import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useGetData from '../../../../../hooks/useGetData'
import { OrderTypes } from '../../../../../interfaces/orders'
import apiService from '../../../../../services/api/apiService'
import PageSection from '../../../../../components/UI/PageSection'
import DetailsInfoBtns from '../../../../../components/UI/DetailsInfoBtns'
import OrderDetailsHeader from '../components/OrderDetailsHeader'
import OrderDetails from '../components/OrderDetails'
import OrderDetailsProducts from '../components/OrderDetailsProducts'
import RenderIf from '../../../../../components/UI/RenderIf'
import OrderInfoSkeleton from '../../../../../components/Skeletons/OrderInfoSkeleton'

function OrderInfo() {
  const params = useParams<{ id: string }>();
  const { data: order, isLoading } = useGetData<OrderTypes>({
    queryKey: `order, ${params.id}`,
    queryFn: () => apiService.getOrderDetails({ id: params.id! })
  })
  const [t] = useTranslation()

  useEffect(() => {
    document.title = `Demo CRM - ${t('orderInfo')}`
  }, [t])

  return (
    <PageSection width="w-full">
      <RenderIf isTrue={isLoading}>
        <OrderInfoSkeleton />
      </RenderIf>
      <RenderIf isTrue={!isLoading}>
        <OrderDetailsHeader order={order} />
        <OrderDetails order={order} />
        <OrderDetailsProducts order={order} />
      </RenderIf>
      <DetailsInfoBtns />
    </PageSection>
  )
}

export default OrderInfo
