import useGetData from '../../../../../hooks/useGetData'
import apiService from '../../../../../services/api/apiService'
import ClientTotalWorthOfOrder from './ClientTotalWorthOfOrder'
import { ClientWorthOfOrdersTypes } from '../../../../../interfaces/statistics'
import RenderIf from '../../../../../components/UI/RenderIf'
import ClientTotalWorthOfOrdersSkeleton from '../../../../../components/Skeletons/ClientTotalWorthOfOrdersSkeleton'
import WidgetTitle from '../../../../../components/UI/WidgetTitle'

interface Props {
  clientNumber: string
}

function ClientTotalWorthOfOrders({ clientNumber }:Props) {
  const { data: clientTotalWorthOfOrders, isLoading } = useGetData<ClientWorthOfOrdersTypes>({
    queryKey: `clientTotalWorthOfOrders ${clientNumber}`,
    queryFn: () => apiService.getClientWorthOfOrders(clientNumber)
  })
  return (
    <>
      <RenderIf isTrue={isLoading}>
        <ClientTotalWorthOfOrdersSkeleton />
      </RenderIf>
      <RenderIf isTrue={!isLoading}>
        <div className="flex flex-col justify-between text-[#E6E6E6] border border-[#E6E6E6] rounded-[10px] lg:max-w-[483px] w-full">
          <div className="px-12 mt-5">
            <WidgetTitle text="totalWorthOfOrders" fontWeight="font-semibold" />
          </div>
          <ClientTotalWorthOfOrder
            date="today"
            worth={clientTotalWorthOfOrders?.todays_total_order_worth}
            change={clientTotalWorthOfOrders?.todays_percentage_change}
          />
          <ClientTotalWorthOfOrder
            date="last30Days"
            worth={clientTotalWorthOfOrders?.monthly_order_worth}
            change={clientTotalWorthOfOrders?.monthly_percentage_change}
          />
          <ClientTotalWorthOfOrder
            date="last365Days"
            worth={clientTotalWorthOfOrders?.yearly_total_order_worth}
            change={clientTotalWorthOfOrders?.yearly_percentage_change}
          />
        </div>
      </RenderIf>
    </>
  )
}

export default ClientTotalWorthOfOrders
