const routes = {
  login: '/',
  forgotPassword: '/forgot-password',
  activateAccount: '/activate-account',
  resetPassword: '/reset-password',
  director: {
    home: '/director',
    agents: '/director/agents',
    statistics: '/director/statistics',
    clients: '/director/clients',
    products: '/director/products',
    orders: '/director/orders',
    offers: '/director/offers',
    complaints: '/director/complaints',
    tasks: '/director/tasks',
    settings: '/director/settings',
    newsletters: '/director/newsletters',
    designEmail: '/director/newsletters/create-newsletter/design-email',
    campaignPreview: '/director/newsletters/create-newsletter/campaign-preview'
  },
  agent: {
    home: '/agent',
    calls: '/agent/calls',
    clients: '/agent/clients',
    products: '/agent/products',
    orders: '/agent/orders',
    complaints: '/agent/complaints',
    tasks: '/agent/tasks',
    clientCallInfo: 'client-call-info',
    settings: '/agent/settings',
    createOrder: '/agent/orders/create-order',
    newsletters: '/agent/newsletters',
    designEmail: '/agent/newsletters/create-newsletter/design-email',
    campaignPreview: '/agent/newsletters/create-newsletter/campaign-preview'
  },
  shared: {
    createClient: 'create-client',
    updateClient: 'update-client',
    createAgent: 'create-agent',
    updateAgent: 'update-agent',
    createOffer: 'create-offer',
    updateOffer: 'update-offer',
    createOrder: 'create-order',
    createComplaint: 'create-complaint',
    updateComplaint: 'update-complaint',
    complaintDetails: 'complaint-details',
    userInfo: 'user-info',
    clientInfo: 'client-info',
    productInfo: 'product-info',
    orderInfo: 'order-info',
    createNewsletter: 'create-newsletter',
    campaignDetails: 'campaign-details'
  }
}

export default routes
