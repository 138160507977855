import { useTranslation } from 'react-i18next'
import { ChangeEvent, useMemo } from 'react'
import moment from 'moment/moment'
import Table from '../../../components/Table/Table'
import { InfiniteActionPaginateTypes } from '../../../interfaces/client'
import CallStatus from '../../../components/UI/CallStatus'
import { AgentCallsTypes, CallStatusTypes } from '../../../interfaces/calls'
import { durationToTime } from '../../../helpers/duration-to-time'

interface Props {
  calls: AgentCallsTypes[]
  callsPaginate: InfiniteActionPaginateTypes
  searchTextCalls: string
  onSearchOrderHandler: (event: ChangeEvent<HTMLInputElement>) => void
  isHidden: boolean
}

function CallsTable({
  calls = [], callsPaginate, searchTextCalls, onSearchOrderHandler, isHidden
}:Props) {
  const { t } = useTranslation()

  const columns = useMemo(() => [
    {
      Header: t('clientName'),
      accessor: 'client.full_name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value: string }) => (<p title={value} className="truncate max-w-[180px]">{value}</p>)
    },
    {
      Header: t('notes'),
      accessor: 'notes',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value: string }) => <p title={value} className="truncate max-w-[180px]">{value || '-'}</p>
    },
    {
      Header: t('callOutcome'),
      accessor: 'status',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value: string }) => (value
        ? <CallStatus status={value as CallStatusTypes} />
        : <p>-</p>)
    },
    {
      Header: t('lastContact'),
      accessor: 'client.last_contact',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value:string }) => <p>{value ? moment(value).format('DD/MM/YYYY, HH:mm') : '-'}</p>
    },
    {
      Header: t('priority'),
      accessor: 'priority'
    },
    {
      Header: t('telephone'),
      accessor: 'destination'
    },
    {
      Header: t('callAttempts'),
      accessor: 'attempts'
    },
    {
      Header: t('duration'),
      accessor: 'duration',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value: number }) => <p>{durationToTime(value)}</p>
    }
  ], [t]);

  return (
    <Table
      columns={columns}
      data={calls}
      storageKey="callsTable"
      rowsPerPage={5}
      apiPagination={callsPaginate}
      searchText={searchTextCalls}
      onSearchHandler={onSearchOrderHandler}
      isHidden={isHidden}
    />
  )
}

export default CallsTable
