import moment from 'moment'
import { MdDelete } from 'react-icons/md'
import { useState } from 'react'
import { ClientCommentsTypes } from '../../../../../interfaces/client'
import RenderIf from '../../../../../components/UI/RenderIf'

interface Props {
  comment: ClientCommentsTypes
  onDeleteHandler: () => void
}
function ClientComment({ comment, onDeleteHandler }: Props) {
  const [isHovered, setIsHovered] = useState<boolean>(false)

  return (
    <div className="flex" onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <div className="w-fit max-w-[90%] bg-gray-200 rounded-lg text-[15px] p-2 text-primary-text-dark">
        <p>{comment.comment}</p>

        <p className="mt-1 text-xs">{moment(comment.date_created).format('DD/MM/YYYY, H:mm')}</p>
      </div>
      <RenderIf isTrue={isHovered}>
        <MdDelete onClick={onDeleteHandler} className="text-error cursor-pointer" />
      </RenderIf>
    </div>
  )
}

export default ClientComment
