function CallInfoSkeleton() {
  return (
    <div className="h-[95px] flex justify-between items-center rounded-lg p-3 bg-gray-200 animate-pulse">
      <div className="flex items-center gap-4">
        <div className="h-[20px] w-[20px] bg-gray-100 animate-pulse rounded-lg" />
        <div>
          <div className="h-[20px] w-[150px] mb-1 bg-gray-100 animate-pulse rounded-lg" />
          <div className="h-[20px] w-[100px] bg-gray-100 animate-pulse rounded-lg" />
        </div>
      </div>
      <div className="h-[20px] w-[100px] bg-gray-100 animate-pulse rounded-lg" />
    </div>
  )
}

function CallsInfoSkeleton() {
  return (
    <div className="rounded-[16px] bg-white shadow-md 2xl:max-w-[450px] w-full mt-7 p-6 pb-14 overflow-y-hidden h-[calc(100vh-150px)] ">
      <div className="px-[14px] py-[19px] rounded-[5px] bg-gray-200 animate-pulse">
        <div className="h-[34px] bg-gray-100 animate-pulse rounded-[5px]" />
      </div>
      <div className="my-3 bg-gray-200 animate-pulse h-[20px] rounded-[5px] w-[100px]" />
      <div className="overflow-y-auto h-full hide-scroll">
        <div className="flex flex-col gap-[7px]">
          <CallInfoSkeleton />
          <CallInfoSkeleton />
          <CallInfoSkeleton />
          <CallInfoSkeleton />
          <CallInfoSkeleton />
          <CallInfoSkeleton />
        </div>
      </div>
    </div>
  )
}

export default CallsInfoSkeleton
