import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import Title from '../../../../components/UI/Title'
import useGetData from '../../../../hooks/useGetData'
import { UserTypes } from '../../../../interfaces/user'
import apiService from '../../../../services/api/apiService'
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal'
import routes from '../../../../constants/routes'
import RenderIf from '../../../../components/UI/RenderIf'
import TableSkeleton from '../../../../components/Skeletons/TableSkeleton'
import UserMethods from '../../../../utils/userMethods'
import UsersTable from './components/UsersTable'

function Agents() {
  const user = UserMethods.getUser()
  const userTypeService = user?.user.user_type === 'MANAGER' ? apiService.getAgents : apiService.getUsers

  const { isLoading, data: users } = useGetData<UserTypes[]>({
    queryKey: 'users',
    queryFn: userTypeService,
    disabled: user?.user.user_type === 'AGENT'
  })

  const { data: managers } = useGetData<UserTypes[]>({
    queryKey: 'managers',
    queryFn: apiService.getManagers,
    disabled: user?.user.user_type !== 'DIRECTOR'
  })

  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isExactPath = pathname === routes.director.agents
  const [t] = useTranslation()

  useEffect(() => {
    document.title = `Demo CRM - ${t('agents')}`
  }, [t])

  return (
    <>
      <RenderIf isTrue={isExactPath}>
        <section className="h-[calc(100%-96px)]">
          <Title results={users} title="agents" add="addAgent" onClickHandler={() => navigate(routes.shared.createAgent)} />
          <RenderIf isTrue={isLoading}>
            <TableSkeleton />
          </RenderIf>
          <RenderIf isTrue={!isLoading}>
            <UsersTable users={users} />
          </RenderIf>
          <ConfirmationModal cacheKey={['users', 'managers', 'agents']} toastSuccessMessage="userHasBeenDeleted" mutationFn={apiService.deleteUser} />
        </section>
      </RenderIf>
      <RenderIf isTrue={!isExactPath}>
        <Outlet context={{ users, managers }} />
      </RenderIf>
    </>

  )
}

export default Agents
