import { createContext } from 'react'
import { MapFiltersCtxTypes } from './mapFiltersCtx.interface'

const mapFiltersCtx = createContext<MapFiltersCtxTypes>({
  showAgentMapModal: false,
  searchValue: '',
  mapAgents: [],
  clientsCities: [],
  selectedClientsPins: [],
  searchedClientsPins: [],
  totalValues: {
    clients: 0,
    totalOrders: 0,
    totalOrdersSum: 0
  },
  isMapPinsLoading: false,
  showSelectedPins: false,
  selectAllAgentOptions: false,
  setShowAgentMapModal: () => {},
  onClearPins: () => {},
  onShowPins: () => {},
  onMapAgentChangeHandler: () => {},
  onSearchValueChangeHandler: () => {},
  onSelectedResultChangeHandler: () => {},
  onCitiesChangeHandler: () => {},
  onSelectAllAgentOptionsHandler: () => {}
})

export default mapFiltersCtx
