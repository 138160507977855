import { useTranslation } from 'react-i18next'
import Spinner from '../../../../../../components/UI/Spinner'

interface Props {
  text: string;
  btnText: string;
  estimatedTime: string;
  onClickHandler: () => void;
  isLoading?: boolean;
}
function CronActionBtn({
  text, btnText, estimatedTime, onClickHandler, isLoading = false
}:Props) {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col gap-2 xs:flex-row xs::items-center xs:justify-between w-full">
      <div>
        <p className="text-[#818181]">{t(text)}</p>
        <p className="text-sm text-[#d0d0d0]">
          {`${t('estimatedTime')}: `}
          <span className="text-grey-pale">{t(estimatedTime)}</span>
        </p>
      </div>
      <button
        disabled
        onClick={onClickHandler}
        type="button"
        className="bg-[#EFF3F8] h-[44px] px-[14px] text-btn-sub text-sm font-semibold rounded-[5px] hover:opacity-90 transition-opacity duration-200"
      >
        {isLoading ? <Spinner /> : t(btnText)}
      </button>
    </div>
  )
}

export default CronActionBtn
