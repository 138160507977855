// eslint-disable-next-line import/prefer-default-export
export const orderOptions = [
  { id: 1, value: '', label: 'choosePaymentMethod' },
  { id: 2, value: '1007', label: 'cheque' },
  { id: 3, value: '1006', label: 'cashOnDelivery' },
  { id: 4, value: '1011', label: 'bankPrepayment' },
  { id: 5, value: '1010', label: 'bankPayment' }
]

export const seriesOptions = [
  { id: 1, value: '', label: 'chooseDocumentary' },
  { id: 2, value: '7023', label: 'receipt' },
  { id: 3, value: '7021', label: 'invoice' }
]

export const shipmentOptions = [
  { id: 1, value: '', label: 'chooseShipmentMethod' },
  { id: 2, value: '101', label: 'pickUpFromSupplier' },
  { id: 3, value: '102', label: 'courier' },
  { id: 4, value: '103', label: 'transportationCompany' },
  { id: 5, value: '104', label: 'transportFromEmoria' }
]
