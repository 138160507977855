import { useTranslation } from 'react-i18next'
import CronActionBtn from './CronActionBtn'
import SyncModal from '../../../../../../components/Modals/SyncModal'
import { useCronActionsCtx } from '../../../../../../context/CronActionsCtx/CronActionsProvider'

function SyncActions() {
  const { t } = useTranslation()
  const cronActionsCtx = useCronActionsCtx()

  return (
    <>
      <SyncModal cronActionsCtx={cronActionsCtx} />
      <div className="flex flex-col gap-6 w-full">
        <h3 className="font-semibold">{t('syncWithErp')}</h3>
        <CronActionBtn
          text="clientSync"
          btnText="sync"
          estimatedTime="about3minutes"
          onClickHandler={() => cronActionsCtx.showSyncModalHandler('about3minutes', 'clientsProducts')}
        />
        <CronActionBtn
          text="orderSync"
          btnText="sync"
          estimatedTime="about30minutes"
          onClickHandler={() => cronActionsCtx.showSyncModalHandler('about30minutes', 'orders')}
        />
      </div>
    </>
  )
}

export default SyncActions
