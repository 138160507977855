import {
  FormEvent, useEffect, useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { OrderProductTypes, ProductTypes } from '../../../../../interfaces/products'
import useToggleMenu from '../../../../../hooks/useToggleMenu'
import RenderIf from '../../../../../components/UI/RenderIf'
import SearchProduct from './SearchProduct'

interface Props {
  products: ProductTypes[]
  orderProducts?:OrderProductTypes[]
  addProduct: (product: ProductTypes) => void
}

function ProductsList({ products = [], orderProducts, addProduct }:Props) {
  const { t } = useTranslation()
  const { isOpen, setIsOpen, ref } = useToggleMenu()
  const [searchValue, setSearchValue] = useState<string>('')
  const [filteredProducts, setFilteredProducts] = useState<ProductTypes[]>(products)

  const onSearchHandler = (e: FormEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value)
    if (e.currentTarget.value.length > 1) {
      const filtered = products.filter((product) => product.product_name
        .toLowerCase().includes(e.currentTarget.value.toLowerCase())
        || product.product_sku.toLowerCase().startsWith(e.currentTarget.value.toLowerCase()))

      setFilteredProducts(filtered)
    }
  }

  useEffect(() => {
    if (products.length !== 0) {
      setFilteredProducts(products)
    }
  }, [products, isOpen])

  useEffect(() => {
    if (searchValue.trim().length > 1) {
      setIsOpen(true)
    }

    if (searchValue.trim() === '') {
      setIsOpen(false)
    }
  }, [searchValue, setIsOpen])

  const listClasses = isOpen ? 'scale-100 opacity-100 max-h-[250px]' : 'scale-0 opacity-0 h-0'
  return (
    <div ref={ref} className="px-7 pt-5">
      <RenderIf isTrue={!orderProducts}>
        <SearchProduct
          searchValue={searchValue}
          onSearchHandler={onSearchHandler}
          setIsOpen={setIsOpen}
        />
      </RenderIf>
      <div className={`${listClasses} relative bg-white border border-solid border-[#BEBEBE] rounded-md w-full top-1 overflow-y-auto lg:w-1/2`}>
        <RenderIf isTrue={filteredProducts.length === 0}>
          <p className="w-full px-5 py-2">
            {t('noProductsFound')}
          </p>
        </RenderIf>
        <RenderIf isTrue={filteredProducts.length !== 0}>
          {filteredProducts.map((product) => (
            <button
              type="button"
              className="w-full border-b border-b-[#BEBEBE] last:border-0 border-solid cursor-pointer px-5 py-2 hover:bg-[rgba(0,0,0,0.1)] transition-colors duration-200"
              key={product.product_sku}
              onClick={() => {
                addProduct(product)
                setSearchValue('')
                setIsOpen(false)
              }}
            >
              <p
                title={`${product.product_sku} ${product.product_name}`}
                className="truncate text-left"
              >
                {`${product.product_sku} - ${product.product_name}`}
              </p>
            </button>
          ))}
        </RenderIf>
      </div>
    </div>
  )
}

export default ProductsList
