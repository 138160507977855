import React from 'react'
import RenderIf from '../../../../components/UI/RenderIf'
import LatestCallsSkeleton from '../../../../components/Skeletons/LatestCallsSkeleton'
import LatestCalls from './LatestCalls'
import useGetData from '../../../../hooks/useGetData'
import { ClientLatestCallTypes } from '../../../../interfaces/client'
import apiService from '../../../../services/api/apiService'
import userMethods from '../../../../utils/userMethods'

function LatestCallsContainer() {
  const authUser = userMethods.getUser()

  const {
    data: latestCalls,
    isLoading: isLatestCallsLoading
  } = useGetData<ClientLatestCallTypes[]>({
    queryKey: 'latestCalls',
    queryFn: () => apiService.getClientsLatestCalls(authUser!.id),
    disabled: !authUser
  })

  return (
    <>
      <RenderIf isTrue={isLatestCallsLoading}>
        <LatestCallsSkeleton />
      </RenderIf>
      <RenderIf isTrue={!isLatestCallsLoading}>
        <LatestCalls latestCalls={latestCalls} />
      </RenderIf>
    </>
  )
}

export default LatestCallsContainer
