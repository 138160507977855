import WidgetTitle from '../../../../components/UI/WidgetTitle'
import LatestCall from './LatestCall'
import { ClientLatestCallTypes } from '../../../../interfaces/client'
import RenderIf from '../../../../components/UI/RenderIf'
import NoDataMessage from '../../../../components/UI/NoDataMessage'

interface Props {
  latestCalls: ClientLatestCallTypes[]
}

function LatestCalls({ latestCalls = [] }: Props) {
  const displayLatestCalls = latestCalls.map((call) => (
    <LatestCall
      key={call.id}
      latestCall={call}
    />
  ))
  return (
    <div className="h-[calc(100vh-435px)] overflow-y-hidden">
      <div className="flex items-center gap-2 flex-wrap justify-between">
        <WidgetTitle text="lastContacts" />
      </div>
      <div className="bg-[#FBFBFB] rounded-[10px] mt-5 flex flex-col overflow-y-auto h-[calc(100%-60px)]">
        {displayLatestCalls}
        <RenderIf isTrue={latestCalls.length === 0}>
          <NoDataMessage text="noLatestCallsFound" />
        </RenderIf>
      </div>
    </div>
  )
}

export default LatestCalls
