import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import validationSchema from '../../../../../utils/validationSchema'
import Spinner from '../../../../../components/UI/Spinner'
import { ClientTypes } from '../../../../../interfaces/client'
import useMutateData from '../../../../../hooks/useMutateData'
import apiService from '../../../../../services/api/apiService'

interface Props {
  client: ClientTypes
}
function ClientCommentsForm({ client }:Props) {
  const { t } = useTranslation()
  const { mutate: createComment, isLoading } = useMutateData({
    key: `clientComments ${client.client_number}`,
    mutationFn: apiService.createClientComment,
    successMessage: 'commentHasBeenCreated'
  })
  const formik = useFormik({
    initialValues: {
      comment: ''
    },
    validationSchema: validationSchema.createComment,
    onSubmit: (values) => {
      createComment({ clientNumber: client.client_number, comment: values.comment }, {
        onSuccess: () => {
          formik.resetForm()
        }
      })
    }
  })
  const hasError = formik.touched.comment && formik.errors.comment

  return (

    <form onSubmit={formik.handleSubmit} className="flex w-full gap-2 items-end flex-col sm:flex-row">

      <div className="mt-auto relative w-full">
        <textarea
          rows={2}
          placeholder={t('commentForClient')}
          className={`outline-non border py-1 px-2 rounded-[5px] w-full ${hasError ? 'border-red-700' : 'border-gray-400'} placeholder:text-sm `}
          {...formik.getFieldProps('comment')}
        />
        {hasError && <span className="absolute left-0 -bottom-4 text-red-700 text-sm">{t(formik.errors.comment ?? '')}</span>}
      </div>
      <button
        type="submit"
        className="mt-2 ml-auto bg-btn-sub text-white rounded-[5px] w-[161px] h-[58px] mb-1.5 hover:opacity-90 transition-opacity duration-200"
      >
        {isLoading ? <Spinner /> : t('submit')}
      </button>
    </form>
  )
}

export default ClientCommentsForm
