import { useTranslation } from 'react-i18next'
import Spinner from './Spinner'

interface Props {
  type: 'button' | 'submit'
  btnText: string
  onClickHandler?: () => void
  isLoading?: boolean
  disabled?: boolean
}

function PrimaryActionBtn({
  type,
  btnText,
  onClickHandler,
  isLoading = false,
  disabled = false
}: Props) {
  const { t } = useTranslation()
  return (
    <button
      /* eslint-disable-next-line react/button-has-type */
      type={type}
      className="w-full lg:w-auto text-lg text-white leading-6 rounded-[5px] px-[30px] py-4 font-semibold hover:opacity-90 transition-opacity duration-200text-white bg-btn-sub"
      onClick={onClickHandler}
      disabled={disabled}
    >
      {isLoading ? <Spinner /> : t(btnText)}
    </button>
  )
}

export default PrimaryActionBtn
