import { useTranslation } from 'react-i18next'
import { useDataProvider } from '../../../../../context/DataProviderCtx/DataProvider'

function ToughClientsFilter() {
  const { t } = useTranslation()
  const { showToughClients, setShowToughClients } = useDataProvider()

  return (

    <div className="flex items-center justify-between gap-8 px-4 py-1 last:border-0 border-b border-solid border-b-[#BEBEBE]">
      <p className="text-grey-pale whitespace-nowrap">{t('onlyToughClients')}</p>
      <input type="checkbox" checked={showToughClients} onChange={() => setShowToughClients((prevState) => !prevState)} />
    </div>
  )
}

export default ToughClientsFilter
