import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import PageTitle from '../../../../../components/UI/PageTitle'
import PrintButton from '../../../../../components/UI/PrintButton'
import orderStateColors, { ColorState } from '../../../../../helpers/order-state-colors'
import { OrderTypes } from '../../../../../interfaces/orders'

interface Props {
  order?: OrderTypes
}

function OrderDetailsHeader({ order }: Props) {
  const { t } = useTranslation()
  return (
    <header className="flex items-center justify-between gap-4  mb-5">
      <div className="flex items-center gap-4 flex-wrap">
        <PageTitle text={`${t('order')} #${order?.order_number ?? ''}`} margin="mb-0" />
        <p className={`w-[160px] py-[6px] rounded-xl text-center ${orderStateColors(order?.order_status as ColorState)}`}>
          {order?.order_status ?? ''}
        </p>
      </div>
      <div className="flex flex-col-reverse gap-2 lg:flex-row items-end">
        <div className="text-primary-text-dark flex flex-col">
          <p className="text-lg md:text-[22px] text-right">{t('fullOrderDate')}</p>
          <p className="text-xl md:self-end md:text-2xl font-semibold text-right">{order?.created_date ? moment(order.created_date).format('DD/MM/YYYY') : ''}</p>
        </div>
        <PrintButton usedInTitle usedInOrder />
      </div>
    </header>
  )
}

export default OrderDetailsHeader
