import { createContext } from 'react'
import { ClientCallCtxTypes } from './clientCallCtx.interface'

const clientCallCtx = createContext<ClientCallCtxTypes>({
  call: null,
  client: null,
  callState: 'NOT_STARTED',
  callClock: '',
  startCall: () => {},
  endCall: () => {},
  startDate: null,
  endDate: null,
  duration: 0,
  onReset: () => {}
})

export default clientCallCtx
