import { createContext } from 'react'
import { CronActionsCtxTypes } from './cronActionsCtx.interface'

const cronActionsCtx = createContext<CronActionsCtxTypes>({
  showSyncModal: false,
  isTaskStarted: false,
  isSyncCronActionsLoading: false,
  isTaskStateLoading: false,
  estimatedTime: '',
  taskState: {
    state: null,
    data: null
  },
  closeSyncModalHandler: () => {},
  showSyncModalHandler: () => {},
  onSetTaskToStartHandler: () => {}
})

export default cronActionsCtx
