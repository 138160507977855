import {
  ChangeEvent, useEffect, useMemo, useState
} from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { useInfiniteQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import Title from '../../../../components/UI/Title'
import RenderIf from '../../../../components/UI/RenderIf'
import routes from '../../../../constants/routes'
import OrdersTable from './components/OrdersTable'
import ProductsCartProvider from '../../../../context/ProductsCartCtx/ProductsCartProvider'
import apiService from '../../../../services/api/apiService'
import TableSkeleton from '../../../../components/Skeletons/TableSkeleton'
import { InfiniteActionPaginateTypes } from '../../../../interfaces/client'
import tokenMethods from '../../../../utils/tokenMethods'

export default function Orders() {
  const { t } = useTranslation()
  const [searchTextOrder, setSearchTextOrder] = useState<string>('')
  const token = tokenMethods.getAccessToken()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isExactPath = pathname === routes.director.orders
    || pathname === routes.agent.orders

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  } = useInfiniteQuery(['orders', searchTextOrder], ({ pageParam }) => apiService
    .getOrders({ pageParam, searchText: searchTextOrder }), {
    getNextPageParam: (lastPage) => lastPage?.next?.split('=').at(-1),
    enabled: !!token,
    refetchOnWindowFocus: false
  })

  const orderPaginate: InfiniteActionPaginateTypes = {
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  }

  const orders = useMemo(() => data?.pages
    .map((group) => group?.results ?? []).flat(1) ?? [], [data])

  const onSearchOrderHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTextOrder(event.target.value)
  }

  useEffect(() => {
    document.title = `Demo CRM - ${t('Orders')}`
  }, [t])

  useEffect(() => () => {
    // reset search text when leaving the page
    setSearchTextOrder('')
  }, [setSearchTextOrder])

  return (
    <ProductsCartProvider>
      <RenderIf isTrue={isExactPath}>
        <section>
          <Title results={orders} title="Orders" add="Add Order" onClickHandler={() => navigate(routes.shared.createOrder)} />
          <RenderIf isTrue={isLoading || orderPaginate.isFetchingNextPage}>
            <TableSkeleton />
          </RenderIf>
          <RenderIf isTrue={!isLoading}>
            <OrdersTable
              orders={orders}
              orderPaginate={orderPaginate}
              searchTextOrder={searchTextOrder}
              isHidden={orderPaginate.isFetchingNextPage}
              onSearchOrderHandler={onSearchOrderHandler}
            />
          </RenderIf>
        </section>
      </RenderIf>
      <RenderIf isTrue={!isExactPath}>
        <Outlet />
      </RenderIf>
    </ProductsCartProvider>
  )
}
