import { useTranslation } from 'react-i18next'
import CronActionsWrapper from './CronActionsWrapper'

function CronActions() {
  const { t } = useTranslation()
  return (
    <div className="border-t border-t-[#BEBEBE] mt-9 pt-8">
      <h2 className="text-[20px] font-semibold text-btn-sub">{t('advancedSettings')}</h2>
      <p className="text-[#959292] my-3">{t('advancedSettingsNote')}</p>
      <CronActionsWrapper />
    </div>
  )
}

export default CronActions
