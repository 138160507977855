import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { UserRoleTypes, UserTypes } from '../../../../../interfaces/user'
import Table from '../../../../../components/Table/Table'
import { useModalContext } from '../../../../../context/ModalsCtx/ModalsProvider'
import routes from '../../../../../constants/routes'
import TableActionBtns from '../../../../../components/Table/TableActionBtns'
import userMethods from '../../../../../utils/userMethods'

interface Props {
  users: UserTypes[]
}

interface OriginalTypes {
  cell: { row: { original: { id:number, fullName: string, objectId: number; user_type: UserRoleTypes['userRole'] } } }
}

function UsersTable({ users = [] }: Props) {
  const { t } = useTranslation()
  const { openConfirmationModalHandler } = useModalContext()
  const navigate = useNavigate()
  const authUser = userMethods.getUser()
  const transformedUsers = useMemo(() => users.map((user) => (
    {
      ...user.user,
      department: user.department,
      objectId: user.id,
      fullName: `${user.user.first_name} ${user.user.last_name}`,
      tableRole: t(user.user.user_type)
    })), [users, t])

  const columns = useMemo(() => [
    {
      Header: 'ID',
      accessor: 'id'
    },
    {
      Header: t('agentName'),
      accessor: 'fullName'
    },
    {
      Header: t('telephone'),
      accessor: 'telephone'
    },
    {
      Header: 'E-mail',
      accessor: 'email',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value: string }) => <p className="font-semibold">{value}</p>
    },
    {
      Header: t('role'),
      accessor: 'tableRole',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }: { value: string }) => <span className="text-[#787878] font-semibold">{value}</span>
    },
    {
      Header: t('department'),
      accessor: 'department'
    },
    {
      Header: ' ',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({
        cell: {
          row: { original }
        }
      }: OriginalTypes) => (
        original.id !== 1 && original.id !== 2 && original.id !== 3
          ? (
            <TableActionBtns
              onClickHandlerEdit={() => navigate(`${routes.shared.updateAgent}/${original.user_type.toLowerCase()}-${original.objectId}`)}
              onClickHandlerDelete={() => openConfirmationModalHandler('deleteUserMessage', {
                id: original.objectId,
                userRole: original.user_type
              })}
            />
          ) : null
      )

    }
  ], [t, navigate, openConfirmationModalHandler]);

  return (
    <Table
      columns={columns}
      data={transformedUsers}
      storageKey="usersTable"
      rowsPerPage={10}
    />
  )
}

export default UsersTable
