import {
  useState, useEffect, useCallback
} from 'react'
import {
  GoogleMap, useJsApiLoader, MarkerF, InfoWindowF
} from '@react-google-maps/api'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { AiFillCloseCircle } from 'react-icons/ai'
import RenderIf from '../../../../components/UI/RenderIf'
import useWidth from '../../../../hooks/useWidth'
import { ClientPinTypes } from '../../../../interfaces/client'
import routes from '../../../../constants/routes'
import MapFiltersBar from './MapFiltersBar'
import { useMapFilters } from '../../../../context/MapFiltersCtx/MapFiltersProvider'
import MapSkeleton from '../../../../components/Skeletons/MapSkeleton'
import WidgetTitle from '../../../../components/UI/WidgetTitle'
import UserMethods from '../../../../utils/userMethods'

function ClientsMap() {
  const { t } = useTranslation()
  const authUser = UserMethods.getUser()
  const navigate = useNavigate()
  const { width } = useWidth()
  const mapFiltersCtx = useMapFilters()
  const [mapRef, setMapRef] = useState< google.maps.Map | null>(null);
  const [selectedPin, setSelectedPin] = useState<ClientPinTypes | null>(null);
  const [defaultPosition, setDefaultPosition] = useState<{ lat: number, lng: number }>({
    lat: 37.9865394228933,
    lng: 23.733367149240912
  })
  const handleOnLoad = (map: google.maps.Map) => {
    setMapRef(map);
  };

  const fitBounds = useCallback(() => {
    if (mapRef) {
      const bounds = new google.maps.LatLngBounds();
      mapFiltersCtx.selectedClientsPins.forEach((pin) => {
        bounds.extend({
          lat: +pin.latitude,
          lng: +pin.longitude
        });
      });
      mapRef.fitBounds(bounds);
    }
  }, [mapFiltersCtx.selectedClientsPins, mapRef]);

  useEffect(() => {
    if (mapFiltersCtx.selectedClientsPins.length > 0) {
      fitBounds()
    }
  }, [mapFiltersCtx.selectedClientsPins, fitBounds])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NODE_ENV === 'development'
      ? `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY_STAGING}`
      : `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY_PRODUCTION}`
  });
  const isDirector = authUser?.user.user_type === 'DIRECTOR'
  const isAgent = authUser?.user.user_type === 'AGENT'

  // eslint-disable-next-line no-nested-ternary
  const showSelectedPinsMapHeight = mapFiltersCtx.showSelectedPins && isDirector
    ? 'calc(100% - 230px)'
    : isDirector
      ? 'calc(100% - 150px)'
      : '600px'

  const containerStyle = {
    width: '100%',
    height: width && width < 1536 ? '500px' : showSelectedPinsMapHeight
  }

  const displayMarkers = mapFiltersCtx.selectedClientsPins?.map((pin) => (
    <MarkerF
      key={pin.client_number}
      position={{ lat: +pin.latitude, lng: +pin.longitude }}
      onClick={() => {
        setDefaultPosition({ lat: +pin.latitude, lng: +pin.longitude })
        setSelectedPin(pin)
      }}
    />
  ))

  const localStorageMapPins = JSON.parse(localStorage.getItem('mapPins') || '[]')
  return (
    <>
      <RenderIf isTrue={mapFiltersCtx.isMapPinsLoading && localStorageMapPins.length === 0}>
        <MapSkeleton />
      </RenderIf>
      <RenderIf isTrue={isLoaded
        && (localStorageMapPins.length > 0
          || !mapFiltersCtx.isMapPinsLoading)}
      >
        <div className="bg-white p-4 sm:p-[30px] rounded-[10px] border border-solid border-[#E6E6E6]">
          <div className="flex items-center justify-between">
            <WidgetTitle text="clientsAgentMap" />
            <RenderIf isTrue={isAgent}>
              <AiFillCloseCircle
                onClick={() => mapFiltersCtx.setShowAgentMapModal(false)}
                size={25}
                className="text-grey-pale cursor-pointer transition-colors hover:text-btn-sub duration-200"
              />
            </RenderIf>
          </div>
          <MapFiltersBar />
          <GoogleMap
            onLoad={handleOnLoad}
            mapContainerStyle={containerStyle}
            center={defaultPosition}
            // eslint-disable-next-line no-nested-ternary
            zoom={width && width < 1024 ? 5 : 6}
            options={{
              minZoom: 1.4
            }}
          >
            <RenderIf isTrue={mapFiltersCtx.showSelectedPins}>
              {displayMarkers}
              {selectedPin
              && (
                <InfoWindowF
                  options={
                    {
                      pixelOffset: new window.google.maps.Size(0, -30)
                    }
                  }
                  onCloseClick={() => setSelectedPin(null)}
                  position={{ lat: +selectedPin.latitude, lng: +selectedPin.longitude }}
                >
                  <div className="text-primary-text-dark flex flex-col gap-1">
                    <button
                      type="button"
                      className="text-grey-pale font-semibold mb-1 text-left"
                      onClick={() => navigate(`${isDirector ? routes.director.clients : routes.agent.clients}/${routes.shared.clientInfo}/${selectedPin?.client_number}`)}
                    >
                      {selectedPin.full_name}
                    </button>
                    <p className="font-medium">{selectedPin.address}</p>
                    <p className="font-medium">{`${t('totalOrders')}: ${selectedPin.total_orders}`}</p>
                    <p className="font-medium">{`${t('totalOrdersAmount')}: ${selectedPin.total_orders_amount}€`}</p>
                  </div>
                </InfoWindowF>
              )}
            </RenderIf>
          </GoogleMap>
        </div>
      </RenderIf>
    </>
  )
}

export default ClientsMap
