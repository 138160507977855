import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import Spinner from '../UI/Spinner'
import RenderIf from '../UI/RenderIf'
import { getTaskPercentage } from '../../helpers/get-task-percentage'
import { CronActionsCtxTypes } from '../../context/CronActionsCtx/cronActionsCtx.interface'

interface Props {
  cronActionsCtx:CronActionsCtxTypes
}

const SyncModal = ({
  cronActionsCtx
}:Props) => {
  const { t } = useTranslation()
  const closeModal = !cronActionsCtx.isTaskStarted
    ? cronActionsCtx.closeSyncModalHandler
    : undefined

  const backdropClasses = cronActionsCtx.showSyncModal ? 'opacity-100 visible' : 'opacity-0 invisible'
  const modalClasses = cronActionsCtx.showSyncModal ? 'opacity-100 scale-100' : 'opacity-0 scale-0'

  const overlays = document.getElementById('overlays') as HTMLElement

  return ReactDOM.createPortal(
    <>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions */}
      <div className={`fixed inset-0 z-40 bg-[rgba(0,0,0,0.20)] transition-opacity duration-300 ${backdropClasses}`} onClick={closeModal} />
      <div
        className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-50 max-w-[650px] w-full origin-center transition-all duration-300 ${modalClasses} `}
      >
        <div className="relative p-4">
          <div className="relative bg-white rounded-lg shadow">
            <div className="p-6 text-center">
              <h3 className="text-grey-pale font-semibold text-lg">{t('systemAlert')}</h3>

              <RenderIf isTrue={cronActionsCtx?.taskState?.state === 'PROGRESS'}>
                <div className="flex flex-col items-center gap-2 mt-4 text-grey-pale">
                  <p className="text-center max-w-[280px]">{t('syncInProgressPleaseDontClosePage')}</p>
                  <p>
                    {getTaskPercentage(
                      cronActionsCtx.taskState?.data?.current_batch ?? 0,
                      cronActionsCtx.taskState?.data?.total_batches ?? 0
                    )}
                    %
                  </p>
                  <div className="relative max-w-[400px] w-full h-[5px] rounded-[5px] bg-[#E8E8E8] text-grey-pale">
                    <div
                      style={{
                        width: `${getTaskPercentage(
                          cronActionsCtx.taskState?.data?.current_batch ?? 0,
                          cronActionsCtx.taskState?.data?.total_batches ?? 0
                        )}%`
                      }}
                      className="absolute left-0 top-0 bottom-0 z-50 rounded-[5px] bg-btn-sub"
                    />
                  </div>
                </div>
              </RenderIf>
              <RenderIf isTrue={cronActionsCtx?.taskState?.state !== 'PROGRESS'}>
                <p className="font-normal text-grey-pale mt-4 mb-5">
                  <span>{t('systemAlertNote1')}</span>
                  <span>{t(cronActionsCtx.estimatedTime)}</span>
                  <span>{t('systemAlertNote2')}</span>
                </p>

                <div className="flex gap-2 justify-center">
                  <button
                    onClick={closeModal}
                    type="button"
                    className="focus:outline-none rounded-lg text-sm font-medium px-5 py-2.5 bg-[#E8E8E8] hover:opacity-90 transition-opacity duration-200"
                  >
                    {t('cancel')}
                  </button>
                  <button
                    onClick={cronActionsCtx.onSetTaskToStartHandler}
                    type="button"
                    className="bg-[#EFF3F8] focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center hover:opacity-90 transition-opacity duration-200"
                  >
                    {cronActionsCtx.isTaskStateLoading || cronActionsCtx?.taskState?.state === 'PENDING' ? <Spinner width="w-5" height="h-5" /> : t('sync') }
                  </button>
                </div>
              </RenderIf>

            </div>
          </div>
        </div>
      </div>
    </>,
    overlays
  )
}

export default SyncModal
