import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import RenderIf from '../../../../components/UI/RenderIf'
import Title from '../../../../components/UI/Title'
import routes from '../../../../constants/routes'
import { useDataProvider } from '../../../../context/DataProviderCtx/DataProvider'
import TasksTable from './components/TasksTable'
import TableSkeleton from '../../../../components/Skeletons/TableSkeleton'
import TaskModal from './components/TaskModal'
import ConfirmationModal from '../../../../components/Modals/ConfirmationModal'
import apiService from '../../../../services/api/apiService'
import { useModalContext } from '../../../../context/ModalsCtx/ModalsProvider'

function Tasks() {
  const { t } = useTranslation()
  const { openTaskModalHandler } = useModalContext()
  const { tasks, isTasksLoading } = useDataProvider()
  const { pathname } = useLocation()
  const isExactPath = pathname === routes.director.tasks
    || pathname === routes.agent.tasks

  useEffect(() => {
    document.title = `Demo CRM - ${t('tasks')}`
  }, [t])

  return (
    <>
      <RenderIf isTrue={isExactPath}>
        <section>
          <Title results={tasks} title="tasks" add="addTask" onClickHandler={() => openTaskModalHandler()} />
          <RenderIf isTrue={isTasksLoading}>
            <TableSkeleton />
          </RenderIf>
          <RenderIf isTrue={!isTasksLoading}>
            <TasksTable tasks={tasks} />
            <TaskModal />
            <ConfirmationModal cacheKey="tasks" toastSuccessMessage="taskHasBeenDeleted" mutationFn={apiService.deleteTask} />
          </RenderIf>
        </section>
      </RenderIf>
      <RenderIf isTrue={!isExactPath}>
        <Outlet />
      </RenderIf>
    </>
  )
}

export default Tasks
