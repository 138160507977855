import moment from 'moment'
import { IoMdTime } from 'react-icons/io'
import CallStatus from '../UI/CallStatus'
import { durationToTime } from '../../helpers/duration-to-time'
import { ClientCallHistoryTypes } from '../../interfaces/client'

interface Props {
  callHistory: ClientCallHistoryTypes
}

function CallHistory({ callHistory }:Props) {
  return (
    <div className="border-b border-solid border-b-[#E6E6E6] py-[11px] last:border-b-0">
      <div className="flex items-center justify-between text-grey-pale flex-wrap gap-2 w-full">
        <CallStatus status={callHistory.status} />
        <div className="flex items-center gap-2 text-sm">
          <p>{callHistory.date && moment(callHistory.date).format('DD/MM/YYYY, HH:mm')}</p>
          <p className="flex items-center gap-1">
            <IoMdTime size={15} />
            {durationToTime(callHistory.duration)}
          </p>
        </div>
      </div>
      <p title={callHistory.notes} className="text-left mt-[15px] text-[#BEBEBE] text-ellipsis line-clamp-1">
        {callHistory.notes}
      </p>
    </div>
  )
}

export default CallHistory
