import { useTranslation } from 'react-i18next'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { ClientsOutletContextTypes } from '../../../../../interfaces/outlet-context'
import useMutateData from '../../../../../hooks/useMutateData'
import apiService from '../../../../../services/api/apiService'
import PageTitle from '../../../../../components/UI/PageTitle'
import ClientCreationForm from '../components/ClientCreationForm'
import { ClientCreationTypes, ClientTypes } from '../../../../../interfaces/client'
import PageSection from '../../../../../components/UI/PageSection'
import useGetData from '../../../../../hooks/useGetData'
import RenderIf from '../../../../../components/UI/RenderIf'

function UpdateClient() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams()

  const {
    agents,
    clientCategories,
    clientCountries
  }: ClientsOutletContextTypes = useOutletContext()

  const { data: client, isLoading: isClientLoading } = useGetData<ClientTypes>({
    queryKey: `client-${params.clientNumber}`,
    queryFn: () => apiService.getClientByClientNumber(params.clientNumber!),
    disabled: !params.clientNumber
  })

  const { isLoading, mutate: updateClient } = useMutateData(
    {
      key: 'clients',
      mutationFn: apiService.updateClient,
      successMessage: 'clientHasBeenUpdated'
    }
  )

  const submitFormHandler = (values: ClientCreationTypes) => {
    updateClient(values, {
      onSuccess: () => navigate(-1)
    })
  }

  useEffect(() => {
    document.title = `Demo CRM - ${t('Update Client')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text={t('Update Client')} />
      <RenderIf isTrue={!isClientLoading}>
        <ClientCreationForm
          onSubmit={submitFormHandler}
          client={client}
          isLoading={isLoading}
          agents={agents}
          clientCategories={clientCategories}
          clientCountries={clientCountries}
        />
      </RenderIf>
    </PageSection>
  )
}

export default UpdateClient
