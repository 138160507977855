import { useTranslation } from 'react-i18next'
import { GoSearch } from 'react-icons/go'
import { FormEvent } from 'react'
import { SearchedClientPinTypes } from '../../../../interfaces/client'

interface Props {
  searchValue: string
  searchedClientsPins: SearchedClientPinTypes[]
  onChangeHandler: (event: FormEvent<HTMLInputElement>) => void
  onSelectedResultChangeHandler: (option: SearchedClientPinTypes) => void
}

function SearchClientsPins({
  searchValue, searchedClientsPins = [], onChangeHandler, onSelectedResultChangeHandler
}:Props) {
  const { t } = useTranslation()
  const isSearchListNotEmptyClasses = searchedClientsPins.length > 0 ? 'scale-100 opacity-100' : 'scale-0 opacity-0'

  return (
    <div className="relative w-full">
      <div className="w-full bg-white flex items-center gap-5 rounded-[5px] border border-solid border-[#BEBEBE] px-4 py-2">
        <input
          value={searchValue}
          onChange={onChangeHandler}
          className="border-0 w-full bg-transparent outline-none"
          placeholder={t('clientNameOrVat')}
        />
        <GoSearch className="text-grey-pale" size={18} />
      </div>
      <div className={`${isSearchListNotEmptyClasses} absolute left-0 right-0 max-h-[300px] overflow-y-auto bg-white rounded-md top-[100px] sm:top-[69px] z-[500] origin-top transition-all duration-300 shadow-faint`}>
        {searchedClientsPins.map((pin) => (
          <div key={pin.client_number} className="flex gap-2 items-center justify-between mx-3 border-b border-solid border-b-[#f2f2f2] py-2 last:border-b-0">
            {pin.full_name}
            <input
              type="checkbox"
              className="mr-2"
              checked={pin?.isSelected ?? false}
              onChange={() => onSelectedResultChangeHandler(pin)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default SearchClientsPins
