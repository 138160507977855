import { useTranslation } from 'react-i18next'
import SelectMultipleAgents from './SelectMultipleAgents'
import ShowFiltersOnMapBtn from './ShowFiltersOnMapBtn'
import { useMapFilters } from '../../../../context/MapFiltersCtx/MapFiltersProvider'
import SearchClientsPins from './SearchClientsPins'
import RenderIf from '../../../../components/UI/RenderIf'
import FilteredResultsTotalValues from './FilreredResultsTotalValues'
import UserMethods from '../../../../utils/userMethods'
import SelectClientCity from './SelectClientCity'

function MapFiltersBar() {
  const { t } = useTranslation()
  const authUser = UserMethods.getUser()
  const {
    searchValue,
    mapAgents,
    clientsCities,
    searchedClientsPins,
    showSelectedPins,
    selectAllAgentOptions,
    totalValues,
    onShowPins,
    onClearPins,
    onMapAgentChangeHandler,
    onSearchValueChangeHandler,
    onSelectedResultChangeHandler,
    onCitiesChangeHandler,
    onSelectAllAgentOptionsHandler
  } = useMapFilters()

  const showSelectedPinsClasses = showSelectedPins ? 'border-b border-solid border-b-[#E6E6E6] pb-5 mb-3' : ''
  return (
    <div className="bg-[#F8F8F8] rounded-[5px] p-5 my-5">
      <RenderIf isTrue={showSelectedPins}>
        <button
          type="button"
          onClick={onClearPins}
          className="relative text-sm text-grey-pale -top-2 hover:text-btn-sub transition-all duration-200"
        >
          {t('clearFilters')}
        </button>
      </RenderIf>
      <div className={`flex flex-col gap-2 justify-between ${showSelectedPinsClasses} sm:flex-row`}>
        <RenderIf isTrue={authUser?.user.user_type === 'DIRECTOR'}>
          <SelectMultipleAgents
            mapAgents={mapAgents}
            selectAllAgentOptions={selectAllAgentOptions}
            onChangeHandler={onMapAgentChangeHandler}
            onSelectAllAgentOptionsHandler={onSelectAllAgentOptionsHandler}
          />
        </RenderIf>
        <RenderIf isTrue={authUser?.user.user_type === 'DIRECTOR'}>
          <SelectClientCity
            clientsCities={clientsCities}
            onCitiesChangeHandler={onCitiesChangeHandler}
          />
        </RenderIf>

        <SearchClientsPins
          searchValue={searchValue}
          searchedClientsPins={searchedClientsPins}
          onChangeHandler={onSearchValueChangeHandler}
          onSelectedResultChangeHandler={onSelectedResultChangeHandler}
        />
        <ShowFiltersOnMapBtn
          text="show"
          onClickHandler={onShowPins}
        />
      </div>
      <RenderIf isTrue={showSelectedPins}>
        <FilteredResultsTotalValues totalValues={totalValues} />
      </RenderIf>
    </div>
  )
}

export default MapFiltersBar
