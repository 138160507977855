import { useTranslation } from 'react-i18next'
import { HiOutlinePlus } from 'react-icons/hi'
import { FormikErrors } from 'formik'
import { MdDelete } from 'react-icons/md'
import { ClientCreationTypes } from '../../../../../interfaces/client'
import RenderIf from '../../../../../components/UI/RenderIf'

interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined))
  => (Promise<FormikErrors<ClientCreationTypes>> | Promise<void>)
  clientAgreement: File
}

function TradeAgreement({ setFieldValue, clientAgreement }:Props) {
  const { t } = useTranslation();
  return (
    <div className="ml-4">
      <p className="text-grey-pale text-base md:text-lg">{t('tradeAgreement')}</p>

      <RenderIf isTrue={clientAgreement.toString() !== ''}>
        <div className="fex flex-col mt-2">

          <div className="flex items-center mb-2 gap-3">
            <p>{clientAgreement.name}</p>
            <MdDelete className="text-error cursor-pointer" size={20} onClick={() => setFieldValue('clientAgreement', '')} />
          </div>

        </div>
      </RenderIf>

      <label htmlFor="tradeAgreement" className="flex justify-center items-center mt-5 rounded-lg bg-[#F1F1F1] w-[46px] h-[46px] cursor-pointer hover:opacity-80 transition-opacity duration-200">
        <HiOutlinePlus />
        <input
          id="tradeAgreement"
          type="file"
          name="clientAgreement"
          accept=".pdf"
          className="hidden"
          onClick={(event) => {
            // Reset file list
            const target = event.target as HTMLInputElement
            target.value = ''
          }}
          onChange={(event) => {
            if (event.target.files !== null) {
              setFieldValue('clientAgreement', event.target.files[0])
            }
          }}
        />
      </label>
    </div>
  )
}

export default TradeAgreement
