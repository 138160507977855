import { BsFillTelephoneFill } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { ClientCallCtxTypes } from '../../../../context/ClientCallCtx/clientCallCtx.interface'
import { TodayCallsTypes } from '../../../../interfaces/calls'
import { ClientTypes } from '../../../../interfaces/client'

interface Props {
  isNotActiveCall: boolean
  clientCallCtx: ClientCallCtxTypes
  call?: TodayCallsTypes

  client?: ClientTypes
}

function PhoneButton({
  isNotActiveCall, clientCallCtx, call, client
}:Props) {
  const { t } = useTranslation()

  const onPhoneButtonClickHandler = () => {
    if (isNotActiveCall) {
      toast.warn<string>(t('savePreviousCall'))
      return
    }

    if (clientCallCtx.callState === 'NOT_STARTED'
      || clientCallCtx.callState === 'ENDED') {
      clientCallCtx.startCall({ clientCall: call, selectedClient: client })
      return
    }

    if (clientCallCtx.callState === 'IN_PROGRESS') {
      clientCallCtx.endCall()
    }
  }

  const isDisabledColor = isNotActiveCall
  // eslint-disable-next-line no-nested-ternary
  const phoneButtonClasses = isDisabledColor
    ? 'bg-gray-300'
    : clientCallCtx.callState === 'NOT_STARTED' || clientCallCtx.callState === 'ENDED'
      ? 'bg-success' : 'bg-error'
  const phoneIconClasses = clientCallCtx.callState === 'NOT_STARTED'
  || clientCallCtx.callState === 'ENDED'
  || isNotActiveCall ? 'rotate-0' : 'rotate-[135deg]'

  return (
    <div
      className="flex items-center gap-2"
    >
      <button
        type="button"
        onClick={onPhoneButtonClickHandler}
        className={`${phoneButtonClasses}  h-[50px] w-[50px] rounded-full shadow-md flex items-center justify-center hover:opacity-90 transition-opacity duration-200`}
      >
        <BsFillTelephoneFill className={`${phoneIconClasses} text-white transition-transform duration-200`} size={18} />
      </button>
      <p className="font-semibold text-[14px] sm:text-base">{clientCallCtx.callState === 'NOT_STARTED' || isNotActiveCall ? t('startCall') : clientCallCtx.callClock}</p>
    </div>
  )
}

export default PhoneButton
