import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { ComplaintCreationTypes, ComplaintTypes } from '../../../../../interfaces/complaints'
import PageSection from '../../../../../components/UI/PageSection'
import PageTitle from '../../../../../components/UI/PageTitle'
import ComplaintCreationForm from '../components/ComplaintCreationForm'
import useMutateData from '../../../../../hooks/useMutateData'
import apiService from '../../../../../services/api/apiService'

function UpdateComplaint() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const params = useParams<{ id: string }>()
  const complaints = useOutletContext<ComplaintTypes[]>()
  const complaint = complaints
    ?.find((currentComplaint) => currentComplaint.id.toString() === params.id)

  const { mutate: updateComplaint, isLoading } = useMutateData({
    mutationFn: apiService.updateComplaint,
    key: 'complaints',
    successMessage: 'complaintHasBeenUpdated'
  })

  const onSubmit = (values: ComplaintCreationTypes) => {
    updateComplaint(values, {
      onSuccess: () => navigate(-1)
    })
  }

  useEffect(() => {
    document.title = `Demo CRM - ${t('editComplaint')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text={t('editComplaint')} />
      <ComplaintCreationForm
        onSubmit={onSubmit}
        isLoading={isLoading}
        complaint={complaint}
      />
    </PageSection>
  )
}

export default UpdateComplaint
