import { useTranslation } from 'react-i18next'
import { FiPlus } from 'react-icons/fi'
import { ReactNode } from 'react'
import RenderIf from './RenderIf'

interface TitleProps {
  title: string
  add: string
  onClickHandler?: () => void
  marginTop?: 'mt-8' | 'mt-0'
  marginBottom?: 'mb-8' | 'mb-0'
  results?: any[]
  children?: ReactNode
}

export default function Title({
  title, add, onClickHandler, marginTop = 'mt-8', marginBottom = 'mb-0', results = [], children
}: TitleProps): JSX.Element {
  const { t } = useTranslation()
  const disablePrint = 'print:hidden';
  return (
    <>
      <div className={`${disablePrint} w-full flex flex-wrap gap-5 justify-between items-center ${marginTop} ${marginBottom}`}>
        <div className="flex items-center gap-3 sm:gap-4 flex-wrap">
          <h2 className="text-3xl md:text-4xl font-semibold text-primary-text-dark">{t(title)}</h2>
          {children}
        </div>

        <RenderIf isTrue={!!onClickHandler}>
          <button onClick={onClickHandler} type="button" className="hover:bg-btn-sub hover:text-white transition-all duration-200 border border-btn-sub flex items-center gap-2 py-4 px-4 xs:px-6 rounded-md xs:text-lg bg-white text-btn-sub font-semibold">
            <FiPlus size={20} />
            {t(add)}
          </button>
        </RenderIf>
      </div>
      <RenderIf isTrue={!!results?.length}>
        <p className="text-primary-text-dark mt-1">
          {`${results?.length} ${t('results')}`}
        </p>
      </RenderIf>
    </>
  )
}
