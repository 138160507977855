import routes from '../../../constants/routes'
import NavLink from '../NavLink'

function SalesAgentNav() {
  return (
    <>
      <NavLink text="home" path={routes.agent.home} />
      <NavLink text="calls" path={routes.agent.calls} />
      <NavLink text="clients" path={routes.agent.clients} />
      <NavLink text="products" path={routes.agent.products} />
      <NavLink text="orders" path={routes.agent.orders} />
      <NavLink text="complaints" path={routes.agent.complaints} />
      <NavLink text="newsletters" path={routes.agent.newsletters} />
    </>
  )
}

export default SalesAgentNav
