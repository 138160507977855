import { useTranslation } from 'react-i18next'

interface Props {
  date: string
  worth: number | null
  change: number | null
}

function ClientTotalWorthOfOrder({ date, worth, change }:Props) {
  const { t } = useTranslation()
  return (
    <div className="h-[130px] flex flex-col px-12 py-[17px] border-b border-b-[#E6E6E6] last:border-b-0">
      <p className="underline text-lg text-[#707070]">{t(date)}</p>
      <div className="flex items-center justify-between">
        <p className="font-bold text-[35px] text-black">
          {worth ?? 0}
          €
        </p>
        {/* eslint-disable-next-line no-nested-ternary */}
        <p className={`${change && change > 0 ? 'text-success' : change && change < 0 ? 'text-error' : ''}`}>{ change ? `${change}%` : '-'}</p>
      </div>
    </div>
  )
}

export default ClientTotalWorthOfOrder
