import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import FormLayout from '../../../../../components/Form/FormLayout'
import FormInput from '../../../../../components/Form/FormInput'
import FormSelect from '../../../../../components/Form/FormSelect'
import { ClientTypes } from '../../../../../interfaces/client'
import RenderIf from '../../../../../components/UI/RenderIf'
import { orderOptions, seriesOptions, shipmentOptions } from '../../../../../helpers/order-options'
import FormTextArea from '../../../../../components/Form/FormTextArea'
import SearchClientFormInput from '../../../../../components/Form/SearchClientFormInput'
import OrderClientInfo from './OrderClientInfo'

interface Props {
  client?: ClientTypes
  formik: any
}

function OrderCreationForm({ formik, client }: Props) {
  const { t } = useTranslation()
  const [selectedClient, setSelectedClient] = useState<ClientTypes | undefined>(client)
  const { values, setFieldValue } = formik

  useEffect(() => {
    if (selectedClient && values.clientInfo.trim().length > 2 && !client) {
      setFieldValue('client', selectedClient.client_number)
      setFieldValue('clientInfo', `${selectedClient.full_name} - ${selectedClient.vat_number}`)
      setFieldValue('clientAddress', selectedClient.address)
      return
    }

    if (selectedClient && values.clientInfo.trim().length === 0 && !client) {
      setSelectedClient(undefined)
      setFieldValue('client', '')
      setFieldValue('clientInfo', '')
      setFieldValue('clientAddress', '')
    }
  }, [setFieldValue, selectedClient, client, values.clientInfo])

  return (
    <FormLayout>
      <div className="w-full grid grid-cols-1 md:grid-cols-2">
        <div>
          <span className="font-semibold grey-bold text-xl px-4">{t('customerInfo')}</span>
          <RenderIf isTrue={!client}>
            <SearchClientFormInput
              name="client"
              getFieldProps={formik.getFieldProps('clientInfo')}
              errors={!!(formik.touched.clientInfo && formik.errors.clientInfo)}
              errorName={formik.errors.clientInfo}
              formik={formik}
              selectedClient={selectedClient}
              setSelectedClient={setSelectedClient}
            />
          </RenderIf>
          <RenderIf isTrue={!!client}>
            <FormInput
              inputId="client"
              name="client"
              width="md:w-full"
              getFieldProps={formik.getFieldProps('clientInfo')}
              errors={!!(formik.touched.clientInfo && formik.errors.clientInfo)}
              errorName={formik.errors.clientInfo}
              disabled
            />
          </RenderIf>
          <OrderClientInfo selectedClient={selectedClient} direction="left" />
        </div>
        <div>
          <span className="font-semibold grey-bold text-xl px-4">{t('deliveryInfo')}</span>
          <FormInput
            inputId="clientAddress"
            name="clientAddress"
            width="md:w-full"
            getFieldProps={formik.getFieldProps('clientAddress')}
            errors={
                !!(formik.touched.clientAddress && formik.errors.clientAddress)
              }
            errorName={formik.errors.clientAddress}
            disabled
          />
          <OrderClientInfo selectedClient={selectedClient} direction="right" />
        </div>
      </div>
      <div className="w-full grid grid-cols-1 md:grid-cols-2">
        <FormSelect
          labelText="paymentMethod"
          width="md:w-full"
          getFieldProps={formik.getFieldProps('paymentMethod')}
          errors={
              !!(formik.touched.paymentMethod && formik.errors.paymentMethod)
            }
          errorName={formik.errors.paymentMethod}
        >
          {
            orderOptions.map((option) => (

              <option key={option.id} value={option.value}>
                {t(option.label)}
              </option>
            ))
          }
        </FormSelect>
        <FormSelect
          labelText="shipmentMethod"
          width="md:w-full"
          getFieldProps={formik.getFieldProps('shipment')}
          errors={
            !!(formik.touched.shipment && formik.errors.shipment)
          }
          errorName={formik.errors.shipment}
        >
          {
            shipmentOptions.map((option) => (
              <option key={option.id} value={option.value}>
                {t(option.label)}
              </option>
            ))
          }
        </FormSelect>
      </div>
      <div className="w-full">
        <FormSelect
          labelText="documentary"
          width="lg:w-1/2"
          getFieldProps={formik.getFieldProps('series')}
          errors={
              !!(formik.touched.series && formik.errors.series)
            }
          errorName={formik.errors.series}
        >
          {
              seriesOptions.map((option) => (
                <option key={option.id} value={option.value}>
                  {t(option.label)}
                </option>
              ))
            }
        </FormSelect>
      </div>

      <FormTextArea
        inputId="notes"
        name="notes"
        width="lg:w-full"
        rows={2}
        getFieldProps={formik.getFieldProps('notes')}
        errors={!!(formik.touched.notes && formik.errors.notes)}
        errorName={formik.errors.notes}
      />
    </FormLayout>
  )
}

export default OrderCreationForm
