import { Route, Routes } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import { ReactQueryDevtools } from 'react-query/devtools'
import routes from './constants/routes'
import Layout from './containers/Layout'
import 'react-toastify/dist/ReactToastify.css'
import Login from './pages/Login/Login'
import ForgotPassword from './pages/ForgotPassword/ForgotPassword'
import RenderIf from './components/UI/RenderIf'
import Clients from './pages/Shared/pages/Clients/Clients'
import Products from './pages/Shared/pages/Products/Products'
import Orders from './pages/Shared/pages/Orders/Orders'
import ProtectedRoutes from './components/Routes/ProtectedRoutes'
import PublicRoutes from './components/Routes/PublicRoutes'
import CreateAgent from './pages/Shared/pages/Agents/pages/CreateAgent'
import Director from './pages/Director/Director'
import Agents from './pages/Shared/pages/Agents/Agents'
import UpdateAgent from './pages/Shared/pages/Agents/pages/UpdateAgent'
import CreateClient from './pages/Shared/pages/Clients/pages/CreateClient'
import Complaints from './pages/Shared/pages/Complaints/Complaints'
import Statistics from './pages/Director/pages/Statistics'
import CreateComplaint from './pages/Shared/pages/Complaints/pages/CreateComplaint'
import Calls from './pages/SalesAgent/pages/Calls/Calls'
import CreateOrder from './pages/Shared/pages/Orders/pages/CreateOrder'
import UserInfo from './pages/Shared/pages/Agents/pages/UserInfo'
import ClientInfo from './pages/Shared/pages/Clients/pages/ClientInfo'
import UpdateClient from './pages/Shared/pages/Clients/pages/UpdateClient'
import SalesAgent from './pages/SalesAgent/SalesAgent'
import ClientCallInfo from './pages/SalesAgent/pages/ClientCallInfo/ClientCallInfo'
import ComplaintInfo from './pages/Shared/pages/Complaints/pages/ComplaintInfo'
import UpdateComplaint from './pages/Shared/pages/Complaints/pages/UpdateComplaint'
import Tasks from './pages/Shared/pages/Tasks/Tasks'
import ProductInfo from './pages/Shared/pages/Products/pages/ProductInfo'
import OrderInfo from './pages/Shared/pages/Orders/pages/OrderInfo'
import Settings from './pages/Shared/pages/Settings/Settings'

import 'react-datepicker/dist/react-datepicker.css'
import Newsletters from './pages/Shared/pages/Newsletters/Newsletters'
import CreateNewsletter from './pages/Shared/pages/Newsletters/pages/CreateNewsletter'
import CampaignDetails from './pages/Shared/pages/Newsletters/pages/CampaignDetails'
import DesignEmail from './pages/Shared/pages/Newsletters/pages/DesignEmail'
import CampaignPreview from './pages/Shared/pages/Newsletters/pages/CampaignPreview'
import ActivateAccount from './pages/ActivateAccount/ActivateAccount'
import ResetPassword from './pages/ResetPassword/ResetPassword'
import FixedPhoneButton from './pages/SalesAgent/components/InitializeCall/FixedPhoneButton'
import { useCallClient } from './context/ClientCallCtx/ClientCallProvider'
import userMethods from './utils/userMethods'

function App() {
  const clientCallCtx = useCallClient()
  const authUser = userMethods.getUser()
  return (
    <>
      <RenderIf isTrue={clientCallCtx.callState !== 'NOT_STARTED' && authUser?.user.user_type === 'AGENT'}>
        <FixedPhoneButton />
      </RenderIf>
      <Routes>
        <Route path={routes.activateAccount} element={<ActivateAccount />} />
        <Route path={routes.resetPassword} element={<ResetPassword />} />
        <Route element={<PublicRoutes />}>
          <Route path={routes.login} element={<Login />} />
          <Route path={routes.forgotPassword} element={<ForgotPassword />} />
        </Route>

        {/* DIRECTOR ROUTES */}
        <Route element={<ProtectedRoutes userRole="DIRECTOR" />}>
          <Route element={<Layout userRole="DIRECTOR" />}>
            <Route path={routes.director.home} element={<Director />} />
            <Route path={routes.director.statistics} element={<Statistics />} />
            <Route path={routes.director.agents} element={<Agents />}>
              <Route path={routes.shared.createAgent} element={<CreateAgent />} />
              <Route path={`${routes.shared.updateAgent}/:id`} element={<UpdateAgent />} />
              <Route path={`${routes.shared.userInfo}/:id`} element={<UserInfo />} />
            </Route>
            <Route path={routes.director.clients} element={<Clients />}>
              <Route path={routes.shared.createClient} element={<CreateClient />} />
              <Route path={`${routes.shared.updateClient}/:clientNumber`} element={<UpdateClient />} />
              <Route path={`${routes.shared.clientInfo}/:clientNumber`} element={<ClientInfo />} />
            </Route>
            <Route path={routes.director.products} element={<Products />}>
              <Route path={`${routes.shared.productInfo}/:id`} element={<ProductInfo />} />
            </Route>
            <Route path={routes.director.orders} element={<Orders />}>
              <Route path={routes.shared.createOrder} element={<CreateOrder />} />
              <Route path={`${routes.shared.createOrder}/:clientNumber`} element={<CreateOrder />} />
              <Route path={`${routes.shared.orderInfo}/:id`} element={<OrderInfo />} />
            </Route>
            <Route path={routes.director.complaints} element={<Complaints />}>
              <Route path={routes.shared.createComplaint} element={<CreateComplaint />} />
              <Route path={`${routes.shared.updateComplaint}/:id`} element={<UpdateComplaint />} />
              <Route path={`${routes.shared.complaintDetails}/:id`} element={<ComplaintInfo />} />
            </Route>
            <Route path={routes.director.tasks} element={<Tasks />} />
            <Route path={routes.director.settings} element={<Settings />} />
            <Route path={routes.director.newsletters} element={<Newsletters />}>
              <Route path={routes.shared.createNewsletter} element={<CreateNewsletter />}>
                <Route index element={<CampaignDetails />} />
                <Route path={routes.director.designEmail} element={<DesignEmail />} />
                <Route path={routes.director.campaignPreview} element={<CampaignPreview />} />
              </Route>
            </Route>
          </Route>
        </Route>

        {/* SALES AGENT ROUTES */}
        <Route element={<ProtectedRoutes userRole="AGENT" />}>
          <Route
            element={<Layout userRole="AGENT" />}
          >
            <Route path={routes.agent.home} element={<SalesAgent />}>
              <Route path={`${routes.agent.clientCallInfo}/:clientCall`} element={<ClientCallInfo />} />
            </Route>
            <Route path={routes.agent.calls} element={<Calls />} />
            <Route path={routes.agent.clients} element={<Clients />}>
              <Route path={routes.shared.createClient} element={<CreateClient />} />
              <Route path={`${routes.shared.updateClient}/:clientNumber`} element={<UpdateClient />} />
              <Route path={`${routes.shared.clientInfo}/:clientNumber`} element={<ClientInfo />} />
            </Route>
            <Route path={routes.agent.products} element={<Products />}>
              <Route path={`${routes.shared.productInfo}/:id`} element={<ProductInfo />} />
            </Route>
            <Route path={routes.agent.orders} element={<Orders />}>
              <Route path={routes.shared.createOrder} element={<CreateOrder />} />
              <Route path={`${routes.shared.createOrder}/:clientNumber`} element={<CreateOrder />} />
              <Route path={`${routes.shared.orderInfo}/:id`} element={<OrderInfo />} />
            </Route>
            <Route path={routes.agent.complaints} element={<Complaints />}>
              <Route path={routes.shared.createComplaint} element={<CreateComplaint />} />
              <Route path={`${routes.shared.updateComplaint}/:id`} element={<UpdateComplaint />} />
              <Route path={`${routes.shared.complaintDetails}/:id`} element={<ComplaintInfo />} />
            </Route>
            <Route path={routes.agent.newsletters} element={<Newsletters />}>
              <Route path={routes.shared.createNewsletter} element={<CreateNewsletter />}>
                <Route index element={<CampaignDetails />} />
                <Route path={routes.agent.designEmail} element={<DesignEmail />} />
                <Route path={routes.agent.campaignPreview} element={<CampaignPreview />} />
              </Route>
            </Route>
            <Route path={routes.agent.tasks} element={<Tasks />} />
            <Route path={routes.agent.settings} element={<Settings />} />
          </Route>
        </Route>
      </Routes>

      {/* React toastify configuration - Ignore */}
      <ToastContainer
        position="top-center"
        autoClose={2500}
        pauseOnFocusLoss={false}
        transition={Slide}
      />
      <RenderIf isTrue={process.env.NODE_ENV === 'development'}>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </RenderIf>
    </>
  )
}

export default App
