function ClientTotalWorthOfOrderSkeleton() {
  return (
    <div className="h-[130px] flex flex-col gap-2 px-12 py-[17px] bg-gray-200 animate-pulse rounded-[10px]">
      <div className="rounded-[10px] bg-gray-100 animate-pulse w-[150px] h-[40px]" />
      <div className="flex items-center justify-between">
        <div className="rounded-[10px] bg-gray-100 animate-pulse w-[140px] h-[60px]" />
        <div className="rounded-[10px] bg-gray-100 animate-pulse w-[80px] h-[30px]" />
      </div>
    </div>
  )
}

function ClientTotalWorthOfOrdersSkeleton() {
  return (
    <div className="flex flex-col justify-between rounded-[10px] lg:max-w-[483px] w-full">
      <ClientTotalWorthOfOrderSkeleton />
      <ClientTotalWorthOfOrderSkeleton />
      <ClientTotalWorthOfOrderSkeleton />
    </div>
  )
}

export default ClientTotalWorthOfOrdersSkeleton
