import useWidth from '../../hooks/useWidth'
import UserMethods from '../../utils/userMethods'

function MapSkeleton() {
  const { width } = useWidth()
  const authUser = UserMethods.getUser()
  const isDirector = authUser?.user.user_type === 'DIRECTOR'
  return (
    // eslint-disable-next-line no-nested-ternary
    <div className={`w-full ${width && width < 1280 ? 'h-[600px]' : isDirector ? 'h-full' : 'h-[600px]'} rounded-[10px] border border-solid border-[#E6E6E6] bg-white`}>
      <div className="bg-gray-100 animate-pulse w-full h-full rounded-[10px] p-4 sm:p-[30px]">
        <div className="flex items-center justify-between gap-2 bg-gray-200 animate-pulse w-full h-[82px] rounded-[10px] p-[30px]">
          <div className="bg-gray-100 animate-pulse w-1/4 h-[30px] rounded-[10px]" />
          <div className="bg-gray-100 w-[200px] w-2/4 animate-pulse w-full h-[30px] rounded-[10px]" />
          <div className="bg-gray-100 animate-pulse w-1/4 w-full h-[30px] rounded-[10px]" />
        </div>
      </div>
    </div>
  )
}

export default MapSkeleton
