import { ClientTypes } from '../../../../../interfaces/client'
import WidgetTitle from '../../../../../components/UI/WidgetTitle'
import ClientCommentsForm from './ClientCommentsForm'
import ClientComments from './ClientComments'
import FormLayout from '../../../../../components/Form/FormLayout'

interface Props {
  client: ClientTypes;
}
function ClientCommentsContainer({ client }: Props) {
  return (
    <div className="w-full flex flex-col gap-4 ">
      <WidgetTitle text="comments" />
      <FormLayout>
        <ClientComments client={client} />
        <ClientCommentsForm client={client} />
      </FormLayout>
    </div>

  )
}

export default ClientCommentsContainer
