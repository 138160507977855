import InitializeCall from '../../../../SalesAgent/components/InitializeCall/InitializeCall'
import { ClientTypes } from '../../../../../interfaces/client'
import WidgetTitle from '../../../../../components/UI/WidgetTitle'

interface Props {
  client: ClientTypes
}
function ClientCall({ client }:Props) {
  return (
    <div className="w-full flex flex-col gap-4 ">
      <WidgetTitle text="call" />
      <InitializeCall client={client} />
    </div>
  )
}

export default ClientCall
