import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import ForgotPasswordForm from './components/ForgotPasswordForm'
import emoriaLogo2x from '../../assets/images/logo-demo.png'
import apiService from '../../services/api/apiService'
import useMutateData from '../../hooks/useMutateData'
import { ResetPasswordTypes } from '../../interfaces/resetPassword'

function ForgotPassword() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    document.title = `Demo CRM - ${t('resetPassword')}`
  }, [t])

  const { mutate: resetPassword, isLoading } = useMutateData({
    key: 'email',
    mutationFn: apiService.forgotPassword,
    successMessage: 'emailWillBeSent'
  })

  const onSubmit = (values: ResetPasswordTypes) => {
    resetPassword(values, {
      onSuccess: () => navigate(-1)
    })
  }

  return (
    <div className="p-5 flex flex-col justify-center w-full min-h-full">
      <img src={emoriaLogo2x} width="332px" height="133px" alt="Emoria logo" className="mx-auto" />
      <div className="mx-auto bg-white mt-12 max-w-[500px] rounded-3xl shadow-faint w-full">
        <h1 className="text-center text-4xl mx-8 mb-8 pt-20 text-[#1B1B1B]">{`${t('Forgot password?')}`}</h1>
        <p className="text-center text-base mx-7 my-4 text-grey-pale">
          {t('pleaseTypeEmailtoResetPassword')}
          .
        </p>
        <ForgotPasswordForm onSubmit={onSubmit} isLoading={isLoading} />
      </div>
    </div>
  )
}

export default ForgotPassword
