import React from 'react'
import { useTranslation } from 'react-i18next'
import { ProductTypes } from '../../../../../interfaces/products'
import FormLayout from '../../../../../components/Form/FormLayout'
import Detail from '../../../../../components/UI/Detail'
import StockIcon from '../../../../../components/UI/StockIcon'

interface Props {
  product?: ProductTypes
}

function ProductDetails({ product }: Props) {
  const { t } = useTranslation()
  const print = 'print:grid-cols-3'
  return (
    <FormLayout>
      <div className={`grid grid-cols-2 md:grid-cols-3 gap-10 w-full ${print}`}>
        <Detail label={t('name')} text={product?.product_name || ''} />
        <Detail label={t('productCode')} text={product?.product_id || ''} />
        <Detail label={t('skuProduct')} text={product?.product_sku || ''} />
        <Detail label={t('price')} text={`${product?.price ?? '0'} €` || '-'} />
        <Detail label={t('tax')} text={t(product?.product_tax.replace('ΦΠΑ', '') || '-')} />
        <Detail label={t('category')} text={t(product?.product_category || '-')} />
        <Detail label={t('productGroup')} text={t(product?.product_group || '-')} />
        <Detail label={t('stock')} text={t(product?.remaining_stock.toString() ?? '-')}>
          <StockIcon stock={product?.remaining_stock} />
        </Detail>
        <Detail label={t('sold')} text={t(product ? Math.abs(product?.sold_stock).toString() : '-')} />
      </div>
    </FormLayout>
  )
}

export default ProductDetails
