import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { useModalContext } from '../../context/ModalsCtx/ModalsProvider'
import routes from '../../constants/routes'

interface Props {
  text: string
  path: string
}

export default function NavLink({ text, path }: Props) {
  const { t } = useTranslation()
  const { setIsOpen } = useModalContext()
  const { pathname } = useLocation()
  const hoverClasses = 'hover:text-grey-pale duration-200 transition-all'
  const activePath = pathname.split('/')[2]
  const isActiveLink = path.includes(activePath)
    || pathname === path
    || (path === routes.agent.home && pathname.includes(routes.agent.clientCallInfo))

  return (
    <div className="relative">
      <Link onClick={() => setIsOpen(false)} to={path} className={`${isActiveLink ? hoverClasses : 'text-grey-pale'} flex items-center justify-center h-full text-xl ${hoverClasses}`}>
        {t(text)}
      </Link>
      <div className={`${isActiveLink ? 'active-link-border -bottom-[18px] 3xl:-bottom-[51px] absolute -left-3 -right-3 h-[60px] bg-red-50 3xl:origin-top transition-transform duration-200 scale-100' : 'scale-0'}`} />
    </div>
  )
}
