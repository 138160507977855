import React from 'react'
import { AiFillCheckCircle, AiFillExclamationCircle, AiFillMinusCircle } from 'react-icons/ai'

interface Props {
  stock?: number
}

function StockIcon({ stock = 0 }:Props) {
  if (stock <= 0) {
    return <AiFillMinusCircle size={20} color="#E64848" />
  }

  if (stock > 0 && stock < 50) {
    return <AiFillExclamationCircle size={20} color="#E6C148" />
  }

  if (stock > 49) {
    return <AiFillCheckCircle size={20} color="#78C896" />
  }

  return null
}

export default StockIcon
