import { DebounceInput } from 'react-debounce-input'
import { ChangeEvent } from 'react'

interface Props {
  text: string | number | JSX.Element
  width?: string
  type?: 'text' | 'number'
  onChangeHandler?: (
    event: ChangeEvent<HTMLInputElement>
  ) => void
  inputText?: string
  isUncompleted?: boolean
  [key: string]: any
}

function AddProductValue({
  text, width, inputText, type = 'text', onChangeHandler, isUncompleted = false, ...restProps
}: Props) {
  const print = 'print:m-0 print:p-0'

  if (type === 'number') {
    return (
      <div className={`${print} w-full flex items-center gap-1 text-[#959292] text-lg`}>
        <DebounceInput debounceTimeout={1500} onChange={onChangeHandler!} className="text-center pl-[10px] py-[1px] border border-solid border-[#BEBEBE] rounded-md" type="number" {...restProps} />
        <p>{inputText}</p>
      </div>
    )
  }

  return (
    <p title={text.toString()} className={`${width} ${isUncompleted ? 'text-error' : 'text-[#959292]'} text-lg  last:text-right truncate w-full`}>{text}</p>
  )
}

export default AddProductValue
