import { useTranslation } from 'react-i18next'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { useEffect } from 'react'
import UserCreationForm from '../components/UserCreationForm'
import apiService from '../../../../../services/api/apiService'
import { UserCreationTypes } from '../../../../../interfaces/user'
import useMutateData from '../../../../../hooks/useMutateData'
import PageTitle from '../../../../../components/UI/PageTitle'
import { UserOutletContextTypes } from '../../../../../interfaces/outlet-context'
import PageSection from '../../../../../components/UI/PageSection'

function CreateAgent() {
  const [t] = useTranslation()
  const navigate = useNavigate()
  const { managers }: UserOutletContextTypes = useOutletContext()

  const { isLoading, mutate: createUser } = useMutateData(
    {
      key: ['users', 'managers', 'agents'],
      mutationFn: apiService.createUser,
      successMessage: 'userHasBeenCreated'
    }
  )

  const submitFormHandler = (values: UserCreationTypes) => {
    createUser(values, {
      onSuccess: () => navigate(-1)
    })
  }

  useEffect(() => {
    document.title = `Demo CRM - ${t('New Employee')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text={t('New Employee')} />
      <UserCreationForm
        onSubmit={submitFormHandler}
        isLoading={isLoading}
        managers={managers}
      />
    </PageSection>
  )
}

export default CreateAgent
