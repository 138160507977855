import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { OrderTypes } from '../../../../../interfaces/orders'
import FormLayout from '../../../../../components/Form/FormLayout'
import Detail from '../../../../../components/UI/Detail'

interface Props {
  order?: OrderTypes
}

function OrderDetails({ order }: Props) {
  const { t } = useTranslation()
  const print = 'print:grid-cols-3'
  return (
    <FormLayout>
      <div className={`grid grid-cols-2 md:grid-cols-3 gap-10 w-full ${print}`}>
        <Detail label={t('clientDetails')} text={order?.client.full_name ?? ''} textOptional={`${order?.client.email ?? ''} ${order?.client.telephone ?? ''}`} />
        <Detail label={t('shipmentDetails')} text={order?.client.address ?? ''} textOptional={`${order?.client.area ?? ''} ${order?.client.zip ?? ''}`} />
        {/* <Detail label={t('documentaryNumber')} text={order?.fincode || '-'} /> */}
        <Detail label={t('paymentMethod')} text={order?.payment_method ?? ''} />
        <Detail label={t('saleAgent')} text={`${order?.client.agent.user.first_name} ${order?.client.agent.user.last_name}`} />
        <Detail label={t('invoiceCode')} text={order?.invoice_code || '-'} />
        <Detail label={t('invoiceDate')} text={order?.invoice_date ? moment(order?.invoice_date).format('DD/MM/YYYY') : '-'} />
        <Detail label={t('deliveryDate')} text={order?.delivery_date ? moment(order?.delivery_date).format('DD/MM/YYYY') : '-'} />
        <Detail label={t('paymentStatus')} text={order?.payment_status || '-'} />
        <Detail label={t('notes')} text={order?.notes || '-'} />
      </div>
    </FormLayout>
  )
}

export default OrderDetails
