import { OrderTypes } from '../../../../../interfaces/orders'
import AddProductsToOrder from './AddProductsToOrder'

interface Props {
  order?: OrderTypes
}

function OrderDetailsProducts({ order }:Props) {
  return (
    <AddProductsToOrder
      orderProducts={order?.orderProducts}
      orderTotalPrice={order?.total_price}
      products={[]}
    />
  )
}

export default OrderDetailsProducts
