import { useTranslation } from 'react-i18next'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import PageTitle from '../../../../../components/UI/PageTitle'
import { UserOutletContextTypes } from '../../../../../interfaces/outlet-context'
import UserDetails from '../components/UserDetails'
import PageSection from '../../../../../components/UI/PageSection'
import TotalWorthProductTypeChart from '../../../../../components/Charts/TotalWorthProductTypeChart'
import DetailsInfoBtns from '../../../../../components/UI/DetailsInfoBtns'
import UserMethods from '../../../../../utils/userMethods'
import routes from '../../../../../constants/routes'
import NumberOfOrdersByProductTypeChart from '../../../../../components/Charts/NumberOfOrdersByProductTypeChart'
import PrintButton from '../../../../../components/UI/PrintButton'
import TotalOrderValues from '../../../../../components/Charts/TotalOrderValues'
import CallStatistics from '../../../../Director/pages/CallStatistics'
import LastCallsTable from '../components/LastCallsTable'

function UserInfo() {
  const { t } = useTranslation()
  const authUser = UserMethods.getUser()
  const navigate = useNavigate()
  const params = useParams()
  const urlParams = params.id?.split('-')
  const { users }: UserOutletContextTypes = useOutletContext()
  const user = users?.find((usr) => usr.id.toString() === urlParams?.[1]
    && usr.user.user_type === urlParams?.[0].toUpperCase())

  const agentPath = `/${authUser?.user.user_type.toLowerCase()}/agents/${routes.shared.updateAgent}/${user?.user.user_type.toLowerCase()}-${user?.id}`

  useEffect(() => {
    document.title = `Demo CRM - ${t('agent')}`
  }, [t])

  return (
    <PageSection>
      <div className="flex justify-between">
        <PageTitle text={`${t('agent')} ${user?.user.first_name ?? ''} ${user?.user.last_name ?? ''}`} />
        <PrintButton usedInTitle />
      </div>
      <UserDetails user={user} />
      <div className="flex flex-col gap-8">
        <h3 className="text-primary-text-dark text-[20px] font-semibold ">{t('statisticDetails')}</h3>
        <div className="grid xl:grid-cols-2 gap-8">
          <CallStatistics agent={user?.id.toString()} />
          <TotalOrderValues agent={user?.id.toString()} />
        </div>
        <TotalWorthProductTypeChart agent={user?.id.toString()} />
        <NumberOfOrdersByProductTypeChart text="numberOfOrderByProductType" agent={user?.id.toString()} />
        <LastCallsTable agent={user?.id!} />
      </div>
      <DetailsInfoBtns btnText="editAgent" onClickHandler={() => navigate(agentPath)} />
    </PageSection>
  )
}

export default UserInfo
