import RenderIf from '../../../../components/UI/RenderIf'
import CallsInfoSkeleton from '../../../../components/Skeletons/CallsInfoSkeleton'
import CallsInfo from './CallsInfo'
import { TodayCallsTypes } from '../../../../interfaces/calls'

interface Props {
  todayCalls: TodayCallsTypes[]
  isTodayCallsLoading: boolean
  isTodayCallsFetching: boolean
}

function CallsInfoContainer({ todayCalls = [], isTodayCallsLoading, isTodayCallsFetching }: Props) {
  return (
    <>
      <RenderIf isTrue={isTodayCallsLoading}>
        <CallsInfoSkeleton />
      </RenderIf>
      <RenderIf isTrue={!isTodayCallsLoading}>
        <CallsInfo isTodayCallsFetching={isTodayCallsFetching} todayCalls={todayCalls} />
      </RenderIf>
    </>
  )
}

export default CallsInfoContainer
