import { TiDelete } from 'react-icons/ti'
import pdfIcon from '../../../../../assets/images/pdf.png'
import ConfirmationModal from '../../../../../components/Modals/ConfirmationModal'
import apiService from '../../../../../services/api/apiService'
import { useModalContext } from '../../../../../context/ModalsCtx/ModalsProvider'
import RenderIf from '../../../../../components/UI/RenderIf'

interface Props {
  file?: { id: number, file: string, client: string }
  isClientInfo?: boolean
  clientNumber?: string
}

function TradeAgreementFilePreview({ file, clientNumber, isClientInfo = false }:Props) {
  const { openConfirmationModalHandler } = useModalContext()
  return (
    <>
      <ConfirmationModal cacheKey={['clients', `client-${clientNumber}`]} toastSuccessMessage="tradeAgreementFileHasBeenRemoved" mutationFn={apiService.deleteTradeAgreementFile} />
      <div className="relative w-max">
        <a href={file?.file ?? '#'} className="block w-[70px] h-[70px] rounded-xl" target="_blank" rel="noreferrer">
          <img src={pdfIcon} alt="" className="w-full h-full rounded-xl cover" />
          <p className="ml-4 text-grey-pale text-sm mt-1">{file?.file?.split('/').at(-1)}</p>
        </a>
        <RenderIf isTrue={!isClientInfo}>
          <TiDelete
            className="absolute text-error -top-[5px] -right-[5px] z-10 cursor-pointer"
            size={28}
            onClick={() => openConfirmationModalHandler('deleteTradeAgreementText', { id: +file!.client, userRole: null })}
          />
        </RenderIf>

      </div>
    </>
  )
}

export default TradeAgreementFilePreview
