import * as yup from 'yup'
import { UserTypes } from '../interfaces/user'
import { ClientTypes } from '../interfaces/client'

const validationSchema = {
  login: yup.object({
    username: yup
      .string().trim()
      .required('Username required.'),
    password: yup.string().trim().required('Password required.')
  }),
  forgotPasswordForm: yup.object({
    email: yup.string().trim().email('Invalid email.').required('Email required.')
  }),
  activatePassword: yup.object({
    password: yup.string().trim().required('Password required.'),
    confirmPassword: yup.string().trim().oneOf([yup.ref('password'), null], 'passwordsMustMatch')
      .required('requiredConfirmation')
  }),
  userCreationForm: (role: UserTypes['user']['user_type'], user?: UserTypes) => yup.object({
    firstName: yup.string().trim().required('Employee name required.'),
    lastName: yup.string().trim().required('Employee surname required.'),
    email: yup.string().trim().email('Invalid email.').required('Email required.'),
    phone: yup.string().trim()
      .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, {
        message: 'Invalid phone number.',
        excludeEmptyString: false
      }).required('Phone number required.'),
    username: yup.string().required('Username required.'),
    department: user || role === 'DIRECTOR' ? yup.string().trim().optional() : yup.string().required('Department required.'),
    role: yup.string().trim().required('Role required.')
  }),
  updateUserDetailsForm: (password: string) => yup.object({
    firstName: yup.string().trim().required('firstName required.'),
    lastName: yup.string().trim().required('lastName required.'),
    email: yup.string().trim().email('Invalid email.').required('Email required.'),
    username: yup.string().trim().required('Username required.'),
    phone: yup.string().trim()
      .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, {
        message: 'Invalid phone number.',
        excludeEmptyString: false
      }).required('Phone number required.'),
    password: yup.string().trim(),
    confirmPassword: password.trim().length === 0 ? yup.string().optional() : yup.string().oneOf([yup.ref('password'), null], 'passwordsMustMatch')
      .required('requiredConfirmation')
  }),
  clientCreationForm: yup.object({
    code1: yup.string().trim().required('clientCode required.'),
    vatNumber: yup.string().trim().required('Vat number required.'),
    irsBranch: yup.string().trim().required('Irs branch required.'),
    fullName: yup.string().trim().required('Full name required.'),
    country: yup.string().trim().required('Country required.'),
    county: yup.string().trim().required('County required.'),
    area: yup.string().trim().required('Area required.'),
    address: yup.string().trim().required('Address required.'),
    city: yup.string().trim().required('City required.'),
    zipCode: yup.string().trim().required('Zip code required.'),
    taxExemption: yup.string().trim().required('Tax exemption required.'),
    email: yup.string().trim().email('Invalid email.').required('Email required.'),
    telephone: yup.string().trim().trim()
      .matches(/^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/, {
        message: 'Invalid phone number.',
        excludeEmptyString: false
      })
      .required('Phone number required.'),
    lastContact: yup.string().trim().required('Last contact required.'),
    category: yup.string().trim().required('Category required.'),
    agent:
      yup.string().trim().required('Sales agent required.')
  }),
  offerCreationForm: yup.object({
    offerTitle: yup.string().trim().required('Offer name required.'),
    startDate: yup.string().trim().required('Start date required.'),
    endDate: yup.string().trim().required('End date required.'),
    discountPercentage: yup.string().trim().required('Discount percentage required.'),
    status: yup.string().trim().required('Status required.'),
    usage: yup.string().trim().required('Usage required.')
  }),
  orderCreationForm: yup.object({
    clientInfo: yup.string().trim().required('Client selection required.').min(3, 'Client selection required.'),
    // clientVat: yup.string().trim().required('Client selection required.'),
    clientAddress: yup.string().trim().required('Shipping address required.'),
    paymentMethod: yup.string().trim().required('Payment method required.'),
    series: yup.string().trim().required('Documentary required.'),
    shipment: yup.string().trim().required('ShipmentMethod required.')
  }),
  complaintCreationForm: yup.object({
    notes: yup.string().trim().required('Notes required.'),
    action: yup.string().trim().required('Action required.'),
    clientInfo: yup.string().trim().required('Client selection required.').min(3, 'Client selection required.'),
    priority: yup.string().trim().required('Priority required.'),
    description: yup.string().trim().required('Description required.'),
    status: yup.string().trim().required('Status required.')
  }),
  taskCreationForm: yup.object({
    title: yup.string().trim().required('Title required.'),
    description: yup.string().trim().required('Description required.'),
    type: yup.string().trim().required('Type required.')
  }),
  campaignDetailsForm: (specificClients:ClientTypes[]) => yup.object({
    campaignName: yup.string().trim().required('Campaign name required.'),
    emailSubject: yup.string().trim().required('Email subject required.'),
    mailingList: specificClients.length > 0
      ? yup.string().trim().optional()
      : yup.string().trim().required('Mailing list required.')
  }),
  mailingListCreationForm: yup.object({
    mailingListName: yup.string().trim().required('Mailing list name required.')
  }),
  callResultCreationFrom: (callResult: string) => yup.object({
    newDate: callResult === 'Postponed' ? yup.string().required(' ') : yup.string().trim().optional()
  }),
  createComment: yup.object({
    comment: yup.string().trim().required('Comment required.').max(500, 'Max 500 characters.')
  })

}

export default validationSchema
