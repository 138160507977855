import { BsSearch } from 'react-icons/bs'
import { FiChevronDown } from 'react-icons/fi'
import { Dispatch, FormEvent, SetStateAction } from 'react'

interface Props {
  searchValue: string
  onSearchHandler: (e: FormEvent<HTMLInputElement>) => void
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

function SearchProduct({
  searchValue, onSearchHandler, setIsOpen
}:Props) {
  return (
    <div className="h-[40px] flex items-center border border-solid border-[#BEBEBE] rounded-md w-full lg:w-1/2">
      <input type="text" value={searchValue} onChange={onSearchHandler} className="w-full bg-transparent px-2 rounded-md outline-none" />
      <BsSearch color="#707070" className="pr-2" size={22} />
      <FiChevronDown
        className="h-full px-2 cursor-pointer border-l border-solid border-l-[#BEBEBE]"
        color="#707070"
        size={38}
        onClick={() => setIsOpen((prevState) => !prevState)}
      />
    </div>

  )
}

export default SearchProduct
