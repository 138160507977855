import { ChildrenTypes } from '../../interfaces/children'

function OrderDetailSkeleton() {
  return (
    <div className="flex flex-col gap-3">
      <div className="w-[160px] h-[28px] bg-gray-100 animate-pulse rounded-xl" />
      <div className="max-w-[350px] h-[34px] bg-gray-100 animate-pulse rounded-xl" />
      <div className="max-w-[420px] h-[34px] bg-gray-100 animate-pulse rounded-xl" />
    </div>
  )
}

function OrderDetailProductSkeletonWrapper({ children }:ChildrenTypes) {
  return <div className="flex pt-5 pb-2 border-b border-solid border-b-[#d4d4d4] px-7 flex gap-4 justify-between">{children}</div>
}

function OrderDetailProductSkeleton() {
  return <div className="last:text-right w-[200px] h-[28px] bg-gray-100 animate-pulse rounded-xl" />
}

function OrderInfoSkeleton() {
  return (
    <>
      <div className="flex items-center justify-between gap-4 flex-wrap mb-5">
        <div className="flex items-center gap-4 flex-wrap">
          <div className="w-[300px] sm:w-[384px] w-full h-[52px] bg-gray-200 animate-pulse rounded-xl" />
          <div className="w-[160px] h-[36px] bg-gray-200 animate-pulse rounded-xl" />
        </div>
        <div className="flex flex-col gap-4">
          <div className="w-[264px] h-[33px] bg-gray-200 animate-pulse rounded-xl" />
          <p className="md:self-end w-[128px] h-[32px] bg-gray-200 animate-pulse rounded-xl" />
        </div>
      </div>
      <div className="bg-white rounded-3xl shadow-faint w-full flex mx-auto flex-wrap mb-5 ">
        <div className="p-10 grid grid-cols-1 md:grid-cols-2 gap-10 w-full bg-gray-200 animate-pulse rounded-3xl">
          <OrderDetailSkeleton />
          <OrderDetailSkeleton />
          <OrderDetailSkeleton />
          <OrderDetailSkeleton />
          <OrderDetailSkeleton />
        </div>
      </div>

      <div className="w-[120px] h-[28px] rounded-xl bg-gray-200 animate-pulse" />
      <div className="bg-[#FBFBFB] rounded-xl w-full mt-5 pb-5 bg-gray-200 animate-pulse">
        <div className="overflow-x-auto">
          <div className="min-w-[1100px]">
            <OrderDetailProductSkeletonWrapper>
              <OrderDetailProductSkeleton />
              <OrderDetailProductSkeleton />
              <OrderDetailProductSkeleton />
              <OrderDetailProductSkeleton />
              <OrderDetailProductSkeleton />
            </OrderDetailProductSkeletonWrapper>
            <OrderDetailProductSkeletonWrapper>
              <OrderDetailProductSkeleton />
              <OrderDetailProductSkeleton />
              <OrderDetailProductSkeleton />
              <OrderDetailProductSkeleton />
              <OrderDetailProductSkeleton />
            </OrderDetailProductSkeletonWrapper>
            <OrderDetailProductSkeletonWrapper>
              <OrderDetailProductSkeleton />
              <OrderDetailProductSkeleton />
              <OrderDetailProductSkeleton />
              <OrderDetailProductSkeleton />
              <OrderDetailProductSkeleton />
            </OrderDetailProductSkeletonWrapper>
          </div>
        </div>
      </div>

      <div className="w-full lg:w-1/2 bg-[#FBFBFB] rounded-xl ml-auto mt-[9px] p-7 bg-gray-200 animate-pulse">
        <div className="flex items-center gap-7">
          <div className="sm:w-[90px] h-[30px] pb-3 rounded-xl bg-gray-100 animate-pulse" />
          <div className="text-lg gap-3 flex items-center text-[#959292] justify-between w-full border-b border-solid border-b-[#d4d4d4] pb-3 sm:pl-20">
            <div className="w-[70px] sm:w-[90px] h-[30px] pb-3 rounded-xl bg-gray-100 animate-pulse" />
            <div className="w-[70px] sm:w-[90px] h-[30px] pb-3 rounded-xl bg-gray-100 animate-pulse" />
            <div className="w-[70px] sm:w-[90px] h-[30px] pb-3 rounded-xl bg-gray-100 animate-pulse" />
          </div>
        </div>
        <div className="flex justify-end text-xl mt-[9px] font-semibold">
          <div className="w-[90px] h-[40px] pb-3 rounded-xl bg-gray-100 animate-pulse" />
        </div>
      </div>
    </>

  )
}

export default OrderInfoSkeleton
