import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation } from 'react-router-dom'
import PageTitle from '../../../../../components/UI/PageTitle'
import RenderIf from '../../../../../components/UI/RenderIf'
import routes from '../../../../../constants/routes'
import PageSection from '../../../../../components/UI/PageSection'

function CreateNewsletter() {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const isExactPath = pathname === routes.director.campaignPreview
    || pathname === routes.agent.campaignPreview
  useEffect(() => {
    document.title = `Demo CRM - ${t('createNewsletter')}`
  }, [t])

  return (
    <>
      <RenderIf isTrue={isExactPath}>
        <PageSection>
          <PageTitle text={t('newCampaign')} margin="mb-0" />
          <Outlet />
        </PageSection>
      </RenderIf>
      <RenderIf isTrue={!isExactPath}>
        <section className="mt-8 w-full">
          <PageTitle text={t('newCampaign')} />
          <Outlet />
        </section>
      </RenderIf>
    </>
  )
}

export default CreateNewsletter
