import SyncActions from './SyncActions'
import EmailActions from './EmailActions'
import CronActionsProvider from '../../../../../../context/CronActionsCtx/CronActionsProvider'

function CronActionsWrapper() {
  return (
    <div className="rounded-[10px] border border-[##E8E8E8] p-8 flex justify-between gap-6 flex-col lg:flex-row">
      <CronActionsProvider>
        <SyncActions />
      </CronActionsProvider>
      <div className="hidden lg:block w-[1px] min-h-full bg-[#E8E8E8]" />
      <EmailActions />
    </div>
  )
}

export default CronActionsWrapper
