import { useTranslation } from 'react-i18next'

interface Props {
  text: string
  onClickHandler: () => void
}

function ShowFiltersOnMapBtn({ text, onClickHandler }:Props) {
  const { t } = useTranslation()
  return (
    <button
      type="submit"
      onClick={onClickHandler}
      className="text-lg text-white rounded-[5px] py-2 px-[30px] min-h-full font-semibold hover:opacity-90 transition-opacity duration-200 text-white bg-btn-sub sm:py-0"
    >
      {t(text)}
    </button>
  )
}

export default ShowFiltersOnMapBtn
