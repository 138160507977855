import moment from 'moment/moment'
import { IoMdTime } from 'react-icons/io'
import { ClientLatestCallTypes } from '../../../../interfaces/client'
import CallStatus from '../../../../components/UI/CallStatus'
import { durationToTime } from '../../../../helpers/duration-to-time'

interface Props {
  latestCall: ClientLatestCallTypes
}

function LatestCall({
  latestCall
}: Props) {
  return (
    <div className="border-b border-solid border-b-[#E6E6E6] py-[11px] px-5 last:border-b-0">
      <div className="flex flex-col sm:items-center justify-between text-grey-pale gap-2 w-full sm:flex-row">
        <h3 className="font-semibold 2xl:truncate 2xl:max-w-[200px] 2xl:text-ellipsis" title={latestCall.client_name}>{latestCall.client_name}</h3>
        <div className="flex justify-between sm:block">
          <div className="sm:ml-auto">
            <CallStatus status={latestCall.status} />
          </div>
          <div className="text-sm items-center flex sm:flex-col relative">
            <p className="whitespace-nowrap">{latestCall.date && moment(latestCall.date).format('DD/MM/YYYY, HH:mm')}</p>
            <p className="flex items-center gap-1 absolute right-0 bottom-0 top-[30px]">
              <IoMdTime size={15} />
              {durationToTime(latestCall.duration)}
            </p>
          </div>
        </div>

      </div>
      <p title={latestCall.notes} className="text-left mt-[30px] text-[#BEBEBE] text-ellipsis line-clamp-1">{latestCall.notes}</p>
    </div>
  )
}

export default LatestCall
