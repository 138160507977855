import { useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import FormLayout from '../../../../../components/Form/FormLayout'
import FormInput from '../../../../../components/Form/FormInput'
import FormButtons from '../../../../../components/Form/FormButtons'
import FormSelect from '../../../../../components/Form/FormSelect'
import validationSchema from '../../../../../utils/validationSchema'
import { UserTypes } from '../../../../../interfaces/user'
import RenderIf from '../../../../../components/UI/RenderIf'
import UserMethods from '../../../../../utils/userMethods'
import {
  ClientCountryTypes,
  ClientCreationTypes,
  ClientJobCategoryTypes,
  ClientTypes
} from '../../../../../interfaces/client'
import clientTaxExemptionOptions from '../../../../../helpers/client-options'
import TradeAgreement from './TradeAgreement'
import TradeAgreementFilePreview from './TradeAgreementFilePreview'

interface Props {
  onSubmit: (values: ClientCreationTypes) => void
  isLoading:boolean
  agents?: UserTypes[]
  client?: ClientTypes
  clientCategories: ClientJobCategoryTypes[]
  clientCountries: ClientCountryTypes[]
}

function ClientCreationForm({
  onSubmit,
  isLoading,
  clientCategories = [],
  clientCountries = [],
  client,
  agents = []
}:Props) {
  const { t } = useTranslation()
  const authUser = UserMethods.getUser()
  const initialValues: ClientCreationTypes = {
    clientNumber: client?.client_number ?? '',
    code1: client?.code1 ?? '',
    vatNumber: client?.vat_number ?? '',
    irsBranch: client?.irs_branch ?? '',
    fullName: client?.full_name ?? '',
    email: client?.email ?? '',
    telephone: client?.telephone ?? '',
    country: client?.country ?? '',
    county: client?.prefecture ?? '',
    city: client?.city ?? '',
    area: client?.area ?? '',
    zipCode: client?.zip ?? '',
    taxExemption: client?.tax_exemption ?? '',
    address: client?.address ?? '',
    category: client?.category ?? '',
    lastContact: client ? moment(client?.last_contact).format('YYYY-MM-DD') : '',
    // eslint-disable-next-line no-nested-ternary
    agent: client ? client?.agent.id : authUser?.user.user_type === 'AGENT' ? authUser.id : '',
    clientAgreement: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: !client && validationSchema.clientCreationForm,
    onSubmit
  })

  return (
    <form onSubmit={formik.handleSubmit}>

      <FormLayout>
        <RenderIf isTrue={!!client}>
          <p className="ml-4 mb-2 text-lg font-semibold">
            Προς το παρόν η επεξεργασία πελάτη δεν υποστηρίζεται από το ERP.
            Μπορείτε να επεξεργαστείτε μόνο την εμπορική συμφωνία.
          </p>
        </RenderIf>
        <FormInput
          inputId="clientCode"
          name="clientCode"
          getFieldProps={formik.getFieldProps('code1')}
          errors={!!(formik.touched.code1 && formik.errors.code1)}
          errorName={formik.errors.code1}
          disabled={!!client}
        />
        <FormInput
          inputId="fullName"
          name="fullName"
          getFieldProps={formik.getFieldProps('fullName')}
          errors={!!(formik.touched.fullName && formik.errors.fullName)}
          errorName={formik.errors.fullName}
          disabled={!!client}
        />
        <RenderIf isTrue={!client}>
          <FormInput
            inputId="vatNumber"
            name="vatNumber"
            getFieldProps={formik.getFieldProps('vatNumber')}
            errors={!!(formik.touched.vatNumber && formik.errors.vatNumber)}
            errorName={formik.errors.vatNumber}
            disabled={!!client}
          />
        </RenderIf>
        <FormInput
          inputId="irsBranch"
          name="irsBranch"
          getFieldProps={formik.getFieldProps('irsBranch')}
          errors={!!(formik.touched.irsBranch && formik.errors.irsBranch)}
          errorName={formik.errors.irsBranch}
          disabled={!!client}
        />
        <FormInput
          inputId="email"
          name="Email"
          getFieldProps={formik.getFieldProps('email')}
          errors={!!(formik.touched.email && formik.errors.email)}
          errorName={formik.errors.email}
          disabled={!!client}
        />
        <FormInput
          inputId="telephone"
          name="telephone"
          getFieldProps={formik.getFieldProps('telephone')}
          errors={!!(formik.touched.telephone && formik.errors.telephone)}
          errorName={formik.errors.telephone}
          disabled={!!client}
        />
        <FormSelect
          labelText="country"
          getFieldProps={formik.getFieldProps('country')}
          errors={!!(formik.touched.country && formik.errors.country)}
          errorName={formik.errors.country}
          disabled={!!client}
        >
          <option value="">{t('chooseCountry')}</option>
          {clientCountries.map((country) => (
            <option
              key={country.COUNTRY_CODE}
              value={country.NAME}
            >
              {country.NAME[0].toUpperCase() + country.NAME.slice(1).toLowerCase()}
            </option>
          ))}
        </FormSelect>
        <FormInput
          inputId="county"
          name="county"
          getFieldProps={formik.getFieldProps('county')}
          errors={!!(formik.touched.county && formik.errors.county)}
          errorName={formik.errors.county}
          disabled={!!client}
        />
        <FormInput
          inputId="area"
          name="area"
          getFieldProps={formik.getFieldProps('area')}
          errors={!!(formik.touched.area && formik.errors.area)}
          errorName={formik.errors.area}
          disabled={!!client}
        />
        <FormInput
          inputId="address"
          name="address"
          getFieldProps={formik.getFieldProps('address')}
          errors={!!(formik.touched.address && formik.errors.address)}
          errorName={formik.errors.address}
          disabled={!!client}
        />
        <FormInput
          inputId="city"
          name="city"
          getFieldProps={formik.getFieldProps('city')}
          errors={!!(formik.touched.city && formik.errors.city)}
          errorName={formik.errors.city}
          disabled={!!client}
        />
        <FormInput
          inputId="zipCode"
          name="zipCode"
          getFieldProps={formik.getFieldProps('zipCode')}
          errors={!!(formik.touched.zipCode && formik.errors.zipCode)}
          errorName={formik.errors.zipCode}
          disabled={!!client}
        />
        <FormInput
          inputId="lastContact"
          name="lastContact"
          type="date"
          getFieldProps={formik.getFieldProps('lastContact')}
          errors={!!(formik.touched.lastContact && formik.errors.lastContact)}
          errorName={formik.errors.lastContact}
          disabled={!!client}
        />
        <FormSelect
          labelText="category"
          getFieldProps={formik.getFieldProps('category')}
          errors={!!(formik.touched.category && formik.errors.category)}
          errorName={formik.errors.category}
          disabled={!!client}
        >
          <option value="">{t('chooseCategory')}</option>
          {clientCategories.map((category) => (
            <option
              key={category.CODE}
              value={category.CODE}
            >
              {category.NAME}
            </option>
          ))}
        </FormSelect>
        <FormSelect
          labelText="taxStatus"
          getFieldProps={formik.getFieldProps('taxExemption')}
          errors={!!(formik.touched.taxExemption && formik.errors.taxExemption)}
          errorName={formik.errors.taxExemption}
          disabled={!!client}
        >
          {
            clientTaxExemptionOptions
              .map((option) => (
                <option key={option.id} value={option.value}>
                  {t(option.label)}
                </option>

              ))
          }
        </FormSelect>
        <RenderIf isTrue={authUser?.user.user_type === 'DIRECTOR' || authUser?.user.user_type === 'MANAGER'}>
          <FormSelect
            labelText="saleAgent"
            getFieldProps={formik.getFieldProps('agent')}
            errors={
              !!(formik.touched.agent && formik.errors.agent)
            }
            errorName={formik.errors.agent}
            disabled={!!client}
          >
            <option value="">{t('chooseSaleAgent')}</option>
            {agents.map((agent) => (
              <option
                key={agent.id}
                value={agent.id}
              >
                {agent.user.first_name}
                {' '}
                {agent.user.last_name}
              </option>
            ))}
          </FormSelect>
        </RenderIf>
        <RenderIf isTrue={!!client}>
          <div className="w-full" />
        </RenderIf>
        <div className="flex flex-col gap-4">
          <TradeAgreement
            setFieldValue={formik.setFieldValue}
            clientAgreement={formik.values.clientAgreement as File}
          />
          <RenderIf isTrue={!!client && client?.client_agreement.length !== 0 && formik.values.clientAgreement === ''}>
            <TradeAgreementFilePreview
              file={client?.client_agreement[0]}
              clientNumber={client?.client_number}
            />
          </RenderIf>
        </div>
      </FormLayout>
      <FormButtons isLoading={isLoading} btnText={client ? 'Update Client' : 'Add Client'} />
    </form>
  )
}

export default ClientCreationForm
