import { useMemo, useState } from 'react';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import { useTranslation } from 'react-i18next'
import WidgetTitle from '../UI/WidgetTitle'
import useGetData from '../../hooks/useGetData'
import { FrequencyOfOrdersByMonthTypes, FrequencyOfOrdersByYearTypes } from '../../interfaces/statistics'
import apiService from '../../services/api/apiService'

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

interface Props {
  text: string,
  clientNumber: string
}

function FrequencyOfOrdersChart({ text, clientNumber }:Props) {
  const { t } = useTranslation()
  const [isYear, setIsYear] = useState<boolean>(true)
  const { data: frequencyOfOrdersByYear } = useGetData<FrequencyOfOrdersByYearTypes[]>({
    queryKey: `frequencyOfOrdersByYear ${clientNumber}`,
    queryFn: () => apiService.getFrequencyOfOrdersByYear(clientNumber)
  })

  const transformedFrequencyOfOrdersByYear = useMemo(() => frequencyOfOrdersByYear?.map((item) => ({
    ...item,
    month: t(months[item.month - 1])
  })) ?? [], [t, frequencyOfOrdersByYear])

  const { data: frequencyOfOrdersByMonth } = useGetData<FrequencyOfOrdersByMonthTypes[]>({
    queryKey: 'frequencyOfOrdersByMonth',
    queryFn: () => apiService.getFrequencyOfOrdersByMonth(clientNumber)
  })

  const buttonClassesYear = isYear && 'bg-btn-sub text-white rounded-[5px]'
  const buttonClassesMonth = !isYear && 'bg-btn-sub text-white rounded-[5px]'
  return (
    <div className="bg-[#FBFBFB] lg:max-w-[800px] w-full min-h-full rounded-[10px] p-[10px] md:py-5 md:px-8 ">
      <div className="mb-5 flex items-center justify-between w-full">
        <WidgetTitle text={text} fontWeight="font-semibold" />
        <div className="rounded-[5px] border border-[#BEBEBE] font-semi text-[#BEBEBE] whitespace-nowrap gap-2">
          <button className={`${buttonClassesYear} w-[97px] h-[40px] hover:opacity-90 transition-all duration-300`} onClick={() => setIsYear(true)} type="button">{t('year')}</button>
          <button className={`${buttonClassesMonth} w-[97px] h-[40px] hover:opacity-90 transition-all duration-300`} onClick={() => setIsYear(false)} type="button">{t('month')}</button>
        </div>
      </div>
      <ResponsiveContainer width="99%" height="83%">
        <LineChart
          data={isYear ? transformedFrequencyOfOrdersByYear : frequencyOfOrdersByMonth ?? []}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey={isYear ? 'month' : 'day'} />
          <YAxis width={20} />
          <Tooltip />
          <Legend
            payload={
            [{
              value: t('total'),
              color: '#0584A5'
            },
            {
              value: t('completedOrders'),
              color: '#61A279'
            }]
            }
          />
          €
          <Line
            type="monotone"
            dataKey="total"
            stroke="#0584A5"
            name={t('total')}
          />
          <Line
            type="monotone"
            dataKey="completed"
            stroke="#61A279"
            name={t('completedOrders')}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}

export default FrequencyOfOrdersChart
