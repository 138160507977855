import { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useNavigate, Link } from 'react-router-dom'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import validationSchema from '../../../../utils/validationSchema'
import { LoginTypes } from '../../../../interfaces/login'
import apiService from '../../../../services/api/apiService'
import navigateTo from '../../../../utils/navigateTo'
import { UserTypes } from '../../../../interfaces/user'
import InputErrorMessage from '../../../../components/UI/InputErrorMsg'
import RenderIf from '../../../../components/UI/RenderIf'
import Spinner from '../../../../components/UI/Spinner'
import { useDataProvider } from '../../../../context/DataProviderCtx/DataProvider'
import routes from '../../../../constants/routes'
import useMutateData from '../../../../hooks/useMutateData'

function LoginForm() {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [captchaToken, setCaptchaToken] = useState<string | null>(null)
  const [isCaptchaValid, setIsCaptchaValid] = useState<boolean>(false)
  const navigate = useNavigate()
  const { setIsEnabled } = useDataProvider()
  const initialValues: LoginTypes = {
    username: '',
    password: ''
  }

  const { mutate: sendCaptchaToken } = useMutateData({
    key: '',
    mutationFn: apiService.sendCaptchaToken
  })

  useEffect(
    () => {
      if (captchaToken) {
        sendCaptchaToken(captchaToken, {
          onSuccess: () => {
            setIsCaptchaValid(true)
          }
        })
      }
    },
    [sendCaptchaToken, captchaToken]
  )

  const onCaptchaError = () => {
    setCaptchaToken(null)
    setIsCaptchaValid(false)
  }
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema.login,
    onSubmit: (values) => {
      if (!captchaToken && !isCaptchaValid && process.env.NODE_ENV === 'production') {
        toast.error<string>(t('captchaRequired'))
        return
      }

      setIsLoading(true)
      apiService
        .login(values)
        .then((res) => {
          const user: UserTypes = res?.data.data
          navigate(navigateTo(user.user.user_type))
          setIsEnabled(true)
        })
        .catch((error) => toast.error<string>(t(error.message)))
        .finally(() => setIsLoading(false))
    }
  })

  const inputDivClasses = 'relative flex flex-col w-3/4 mx-auto mt-8 text-grey-pale'

  return (
    <form onSubmit={formik.handleSubmit} className="flex flex-col relative">
      <div className={inputDivClasses}>
        <label htmlFor="username" className="mb-2">
          {t('Username')}
        </label>
        <input
          className={`border py-1 px-2 rounded-[5px] h-12 outline-none ${formik.errors.username ? 'border-red-700' : 'border-[#BEBEBE]'
          }`}
          id="username"
          {...formik.getFieldProps('username')}
        />
        <RenderIf isTrue={!!(formik.touched.username && formik.errors.username)}>
          <InputErrorMessage text={formik.errors.username!} />
        </RenderIf>
      </div>
      <div className={inputDivClasses}>
        <label htmlFor="password" className="mb-2">
          {t('Password')}
        </label>
        <input
          className={`border py-1 px-2 rounded-[5px] h-12 outline-none ${formik.errors.password && formik.touched.password ? 'border-red-700' : 'border-[#BEBEBE]'
          }`}
          id="password"
          type="password"
          {...formik.getFieldProps('password')}
        />
        <RenderIf isTrue={!!(formik.touched.password && formik.errors.password)}>
          <InputErrorMessage text={formik.errors.password!} />
        </RenderIf>
      </div>
      <div className="xs:flex xs:justify-between w-3/4 mx-auto mt-2 mb-4 text-grey-pale text-[14px]">
        <div className="mt-2 xs:mt-0 visited:text-purple-600 ml-auto">
          <Link to={routes.forgotPassword}>{t('Forgot password?')}</Link>
        </div>
      </div>
      <RenderIf isTrue={process.env.NODE_ENV === 'production'}>
        <div className="flex justify-center">
          <HCaptcha
            onExpire={onCaptchaError}
            onError={onCaptchaError}
            onClose={onCaptchaError}
            onVerify={(token) => setCaptchaToken(token)}
            sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY_PRODUCTION!}
          />
        </div>
      </RenderIf>
      <button
        disabled={process.env.NODE_ENV === 'development' ? false : !isCaptchaValid}
        className="disabled:bg-gray-300 disabled:cursor-not-allowed text-white text-[18px] bg-btn-sub w-3/4 text-center mx-auto p-4 mt-4 xs:mt-6 mb-20 rounded-[5px]"
        type="submit"
      >
        {isLoading ? <Spinner /> : t('Login')}
      </button>

    </form>
  )
}

export default LoginForm
