import { MdPrint } from 'react-icons/md';

interface Props {
  usedInTitle?: true | false
  usedInOrder?: true | false
}

function PrintButton({ usedInTitle, usedInOrder }: Props) {
  const onClickHandler = () => {
    window.print()
  }
  const disablePrint = 'print:hidden';
  return (
    <button
      type="button"
      className={`${disablePrint} w-max h-max px-3 py-2 border-solid rounded-[5px] ${usedInTitle ? `py-[11.1px] text-btn-sub text-4xl border-btn-sub ${usedInOrder ? 'mb-0 mt-1' : 'mb-8'} ml-2 border border-t` : 'text-grey-bold text-lg border border-[#BEBEBE]'}`}
      onClick={onClickHandler}
    >
      <MdPrint />
    </button>
  )
}

export default PrintButton
