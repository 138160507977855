import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { TfiMapAlt } from 'react-icons/tfi'
import { useMapFilters } from '../../../context/MapFiltersCtx/MapFiltersProvider'
import ClientsMap from '../../Shared/components/Map/ClientsMap'

function AgentMapModal() {
  const { t } = useTranslation()
  const mapFiltersCtx = useMapFilters()

  const backdropClasses = mapFiltersCtx.showAgentMapModal ? 'opacity-100 visible' : 'opacity-0 invisible'
  const modalClasses = mapFiltersCtx.showAgentMapModal ? 'opacity-100 scale-100' : 'opacity-0 scale-0'

  const overlays = document.getElementById('overlays') as HTMLElement

  return (
    <>
      <button
        type="button"
        onClick={() => mapFiltersCtx.setShowAgentMapModal(true)}
        className="bg-btn-sub flex items-center gap-2 text-[#F9F9F9] rounded-[5px] px-[18px] hover:opacity-90 transition-opacity duration-200"
      >
        <TfiMapAlt color="#fff" size={20} />
        {t('map')}
      </button>
      {ReactDOM.createPortal(
        <>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions */}
          <div
            className={`fixed inset-0 z-40 bg-[rgba(0,0,0,0.20)] transition-opacity duration-300 ${backdropClasses}`}
            onClick={() => mapFiltersCtx.setShowAgentMapModal(false)}
          />
          <div
            className={`absolute md:fixed md:top-1/2 md:-translate-y-1/2  left-1/2 -translate-x-1/2 top-10 z-50 max-w-[90%] w-full h-full md:h-auto origin-center transition-all duration-300 ${modalClasses} `}
          >
            <ClientsMap />
          </div>
        </>,
        overlays
      )}
    </>
  )
}

export default AgentMapModal
