import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { toast } from 'react-toastify'
import apiService from '../../../../../../services/api/apiService'
import CronActionBtn from './CronActionBtn'

function EmailActions() {
  const { t } = useTranslation()

  const [isLastDayOrdersLoading, setIsLastDayOrdersLoading] = useState<boolean>(false)
  const [isOutOfStockProducts, setIsOutOfStockProducts] = useState<boolean>(false)
  const [isBrokenEmailsLoading, setIsBrokenEmailsLoading] = useState<boolean>(false)

  const onSendLastDayOrders = () => {
    setIsLastDayOrdersLoading(true)
    apiService.sendLastDayOrdersEmails()
      .then(() => {
        toast.success<string>(t('lastDayOrdersEmailHasBeenSent'))
      })
      .catch((error) => {
        toast.error<string>(error.message)
      })
      .finally(() => {
        setIsLastDayOrdersLoading(false)
      })
  }

  const onSendOutOfStockProducts = () => {
    setIsOutOfStockProducts(true)
    apiService.sendOutOfStockProducts()
      .then(() => {
        toast.success<string>(t('outOfStockEmailProductsHasBeenSent'))
      })
      .catch((error) => {
        toast.error<string>(error.message)
      })
      .finally(() => {
        setIsOutOfStockProducts(false)
      })
  }

  const onSendBrokenEmails = () => {
    setIsBrokenEmailsLoading(true)
    apiService.sendBrokenGeocodeEmails()
      .then(() => {
        toast.success<string>(t('brokenGeocodeEmailsHasBeenSent'))
      })
      .catch((error) => {
        toast.error<string>(error.message)
      })
      .finally(() => {
        setIsBrokenEmailsLoading(false)
      })
  }

  return (
    <div className="flex flex-col gap-6 w-full">
      <h3 className="font-semibold">{t('sendEmail')}</h3>
      <CronActionBtn
        text="sendEmailForLastDayOrders"
        btnText="send"
        estimatedTime="instant"
        isLoading={isLastDayOrdersLoading}
        onClickHandler={onSendLastDayOrders}
      />
      <CronActionBtn
        text="sendEmailForOutOfStockProducts"
        btnText="send"
        estimatedTime="instant"
        isLoading={isOutOfStockProducts}
        onClickHandler={onSendOutOfStockProducts}
      />
      <CronActionBtn
        text="sendEmailForGeocode"
        btnText="send"
        estimatedTime="instant"
        isLoading={isBrokenEmailsLoading}
        onClickHandler={onSendBrokenEmails}
      />
    </div>
  )
}

export default EmailActions
