import {
  useMemo, useEffect, useState, useContext, useCallback
} from 'react'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { ChildrenTypes } from '../../interfaces/children'
import { CronTaskStateTypes, SyncCronActionTypes, TaskToStartTypes } from '../../interfaces/cron-actions'
import useGetData from '../../hooks/useGetData'
import apiService from '../../services/api/apiService'
import { CronActionsCtxTypes } from './cronActionsCtx.interface'
import cronActionsCtx from './cronActionsCtx'

export const useCronActionsCtx = ():CronActionsCtxTypes => useContext(cronActionsCtx)

function CronActionsProvider({ children }:ChildrenTypes) {
  const { t } = useTranslation()
  const [estimatedTime, setEstimatedTime] = useState<CronActionsCtxTypes['estimatedTime']>('')
  const [isTaskStarted, setIsTaskStarted] = useState<CronActionsCtxTypes['isTaskStarted']>(false)
  const [showSyncModal, setShowSyncModal] = useState<CronActionsCtxTypes['showSyncModal']>(false)

  const [taskToStart, setTaskToStart] = useState<TaskToStartTypes | null>(null)
  const [taskId, setTaskId] = useState<string | null>(null)

  const {
    data: syncCronActions,
    isLoading: isSyncCronActionsLoading,
    isError: syncActionHasError
  } = useGetData<SyncCronActionTypes>({
    queryKey: `SyncCronActionTypes ${taskToStart}`,
    // eslint-disable-next-line no-nested-ternary
    queryFn: taskToStart === 'clientsProducts'
      ? apiService.syncClientsAndProducts : taskToStart === 'orders'
        ? apiService.syncOrders
        : () => Promise.resolve(null),
    disabled: !isTaskStarted,
    cacheTime: 0,
    refetchOnWindowFocus: false,
    refetchOnMount: false
  })

  const {
    data: taskState,
    isLoading: isTaskStateLoading,
    isError: taskStateHasError
  } = useGetData<CronTaskStateTypes>({
    queryKey: `taskState ${taskId}`,
    queryFn: () => apiService.getTaskState(taskId),
    disabled: !taskId,
    refetchInterval: 1000,
    cacheTime: 0
  })

  const onLeavePageHandler = useCallback((e:BeforeUnloadEvent) => {
    e.preventDefault()
    e.returnValue = ''
  }, [])

  const onResetSyncActionsHandler = useCallback(() => {
    setTaskId(null)
    setTaskToStart(null)
    setShowSyncModal(false)
    setIsTaskStarted(false)
    window.removeEventListener('beforeunload', onLeavePageHandler)
  }, [
    onLeavePageHandler
  ])

  const closeSyncModalHandler = useCallback(() => {
    setShowSyncModal(false)
    setTaskToStart(null)
  }, [])

  const showSyncModalHandler = useCallback((et: string, task:TaskToStartTypes) => {
    setShowSyncModal(true)
    setEstimatedTime(et)
    setTaskToStart(task)
  }, [])

  const onSetTaskToStartHandler = useCallback(() => {
    setIsTaskStarted(true)
  }, [])

  useEffect(() => {
    if (syncCronActions) {
      setTaskId(syncCronActions.task_id)
      window.addEventListener('beforeunload', onLeavePageHandler)
    }
    return () => {
      window.removeEventListener('beforeunload', onLeavePageHandler)
    }
  }, [syncCronActions, onLeavePageHandler])

  useEffect(() => {
    if (syncActionHasError || taskStateHasError) {
      onResetSyncActionsHandler()
      toast.error<string>('syncActionFailed')
    }
  }, [
    syncActionHasError,
    taskStateHasError,
    onResetSyncActionsHandler
  ])

  useEffect(() => {
    if (taskId && taskState?.state === 'SUCCESS' && !taskState.data) {
      onResetSyncActionsHandler()
      toast.success<string>(t('taskCompleted'))
    }
  }, [
    taskId,
    taskState,
    onResetSyncActionsHandler,
    t
  ])

  const ctx = useMemo(() => ({
    showSyncModal,
    estimatedTime,
    isTaskStarted,
    taskState,
    isSyncCronActionsLoading,
    isTaskStateLoading,
    closeSyncModalHandler,
    showSyncModalHandler,
    onSetTaskToStartHandler
  }), [
    showSyncModal,
    estimatedTime,
    isTaskStarted,
    taskState,
    isSyncCronActionsLoading,
    isTaskStateLoading,
    closeSyncModalHandler,
    showSyncModalHandler,
    onSetTaskToStartHandler
  ])
  return (
    <cronActionsCtx.Provider value={ctx}>
      {children}
    </cronActionsCtx.Provider>
  )
}

export default CronActionsProvider
