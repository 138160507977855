import { ChangeEvent, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import CallInfo from './CallInfo'
import { TodayCallsTypes } from '../../../../interfaces/calls'
import SearchClientCall from './SearchClientCall'
import RenderIf from '../../../../components/UI/RenderIf'
import NoDataMessage from '../../../../components/UI/NoDataMessage'

interface Props {
  todayCalls: TodayCallsTypes[];
  isTodayCallsFetching: boolean;
}

function CallsInfo({ todayCalls = [], isTodayCallsFetching }:Props) {
  const { t } = useTranslation()
  const [filteredCalls, setFilteredCalls] = useState<TodayCallsTypes[]>(todayCalls)
  const [searchValue, setSearchValue] = useState('')
  const { pathname } = useLocation()

  const displayCallInfo = filteredCalls
    .map((todayCall) => (
      <CallInfo
        key={todayCall.id}
        todayCall={todayCall}
      />
    ))

  useEffect(() => {
    if (!isTodayCallsFetching) {
      setFilteredCalls(todayCalls)
    }
  }, [isTodayCallsFetching, todayCalls])

  const onSearchTodayCallsHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase()
    setSearchValue(value)
    if (value.trim().length > 0) {
      const filtered = todayCalls.filter((todayCall) => todayCall.daily_index.toString() === value
      || todayCall.client.full_name.toLowerCase().startsWith(value)
      || todayCall.client.telephone.startsWith(value))
      setFilteredCalls(filtered)
    } else {
      setFilteredCalls(todayCalls)
    }
  }

  const heightClass = pathname.startsWith('/agent/client-call-info') ? 'h-screen' : 'h-[calc(100vh-150px)]'
  return (
    <div className={`rounded-[16px] bg-white shadow-md 2xl:max-w-[450px] w-full mt-7 pt-6 pb-14 ${heightClass}`}>
      <div className="bg-[#F8F8F8] rounded-[5px] px-[14px] py-[19px] mx-2 sm:mx-6">
        <SearchClientCall
          searchValue={searchValue}
          onSearchTodayCallsHandler={onSearchTodayCallsHandler}
        />
      </div>
      <p className="px-6 my-[10px] text-grey-pale flex text-sm text-grey-pale">
        {t('calls')}
        {' '}
        {`${todayCalls.length}/120`}
      </p>

      <div className={`overflow-y-auto h-[calc(100%-85px)] px-2 sm:px-6 ${todayCalls.length > 0 ? 'scroll-bar-left' : ''}`}>
        <RenderIf isTrue={todayCalls.length === 0}>
          <NoDataMessage text="noCallsForToday" />
        </RenderIf>

        <div className="grid gap-[7px] w-full">
          {displayCallInfo}
        </div>
      </div>
    </div>

  )
}

export default CallsInfo
