import { useTranslation } from 'react-i18next'

interface Props {
  totalValues: {
    clients: number
    totalOrders: number
    totalOrdersSum: number
  }
}
function FilteredResultsTotalValues({ totalValues }: Props) {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col gap-2 justify-between sm:flex-row">
      <p>
        {t('clients')}
        :
        <span className="font-semibold ml-1">{totalValues.clients}</span>
      </p>
      <p>
        {t('numberOfOrders')}
        :
        <span className="font-semibold  ml-1">{totalValues.totalOrders}</span>
      </p>
      <p>
        {t('totalOrdersSum')}
        :
        <span className="font-semibold  ml-1">
          {totalValues.totalOrdersSum.toFixed(2)}
          €
        </span>
      </p>
    </div>
  )
}

export default FilteredResultsTotalValues
