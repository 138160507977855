import CallsHistory from './CallsHistory'
import useGetData from '../../hooks/useGetData'
import { ClientCallHistoryTypes } from '../../interfaces/client'
import apiService from '../../services/api/apiService'
import RenderIf from '../UI/RenderIf'
import CallsHistorySkeleton from '../Skeletons/CallsHistorySkeleton'

interface Props {
  clientNumber: string
  height?: 'max-h-[492px]' | 'max-h-[calc(100vh-495px)]'
}

function CallHistoryContainer({ clientNumber, height = 'max-h-[calc(100vh-495px)]' }:Props) {
  const {
    data:
      callHistory,
    isLoading: isCallHistoryLoading
  } = useGetData<ClientCallHistoryTypes[]>(
    {
      queryKey: `callHistory, ${clientNumber}`,
      queryFn: () => apiService.getClientsCallHistory(clientNumber)
    }
  )

  return (
    <>
      <RenderIf isTrue={isCallHistoryLoading}>
        <CallsHistorySkeleton />
      </RenderIf>
      <RenderIf isTrue={!isCallHistoryLoading}>
        <CallsHistory height={height} callHistory={callHistory} />
      </RenderIf>
    </>
  )
}

export default CallHistoryContainer
