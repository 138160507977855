// eslint-disable-next-line import/prefer-default-export
export const callResultOptions = [
  {
    id: 1,
    value: 'New order',
    label: 'New order'
  },
  {
    id: 2,
    value: 'Completed',
    label: 'Completed'
  },
  {
    id: 3,
    value: 'Postponed',
    label: 'Postponed'
  },
  {
    id: 4,
    value: 'No answer',
    label: 'No answer'
  },
  {
    id: 5,
    value: 'Busy',
    label: 'Busy'
  },
  {
    id: 6,
    value: 'Wrong number',
    label: 'Wrong number'
  },
  {
    id: 7,
    value: 'Do not disturb',
    label: 'Do not disturb'
  }
]
