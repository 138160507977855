import {
  useGlobalFilter, usePagination, useTable
} from 'react-table'
import { useLocation, useNavigate } from 'react-router-dom'
import { ChangeEvent } from 'react'
import { CgScrollH } from 'react-icons/cg'
import Pagination from './Pagination'
import Search from './Search'
import RenderIf from '../UI/RenderIf'
import NoDataMessage from '../UI/NoDataMessage'
import TableFiltersDropdown from './TableFiltersDropdown'
import PrintButton from '../UI/PrintButton'
import { ClientTypes, InfiniteActionPaginateTypes } from '../../interfaces/client'
import { StorageKeyTypes } from '../../interfaces/table'
import SearchFromApi from './SearchFromApi'
import { useDataProvider } from '../../context/DataProviderCtx/DataProvider'
import ViewSelectedClientsBtn from '../../pages/Shared/pages/Newsletters/components/MailingList/ViewSelectedClientsBtn'
import UserMethods from '../../utils/userMethods'
import { tableRowNavigation } from '../../helpers/tableRowNavigation'
import AgentMapModal from '../../pages/SalesAgent/components/AgentMapModal'
import MapFiltersProvider from '../../context/MapFiltersCtx/MapFiltersProvider'
import ToughClientsFilter from '../../pages/Shared/pages/Clients/components/ToughClientsFilter'

interface TableProps {
  columns: any,
  data: any,
  storageKey: StorageKeyTypes,
  rowsPerPage?: number
  apiPagination?: InfiniteActionPaginateTypes
  searchText?: string
  onSearchHandler?: (event: ChangeEvent<HTMLInputElement>) => void
  selectedClients?: ClientTypes[]
  onShowSelectedClientsHandler?: () => void
  isHidden?: boolean
}

export default function Table({
  data,
  columns,
  storageKey,
  rowsPerPage = 5,
  apiPagination,
  searchText,
  onSearchHandler,
  selectedClients = [],
  onShowSelectedClientsHandler,
  isHidden = false
}: TableProps) {
  const authUser = UserMethods.getUser()
  const complaintCreatorColumn = authUser?.user.user_type !== 'DIRECTOR' ? 'creator' : ''
  const initialColumns = JSON.parse(localStorage.getItem(storageKey)!) ?? []
  const {
    isClientsLoading
  } = useDataProvider()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isClientsTable = pathname.includes('/clients')
  const isMailingListTable = pathname.includes('/create-newsletter')
  const isOrdersTable = pathname.includes('/orders')
  const isCallsTable = pathname.includes('/calls')
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    rows,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    state,
    gotoPage,
    setGlobalFilter,
    allColumns
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: rowsPerPage,
        hiddenColumns: [...initialColumns, complaintCreatorColumn]
      }
    },
    useGlobalFilter,
    usePagination
  )
  const { pageIndex, pageSize } = state;
  const disablePrint = 'print:hidden';
  const print = 'print:m-0 print:p-0 print:overflow-x-visible print:text-[10px]'
  const disableRowHoverNavigation = storageKey === 'mailingListTable'
    || storageKey === 'callsTable'
    || storageKey === 'offersTable'
    || storageKey === 'tasksTable'
    || storageKey === 'newslettersTable'

  return (
    <div className={`${isHidden ? 'hidden' : 'block'} bg-white rounded-2xl w-full h-max ${isMailingListTable ? 'mt-0 shadow-none p-0' : 'mt-7 shadow-faint p-0 md:p-8'} `}>
      <div className={`${disablePrint}  bg-[#F8F8F8] flex justify-between items-center px-2 py-5 md:p-5 mb-8 rounded-md flex-wrap md:flex-nowrap gap-3`}>
        <RenderIf isTrue={
          !isMailingListTable
          && !isClientsTable
          && !isOrdersTable
          && !isCallsTable
        }
        >
          <Search setFilter={setGlobalFilter} />
        </RenderIf>
        <RenderIf isTrue={isMailingListTable || isClientsTable || isOrdersTable || isCallsTable}>
          <div className="flex items-center w-full sm:w-[80%] gap-3 sm:gap-5 flex-wrap">
            <SearchFromApi
              searchText={searchText!}
              onChangeHandler={onSearchHandler!}
              isLoading={isClientsLoading}
            />
            <RenderIf isTrue={isMailingListTable}>
              <ViewSelectedClientsBtn
                selectedClients={selectedClients}
                onClickHandler={onShowSelectedClientsHandler!}
              />
            </RenderIf>
          </div>
        </RenderIf>

        <div className="flex gap-2 w-full md:w-auto">
          <PrintButton />
          <TableFiltersDropdown
            allColumns={allColumns}
            storageKey={storageKey}
          >
            <RenderIf isTrue={isClientsTable}>
              <ToughClientsFilter />
            </RenderIf>
          </TableFiltersDropdown>
          <RenderIf isTrue={isClientsTable && authUser?.user.user_type === 'AGENT'}>
            <MapFiltersProvider>
              <AgentMapModal />
            </MapFiltersProvider>
          </RenderIf>
          <CgScrollH size={35} className="ml-auto md:hidden text-grey-pale" />
        </div>
      </div>
      <div className="overflow-auto print:overflow-visible">
        <table {...getTableProps()} className={`w-full border-collapse ${isClientsTable ? 'min-w-[1400px]' : 'min-w-[1100px]'} print:min-w-[400px] print:max-w-[1100px] text-[#959292]`}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    style={!isClientsTable ? { width: `calc(100% / ${column.width})` } : { width: 'auto' }}
                    {...column.getHeaderProps()}
                    className={`${isClientsTable ? 'px-2' : 'px-2 2xl:px-0'} ${print} text-left text-[#4D4D4D] text-lg font-semibold border-b border-b-grey-pale pb-5 whitespace-nowrap `}
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr className={`${disableRowHoverNavigation ? '' : 'transition-colors duration-200 hover:bg-[rgba(0,0,0,0.05)] cursor-pointer'} border-b border-b-[#E8E8E8] last:border-b-0`} {...row.getRowProps()}>
                  {row.cells.map((cell, index) => {
                    const disableColumnActionNumber = isClientsTable
                      ? index !== row.cells.length - 1 && index !== row.cells.length - 2
                      : index !== row.cells.length - 1
                    return (
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
                      <td
                        onClick={() => disableColumnActionNumber
                          && navigate(tableRowNavigation({
                            key: storageKey,
                            rowData: cell.row.original
                          }))}
                        {...cell.getCellProps()}
                        className={` ${isClientsTable ? 'px-2' : 'px-2 2xl:px-0'} ${print} ${rowsPerPage === 10 || isMailingListTable ? 'h-[50px]' : 'h-[80px]'} text-black`}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <RenderIf isTrue={rows.length === 0}>
        <NoDataMessage text="noEntriesFound" />
      </RenderIf>

      <Pagination
        pageLength={page.length}
        nextPage={nextPage}
        canNextPage={canNextPage}
        previousPage={previousPage}
        canPreviousPage={canPreviousPage}
        pageOptions={pageOptions}
        pageIndex={pageIndex}
        pageSize={pageSize}
        gotoPage={gotoPage}
        apiPagination={apiPagination}
      />
    </div>

  )
}
