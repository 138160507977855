function AverageOrderSkeleton() {
  return (
    <div className="flex flex-col p-5 justify-between rounded-[10px] gap-6 bg-gray-200 animate-pulse h-full">
      <div className="rounded-[10px] animate-pulse bg-gray-100 w-full h-[25px]" />
      <div className="flex items-center gap-5">
        <div className="rounded-[10px] animate-pulse bg-gray-100 w-[70px] h-[35px]" />
        <div className="rounded-[10px] animate-pulse bg-gray-100 w-[150px] h-[35px]" />
      </div>
      <div className="self-end rounded-[10px] animate-pulse bg-gray-100 w-[150px] h-[35px]" />
    </div>
  )
}

function AverageOrdersSkeleton() {
  return (
    <div className="flex flex-1 flex-col gap-6 lg:justify-between">
      <AverageOrderSkeleton />
      <AverageOrderSkeleton />
    </div>
  )
}

export default AverageOrdersSkeleton
