import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Table from '../../../../../components/Table/Table'
import { ProductTypes } from '../../../../../interfaces/products'
import StockIcon from '../../../../../components/UI/StockIcon'

interface Props {
  products : ProductTypes[]
}

function ProductsTable({ products = [] }:Props) {
  const { t } = useTranslation()

  const columns = useMemo(() => [
    {
      Header: t('productCode'),
      accessor: 'product_id'
    },
    {
      Header: t('skuProduct'),
      accessor: 'product_sku',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p title={value} className="whitespace-nowrap">{value}</p>
    },
    {
      Header: t('name'),
      accessor: 'product_name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p title={value} className="overflow-y-hidden text-ellipsis line-clamp-1 w-[500px]">{value}</p>
    },
    {
      Header: t('category'),
      accessor: 'product_category',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p title={value}>{value || '-'}</p>
    },
    {
      Header: t('productGroup'),
      accessor: 'product_group',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => <p title={value}>{value || '-'}</p>
    },
    {
      Header: t('price'),
      accessor: 'price',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => (
        <p>
          €
          {value}
        </p>
      )
    },
    {
      Header: t('tax'),
      accessor: 'product_tax',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => (
        <p>
          {value?.includes('Μηδενικός Συντελεστής ΦΠΑ') ? value?.replace('Μηδενικός Συντελεστής ΦΠΑ ', '') : value?.replace('ΦΠΑ ', '')}
        </p>
      )

    },
    {
      Header: t('stock'),
      accessor: 'remaining_stock',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => (
        <p className="flex  items-center gap-1">
          <StockIcon stock={+value} />
          {value}
        </p>
      )
    },
    {
      Header: t('sold'),
      accessor: 'sold_stock',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: ({ value }:{ value:string }) => (
        <p className="flex  items-center gap-1">
          {Math.abs(+value)}
        </p>
      )
    }

  ], [t]);

  return (
    <Table
      columns={columns}
      data={products}
      storageKey="productsTable"
      rowsPerPage={10}
    />
  )
}

export default ProductsTable
