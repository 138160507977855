import { useTranslation } from 'react-i18next'
import { IoMdRemoveCircle } from 'react-icons/io'
import { ChangeEvent } from 'react'
import AddProductHeaderValue from './AddProductHeaderValue'
import AddProductValue from './AddProductValue'
import { OrderProductTypes, ProductTypes } from '../../../../../interfaces/products'
import ProductsList from './ProductsList'
import { useProductsCart } from '../../../../../context/ProductsCartCtx/ProductsCartProvider'
import StockIcon from '../../../../../components/UI/StockIcon'
import OrderTotalAmount from './OrderTotalAmount'
import RenderIf from '../../../../../components/UI/RenderIf'

interface Props {
  products: ProductTypes[]
  orderProducts?: OrderProductTypes[]
  orderTotalPrice?: string
}

function AddProductsToOrder({ products = [], orderProducts, orderTotalPrice }: Props) {
  const {
    selectedProducts,
    addProduct,
    removeProduct,
    updateProductQuantity,
    updateProductBuyPrice
  } = useProductsCart()

  const { t } = useTranslation()
  const print = 'print:overflow-visible print:w-[900px]'

  const quantityText = (product:OrderProductTypes) => {
    if (product.uncompleted_quantity! > 0) return `${+product.quantity - product.uncompleted_quantity!}/${product.quantity} τμχ`
    return `${product.quantity} τμχ`
  }

  return (
    <div>
      <h3 className="text-grey-bold text-xl">{t('products')}</h3>
      <div className="bg-[#FBFBFB] rounded-xl w-full mt-5 pb-5 ">
        <div className="overflow-x-auto print:overflow-x-visible">
          <div className="min-w-[1100px] print:text-[10px]">
            <div className={`flex pt-5 pb-2 border-b border-solid border-b-[#d4d4d4] px-7 ${print}`}>
              <AddProductHeaderValue text="SKU" width="max-w-[230px]" />
              <AddProductHeaderValue text="product" width="min-w-[400px] 2xl:min-w-[700px]" />
              <AddProductHeaderValue text="quantity" width="max-w-[300px]" />
              <RenderIf isTrue={!orderProducts}>
                <AddProductHeaderValue text="stock" width="min-w-[100px]" />
              </RenderIf>
              <AddProductHeaderValue text="tax" width="max-w-[100px]" />
              <AddProductHeaderValue text="price" />
            </div>

            {(orderProducts ?? selectedProducts).map((product) => (
              <div className={`flex pt-5 pb-2 items-center relative px-7 ${print}`} key={product.product.product_sku + product.quantity}>
                <RenderIf isTrue={!orderProducts}>
                  <IoMdRemoveCircle
                    size={18}
                    className="h-max absolute left-1 cursor-pointer text-error"
                    onClick={() => removeProduct(product.product.product_sku)}
                  />
                </RenderIf>
                <AddProductValue
                  width="max-w-[230px]"
                  text={product.product.product_sku}
                  isUncompleted={product.uncompleted_quantity! > 0}
                />
                <AddProductValue
                  width="min-w-[400px] 2xl:min-w-[700px]"
                  text={product.product.product_name}
                  isUncompleted={product.uncompleted_quantity! > 0}
                />
                <AddProductValue
                  width="max-w-[300px]"
                  text={orderProducts ? quantityText(product) : product.quantity}
                  type={orderProducts ? 'text' : 'number'}
                  inputText="τμχ"
                  min={product.product.remaining_stock !== 0 ? 1 : 0}
                  max={product.product.remaining_stock}
                  value={product.quantity}
                  disabled={!!orderProducts}
                  onChangeHandler={
                    (event: ChangeEvent<HTMLInputElement>) => updateProductQuantity(event, product)
                  }
                  style={{
                    width: '70px'
                  }}
                  isUncompleted={product.uncompleted_quantity! > 0}

                />
                <RenderIf isTrue={!orderProducts}>
                  <AddProductValue
                    width="min-w-[100px]"
                    text={
                      (
                        <span className="flex items-center gap-1">
                          <StockIcon stock={product.product.remaining_stock} />
                          {product.product.remaining_stock}
                        </span>
                      )
                    }
                  />
                </RenderIf>

                <AddProductValue
                  width="max-w-[100px]"
                  text={product.product.product_tax?.includes('Μηδενικός Συντελεστής ΦΠΑ')
                    ? product.product.product_tax?.replace('Μηδενικός Συντελεστής ΦΠΑ ', '')
                    : product.product.product_tax?.replace('ΦΠΑ ', '')}
                />
                <AddProductValue
                  text={product.buy_price}
                  type="number"
                  inputText="€"
                  min={0}
                  step="0.01"
                  value={product.buy_price.toFixed(2)}
                  disabled={!!orderProducts}
                  onChangeHandler={
                    (event: ChangeEvent<HTMLInputElement>) => updateProductBuyPrice(event, product)
                  }
                  style={{
                    width: '100px',
                    marginLeft: 'auto'
                  }}
                />
              </div>
            ))}
          </div>
        </div>
        <ProductsList products={products} orderProducts={orderProducts} addProduct={addProduct} />
      </div>
      <OrderTotalAmount orderTotalPrice={orderTotalPrice} orderProducts={orderProducts} />
    </div>
  )
}

export default AddProductsToOrder
