import { useOutletContext, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { ComplaintTypes } from '../../../../../interfaces/complaints'
import PageTitle from '../../../../../components/UI/PageTitle'
import PageSection from '../../../../../components/UI/PageSection'
import ComplaintDetails from '../components/ComplaintDetails'
import PrintButton from '../../../../../components/UI/PrintButton'

function ComplaintInfo() {
  const { t } = useTranslation()
  const params = useParams<{ id: string }>()
  const complaints = useOutletContext<ComplaintTypes[]>()
  const complaint = complaints
    ?.find((currentComplaint) => currentComplaint.id.toString() === params.id)

  useEffect(() => {
    document.title = `Demo CRM - ${t('complaint')}`
  }, [t])

  return (
    <PageSection>
      <div className="text-white flex justify-between items-center flex-wrap gap-4 mb-3">
        <PageTitle text={`${t('complaint')} ${complaint?.id ?? ''}`} margin="mb-0" />
        <div className="flex gap-2 items-end">
          <div>
            <p className="text-xl text-primary-text-dark">{t('ticketStatus')}</p>
            <p className="text-grey-pale bg-white rounded-[12px] text-lg py-2 px-4 mt-2">{t(complaint?.status ?? '')}</p>
          </div>
          <PrintButton usedInTitle usedInOrder />
        </div>
      </div>
      <ComplaintDetails complaint={complaint} />
    </PageSection>
  )
}

export default ComplaintInfo
