import useGetData from '../../../../../hooks/useGetData'
import { ClientCommentsTypes, ClientTypes } from '../../../../../interfaces/client'
import apiService from '../../../../../services/api/apiService'
import ClientComment from './ClientComment'
import RenderIf from '../../../../../components/UI/RenderIf'
import NoDataMessage from '../../../../../components/UI/NoDataMessage'
import useMutateData from '../../../../../hooks/useMutateData'

interface Props {
  client: ClientTypes
}
function ClientComments({ client }:Props) {
  const { data: comments, isLoading } = useGetData<ClientCommentsTypes[]>({
    queryKey: `clientComments ${client.client_number}`,
    queryFn: () => apiService.getClientComments(client.client_number)
  })

  const { mutate } = useMutateData({
    key: `clientComments ${client.client_number}`,
    mutationFn: apiService.deleteClientComment,
    successMessage: 'commentHasBeenDeleted'
  })

  const displayComments = comments?.map((comment) => (
    <ClientComment
      key={comment.id}
      comment={comment}
      onDeleteHandler={() => mutate(comment.id)}
    />
  ))

  return (
    <div className="flex flex-col w-full flex-col-reverse gap-4 h-[220px] overflow-y-auto mb-2 ">
      <RenderIf isTrue={!isLoading}>
        {displayComments?.reverse()}
      </RenderIf>
      <RenderIf isTrue={displayComments?.length === 0}>
        <NoDataMessage text="No comments found." />
      </RenderIf>
    </div>
  )
}

export default ClientComments
