import { useTranslation } from 'react-i18next'
import { HiOutlineChevronDown } from 'react-icons/hi'
import useToggleMenu from '../../../../hooks/useToggleMenu'
import Dropdown from '../../../../components/Dropdown/Dropdown'
import { ClientsCitiesTypes } from '../../../../interfaces/client'

interface Props {
  clientsCities: ClientsCitiesTypes[]
  onCitiesChangeHandler: (option: ClientsCitiesTypes) => void
}
function SelectClientCity({ clientsCities = [], onCitiesChangeHandler }:Props) {
  const { t } = useTranslation()
  const { isOpen, setIsOpen, ref } = useToggleMenu()

  return (
    <div ref={ref} className="relative text-grey-pale w-full sm:w-max">
      <button
        onClick={() => setIsOpen((prevState) => !prevState)}
        type="button"
        className="flex items-center bg-white justify-between gap-5 rounded-[5px] border border-solid border-[#BEBEBE] px-4 py-2 w-full"
      >
        {t('city')}
        <HiOutlineChevronDown size={18} className={`${isOpen ? '-rotate-180' : 'rotate-0'} transition-transform duration-300`} />
      </button>
      <Dropdown isOpen={isOpen} align="left-0 origin-top-left" triangleAlignment="left-[12px]">
        <div className="h-[300px] overflow-y-auto w-[300px]">
          {clientsCities.map((city) => (
            <div key={city.city} className="flex gap-2 items-center justify-between mx-3 border-b border-solid border-b-[#f2f2f2] py-2 last:border-b-0">
              {city.city}
              <input
                type="checkbox"
                className="mr-2"
                checked={city.isSelected}
                onChange={() => onCitiesChangeHandler(city)}
              />
            </div>
          ))}
        </div>
      </Dropdown>
    </div>
  )
}

export default SelectClientCity
