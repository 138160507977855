import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  BsCalendar, BsCheckAll, BsFillTelephoneInboundFill, BsFillTelephoneMinusFill, BsFillTelephoneXFill
} from 'react-icons/bs'
import { FcCancel } from 'react-icons/fc'
import { FaPhoneSlash } from 'react-icons/fa'
import { CallStatusTypes } from '../../interfaces/calls'

interface Props {
  status: CallStatusTypes
}

function CallStatus({ status }:Props) {
  const { t } = useTranslation()

  let icon: JSX.Element | null

  switch (status) {
    case 'New order':
      icon = <BsCheckAll className="text-success" size={21} />
      break
    case 'Completed':
      icon = <BsFillTelephoneInboundFill className="text-success" size={14} />
      break
    case 'Postponed':
      icon = <BsCalendar color="#F8D134" size={16} />
      break
    case 'No answer':
      icon = <BsFillTelephoneXFill color="#F8D134" size={14} />
      break
    case 'Busy':
      icon = <BsFillTelephoneMinusFill color="#F8D134" size={14} />
      break
    case 'Wrong number':
      icon = <FcCancel className="text-error" size={17} />
      break
    case 'Do not disturb':
      icon = <FaPhoneSlash className="text-error rotate-[90deg]" size={15} />
      break
    default:
      icon = null
  }

  return (
    <div className="flex items-center gap-[10px] max-w-full whitespace-nowrap">
      {icon}
      <p className={`${status === 'New order' ? '-translate-x-[7px]' : ''}`}>{t(status)}</p>
    </div>
  )
}

export default CallStatus
