import { BsCardList, BsArchive } from 'react-icons/bs'
import useGetData from '../../../../../hooks/useGetData'
import { AverageOrdersPerMonthTypes, AverageOrdersPerYearTypes } from '../../../../../interfaces/statistics'
import apiService from '../../../../../services/api/apiService'
import AverageOfOrder from './AverageOfOrder'
import AverageOrdersSkeleton from '../../../../../components/Skeletons/AverageOrdersSkeleton'
import RenderIf from '../../../../../components/UI/RenderIf'

interface Props {
  clientNumber: string
}

function AverageOfOrders({ clientNumber }:Props) {
  const {
    data: averageOrdersPerMonth,
    isLoading: isAverageOrdersPerMonthLoading
  } = useGetData<AverageOrdersPerMonthTypes>({
    queryKey: `averageOrdersPerMonth ${clientNumber}`,
    queryFn: () => apiService.getAverageOrdersPerMonth(clientNumber)
  })

  const {
    data: averageOrdersPerYear,
    isLoading: isAverageOrdersPerYearLoading
  } = useGetData<AverageOrdersPerYearTypes>({
    queryKey: `averageOrdersPerYear ${clientNumber}`,
    queryFn: () => apiService.getAverageOrdersPerYear(clientNumber)
  })
  return (
    <>
      <RenderIf isTrue={isAverageOrdersPerMonthLoading || isAverageOrdersPerYearLoading}>
        <AverageOrdersSkeleton />
      </RenderIf>
      <RenderIf isTrue={!isAverageOrdersPerMonthLoading && !isAverageOrdersPerYearLoading}>
        <div className="flex flex-col gap-6 lg:justify-between">
          <AverageOfOrder icon={<BsCardList size={40} />} text="averageOrdersPerMonth" averageOrdersPerMonth={averageOrdersPerMonth} />
          <AverageOfOrder icon={<BsArchive size={40} />} text="averageOrdersPerYear" averageOrdersPerYear={averageOrdersPerYear} />
        </div>
      </RenderIf>
    </>
  )
}

export default AverageOfOrders
