import moment from 'moment'
import { FaChevronRight } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { ClientLatestOrderTypes } from '../../interfaces/client'
import routes from '../../constants/routes'
import UserMethods from '../../utils/userMethods'

interface Props {
  latestOrder: ClientLatestOrderTypes
}
function LatestOrder({ latestOrder }: Props) {
  const navigate = useNavigate()
  const authUser = UserMethods.getUser()
  return (
    <button
      type="button"
      onClick={() => navigate(`/${authUser?.user.user_type.toLowerCase()}/orders/${routes.shared.orderInfo}/${latestOrder.order_id}`)}
      className="border-b border-solid border-b-[#E6E6E6] py-[16px] pr-1 cursor-pointer first:pt-0 last:border-b-0"
    >
      <div className="flex items-center justify-between text-grey-pale flex-wrap gap-2 w-full">
        <div className="flex items-center gap-2 flex-wrap max-w-full">
          <h3 className="font-semibold truncate max-w-[400px]">
            #
            {latestOrder.order_number}
          </h3>
        </div>
        <p className="text-sm">{moment(latestOrder.created_date).format('DD/MM/YYYY')}</p>
      </div>
      <div className="flex items-center justify-between">
        <p className="text-left mt-[15px] text-[#BEBEBE] text-ellipsis line-clamp-2">
          {latestOrder.total_price.toFixed(2)}
          €
        </p>
        <FaChevronRight className="text-grey-pale" />
      </div>

    </button>
  )
}

export default LatestOrder
