import { useTranslation } from 'react-i18next'
import { AverageOrdersPerMonthTypes, AverageOrdersPerYearTypes } from '../../../../../interfaces/statistics'

interface Props {
  text: string
  averageOrdersPerYear?: AverageOrdersPerYearTypes
  averageOrdersPerMonth?: AverageOrdersPerMonthTypes
  icon: JSX.Element
}

function AverageOfOrder({
  text, icon, averageOrdersPerMonth, averageOrdersPerYear
}:Props) {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col p-5 justify-between border border-[#E6E6E6] rounded-[10px] gap-6">
      <h3 className="text-lg font-semibold">{t(text)}</h3>
      <div className="flex items-center gap-5">
        {icon}
        <p className="font-bold text-[35px]">{averageOrdersPerMonth?.average_orders ?? averageOrdersPerYear?.average_orders}</p>
      </div>
      <p className="self-end font-semibold text-[22px]">{`${t('value')}: ${averageOrdersPerMonth?.average_price ?? averageOrdersPerYear?.average_worth ?? 0}€`}</p>

    </div>
  )
}

export default AverageOfOrder
