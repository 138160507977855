import { Outlet, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import Title from '../../components/UI/Title'
import SubTitle from '../../components/UI/SubTitle'
import RenderIf from '../../components/UI/RenderIf'
import useGetData from '../../hooks/useGetData'
import { TodayCallsTypes } from '../../interfaces/calls'
import apiService from '../../services/api/apiService'
import LatestCallsContainer from './components/LatestCalls/LatestCallsContainer'
import CallsInfoContainer from './components/CallsInfo/CallsInfoContainer'
import ControlPanelTasksContainer from '../Shared/pages/Tasks/components/ControlPanelTasksContainer'
import AgentCardsContainer from './components/AgentCardsContainer'
import ClientsNumberContainer from './components/ClientsNumberContainer'
import { AgentAnalyticsTypes } from '../../interfaces/statistics'
import userMethods from '../../utils/userMethods'
import useWidth from '../../hooks/useWidth'

function SalesAgent() {
  const { t } = useTranslation()
  const { width } = useWidth()
  const authUser = userMethods.getUser()
  const { pathname } = useLocation()
  const isExactPath = pathname === '/agent'

  useEffect(() => {
    document.title = `Demo CRM - ${t('saleAgent')}`
  }, [t])

  const {
    data: todayCalls,
    isLoading: isTodayCallsLoading,
    isFetching: isTodayCallsFetching
  } = useGetData<TodayCallsTypes[]>({
    queryKey: 'todayCalls',
    queryFn: apiService.getTodayCalls
  })

  const {
    data: agentAnalytics,
    isLoading: isAgentAnalyticsLoading
  } = useGetData<AgentAnalyticsTypes>(
    {
      queryKey: 'agentAnalytics',
      queryFn: () => apiService.getAgentAnalytics(authUser?.user.id!),
      disabled: !authUser
    }
  )

  return (
    <section className="flex gap-8 w-full flex-col 2xl:flex-row">

      {/* Render Calls container for bigger screens */}
      <RenderIf isTrue={!!width && width >= 1536}>
        <CallsInfoContainer
          isTodayCallsFetching={isTodayCallsFetching}
          todayCalls={todayCalls}
          isTodayCallsLoading={isTodayCallsLoading}
        />
      </RenderIf>

      <RenderIf isTrue={isExactPath}>
        <div className="w-full">
          <div className="flex items-end justify-between text-white flex-wrap gap-2">
            <div className="flex flex-col gap-1">
              <Title title="controlPanelAgent" add="" />
              <SubTitle text="callProgressToday" />
            </div>
            <ClientsNumberContainer
              agentAnalytics={agentAnalytics}
              isLoading={isAgentAnalyticsLoading}
            />
          </div>
          <AgentCardsContainer
            agentAnalytics={agentAnalytics}
            isLoading={isAgentAnalyticsLoading}
          />
          {/* Render Calls container for smaller screens */}
          <RenderIf isTrue={!!width && width < 1536}>
            <CallsInfoContainer
              isTodayCallsFetching={isTodayCallsFetching}
              todayCalls={todayCalls}
              isTodayCallsLoading={isTodayCallsLoading}
            />
          </RenderIf>
          <div className="grid 2xl:grid-cols-2 gap-5 mt-5 2xl:mt-0">
            <ControlPanelTasksContainer />
            <LatestCallsContainer />
          </div>
        </div>
      </RenderIf>
      <RenderIf isTrue={!isExactPath}>
        <Outlet context={todayCalls} />
      </RenderIf>
    </section>
  )
}

export default SalesAgent
