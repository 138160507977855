interface ClientTaxExemptionOptionTypes {
  id: number;
  value: string;
  label: string;
}

const clientTaxExemptionOptions: ClientTaxExemptionOptionTypes[] = [
  { id: 1, value: '', label: 'selectTaxStatus' },
  { id: 2, value: 'NONE', label: 'NONE' },
  { id: 3, value: 'FULL', label: 'FULL' },
  { id: 4, value: 'PARTIAL', label: 'PARTIAL' }
]

export default clientTaxExemptionOptions
