import { ClientTypes } from '../../../../../interfaces/client'
import RenderIf from '../../../../../components/UI/RenderIf'

interface Props {
  selectedClient?: ClientTypes
  direction: 'left' | 'right'
}

function OrderClientInfo({ selectedClient, direction } : Props) {
  return (
    <>
      <RenderIf isTrue={!!selectedClient && direction === 'left'}>
        <div className="flex flex-wrap gap-2 w-full px-5 text-grey-pale mb-2 md:mb-0 ">
          <span>{selectedClient?.company}</span>
          <span>{selectedClient?.telephone}</span>
          <span className="whitespace-nowrap">{selectedClient?.email}</span>
        </div>
      </RenderIf>

      <RenderIf isTrue={!!selectedClient && direction === 'right'}>
        <div className="flex gap-2 w-full px-5  text-grey-pale">
          <span>{selectedClient?.full_name}</span>
          <span>{selectedClient?.company}</span>
        </div>
      </RenderIf>
    </>

  )
}

export default OrderClientInfo
