import {
  useContext, useCallback, useState, useEffect, useMemo,
  ChangeEvent
} from 'react'
import { ChildrenTypes } from '../../interfaces/children'
import { ProductTypes, OrderProductTypes } from '../../interfaces/products'
import { calculatePrice, calculateQuantity, calculateTaxPrice } from '../../helpers/calculate-price'
import ProductsCartContextTypes from './productsCartsCtx.interface'
import productsCartCtx from './productsCartCtx'

export const useProductsCart = ():ProductsCartContextTypes => useContext(productsCartCtx)

function ProductsCartProvider({ children }: ChildrenTypes) {
  const [selectedProducts, setSelectedProducts] = useState<OrderProductTypes[]>([])
  const [totalQuantity, setTotalQuantity] = useState<number>(0)
  const [taxPrice, setTaxPrice] = useState<number>(0)
  const [totalPrice, setTotalPrice] = useState<number>(0)

  const addProduct = useCallback((product: ProductTypes) => {
    // Find index of the added product
    const findProductIndex = selectedProducts
      .findIndex((prd) => prd.product.product_sku === product.product_sku)

    // If findProductIndex exists already, increase quantity, else add product
    if (findProductIndex !== -1) {
      // Don't add product if quantity is 0 or less
      if (selectedProducts[findProductIndex].product.remaining_stock < 1) return

      const existingProduct = selectedProducts[findProductIndex]
      existingProduct.quantity = +existingProduct.quantity + 1

      // Don't add product if quantity is greater than remaining stock
      if (existingProduct.quantity > existingProduct.product.remaining_stock) {
        return
      }

      // Update product
      const updatedProducts = [...selectedProducts]
      updatedProducts[findProductIndex] = existingProduct
      setSelectedProducts(updatedProducts)
    } else {
      // else add product to the list
      setSelectedProducts(
        (prevState) => [...prevState, {
          product,
          quantity:
            product.remaining_stock <= 0 ? 0 : 1,
          buy_price: product.price
        }
        ]
      )
    }
  }, [selectedProducts])

  const removeProduct = useCallback((sku: ProductTypes['product_sku']) => {
    const updatedProducts = selectedProducts.filter((prd) => prd.product.product_sku !== sku)
    setSelectedProducts(updatedProducts)
  }, [selectedProducts])

  const updateProductQuantity = useCallback((
    event: ChangeEvent<HTMLInputElement>,
    product:OrderProductTypes
  ) => {
    // Return if the value is not a number or the value is higher than remaining stock
    const onlyNumRegExp = /^\d+$/;
    if (
      +event.target.value > product.product.remaining_stock
      || !onlyNumRegExp.test(event.target.value)
    ) return

    // Find index of the added product
    const findProductIndex = selectedProducts
      .findIndex((prd) => prd.product.product_sku === product.product.product_sku)
    const existingProduct = selectedProducts[findProductIndex]
    // Increase/Decrease quantity
    existingProduct.quantity = +event.target.value
    // Update state
    const updatedProducts = [...selectedProducts]
    updatedProducts[findProductIndex] = existingProduct
    setSelectedProducts(updatedProducts)
  }, [selectedProducts])

  const updateProductBuyPrice = useCallback(
    (
      event:
      ChangeEvent<HTMLInputElement>,
      product:OrderProductTypes
    ) => {
      if (event.target.value === '') return
      // Find index of the added product
      const findProductIndex = selectedProducts
        .findIndex((prd) => prd.product.product_sku === product.product.product_sku)
      const existingProduct = selectedProducts[findProductIndex]
      // Increase/Decrease Buy Price
      existingProduct.buy_price = +event.target.value
      // Update state
      const updateProducts = [...selectedProducts]
      updateProducts[findProductIndex] = existingProduct
      setSelectedProducts(updateProducts)
    },
    [selectedProducts]
  )

  // Get total quantity, total and tax price of the selected products
  useEffect(() => {
    if (selectedProducts.length !== 0) {
      const getTotalQuantity = calculateQuantity(selectedProducts)
      const getTotalPrice = calculatePrice(selectedProducts)
      const getTaxPrice = calculateTaxPrice(selectedProducts)

      setTotalQuantity(getTotalQuantity)
      setTotalPrice(getTotalPrice)
      setTaxPrice(getTaxPrice)
    } else {
      setTotalQuantity(0)
      setTotalPrice(0)
      setTaxPrice(0)
    }
  }, [selectedProducts])

  const ctx = useMemo(
    () => ({
      selectedProducts,
      totalQuantity,
      totalPrice,
      taxPrice,
      addProduct,
      removeProduct,
      updateProductQuantity,
      updateProductBuyPrice,
      setSelectedProducts
    }),
    [
      selectedProducts,
      totalQuantity,
      totalPrice,
      taxPrice,
      addProduct,
      removeProduct,
      updateProductQuantity,
      updateProductBuyPrice,
      setSelectedProducts
    ]
  )

  return (
    <productsCartCtx.Provider value={ctx}>{children}</productsCartCtx.Provider>
  )
}

export default ProductsCartProvider
