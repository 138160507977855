import axios, { AxiosError, AxiosResponse } from 'axios'
import { OrdersServiceInterface } from './ordersService.interface'
import { CreateOrderTypes, OrderPaginateTypes, OrderTypes } from '../../interfaces/orders'
import exception from '../../utils/exception'
import { ServerError } from '../../interfaces/api'
import axiosInstance from '../axiosInstance'

class OrdersService implements OrdersServiceInterface {
  public async getOrders({ pageParam = 1, searchText = '' }:
  { pageParam: number, searchText: string }): Promise<OrderPaginateTypes | null> {
    try {
      const response = await axiosInstance.post(`/orders/order/search/?page=${pageParam}`, {
        search_text: searchText.trim()
      })
      return response.data.data as OrderPaginateTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }

    return null
  }

  public async getOrderDetails({ id }: { id: string }): Promise<OrderTypes | null> {
    try {
      const response = await axiosInstance.get(`/orders/order/${id}/details/`)
      return response.data.data as OrderTypes
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async createOrder({
    values,
    products,
    totalPrice
  }: CreateOrderTypes): Promise<AxiosResponse | null> {
    try {
      const orderData = {
        client: values.client,
        fincode: values.fincode,
        payment: values.paymentMethod,
        notes: values.notes,
        series: values.series,
        shipment: values.shipment,
        products: products.map((product) => ({
          id: product.product.product_id,
          quantity: product.quantity,
          buy_price: product.buy_price
        })),
        total_price: totalPrice
      }

      const response = await axiosInstance.post('/orders/order/', orderData)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }
    return null
  }

  public async deleteOrder({ id }: { id: number }): Promise<AxiosResponse | null> {
    try {
      const response = await axiosInstance.delete(`/orders/order/${id}/`)
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        exception(error as AxiosError<ServerError>)
      }
    }

    return null
  }
}

export default OrdersService;
