import { useNavigate } from 'react-router-dom'
import PrimaryActionBtn from '../UI/PrimaryActionBtn'
import SecondaryActionBtn from '../UI/SecondaryActionBtn'

interface Props {
  isLoading: boolean
  btnText: string
  onCancelHandler?: () => void
  disabled?: boolean
}

function FormButtons({
  isLoading, btnText, onCancelHandler, disabled = false
}:Props) {
  const navigate = useNavigate()

  return (
    <div className="mt-4 flex flex-col-reverse lg:flex-row justify-end gap-5">
      <SecondaryActionBtn btnText="Cancel" onClickHandler={() => (onCancelHandler ? onCancelHandler() : navigate(-1))} />
      <PrimaryActionBtn disabled={disabled} type="submit" btnText={btnText} isLoading={isLoading} />
    </div>
  )
}

export default FormButtons
