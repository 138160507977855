function ClientDetailSkeleton() {
  return (
    <div className="flex flex-col gap-3">
      <div className="w-[160px] h-[28px] bg-gray-100 animate-pulse rounded-xl" />
      <div className="max-w-[350px] h-[34px] bg-gray-100 animate-pulse rounded-xl" />
    </div>
  )
}

function ClientInfoSkeleton() {
  return (
    <>
      <div className="flex items-center justify-between gap-4 flex-wrap mb-5">
        <div className="w-[200px] w-full h-[52px] bg-gray-200 animate-pulse rounded-xl" />
        <div className="w-[300px] w-full h-[52px] bg-gray-200 animate-pulse rounded-xl" />
      </div>
      <div className="bg-white rounded-3xl shadow-faint w-full flex mx-auto flex-wrap mb-5 ">
        <div className="p-10 grid grid-cols-1 md:grid-cols-3 gap-20 w-full bg-gray-200 animate-pulse rounded-3xl">
          <ClientDetailSkeleton />
          <ClientDetailSkeleton />
          <ClientDetailSkeleton />
          <ClientDetailSkeleton />
          <ClientDetailSkeleton />
          <ClientDetailSkeleton />
          <ClientDetailSkeleton />
          <ClientDetailSkeleton />
          <ClientDetailSkeleton />
        </div>
      </div>
    </>
  )
}

export default ClientInfoSkeleton
