import { CallStateTypes } from '../context/ClientCallCtx/clientCallCtx.interface'
import { TodayCallsTypes } from '../interfaces/calls'
import { ClientTypes } from '../interfaces/client'

interface StoredCallTypes {
  call: TodayCallsTypes | null,
  client: ClientTypes | null
  seconds: number,
  minutes: number,
  hours: number,
  startDate: string,
  endDate: string,
  duration: number,
  callState: CallStateTypes
}

const callMethods = {
  saveStartedCall: (call:StoredCallTypes) => {
    localStorage.setItem('startedCall', JSON.stringify(call))
  },
  getStartedCall: () => {
    const call = localStorage.getItem('startedCall')
    return call ? JSON.parse(call) as StoredCallTypes : null
  },
  removeStartedCall: () => {
    localStorage.removeItem('startedCall')
  }
}

export default callMethods
