import AuthService from '../auth/authService'
import UsersService from '../users/usersService'
import applyMixins from '../../mixins/applyMixins'
import OffersService from '../offers/offersService'
import OrdersService from '../orders/ordersService'
import ClientsService from '../clients/clientsService'
import ComplaintsService from '../complaints/complaintsService'
import ProductsService from '../products/productsService'
import TasksService from '../tasks/tasksService'
import StatisticsService from '../statistics/statisticsService'
import NewslettersService from '../newsletters/newslettersService'
import CallsService from '../calls/callsService'
import CronActionsService from '../cron-actions/cronActionsService'

class ApiService { }

interface ApiService extends
  AuthService,
  UsersService,
  OffersService,
  OrdersService,
  ClientsService,
  ComplaintsService,
  ProductsService,
  TasksService,
  StatisticsService,
  NewslettersService,
  CronActionsService,
  CallsService { }

applyMixins(
  ApiService,
  [
    AuthService,
    UsersService,
    OffersService,
    OrdersService,
    ClientsService,
    ComplaintsService,
    ProductsService,
    TasksService,
    StatisticsService,
    NewslettersService,
    CronActionsService,
    CallsService
  ]
)

export default new ApiService()
