import { useTranslation } from 'react-i18next'
import { HiOutlineChevronDown } from 'react-icons/hi'
import { MapAgentTypes } from '../../../../interfaces/user'
import Dropdown from '../../../../components/Dropdown/Dropdown'
import useToggleMenu from '../../../../hooks/useToggleMenu'

interface Props {
  mapAgents: MapAgentTypes[],
  selectAllAgentOptions: boolean,
  onChangeHandler: (option: MapAgentTypes) => void
  onSelectAllAgentOptionsHandler: () => void
}

function SelectMultipleAgents({
  mapAgents = [],
  selectAllAgentOptions,
  onChangeHandler,
  onSelectAllAgentOptionsHandler
}:Props) {
  const { t } = useTranslation()
  const { isOpen, setIsOpen, ref } = useToggleMenu()
  return (
    <div ref={ref} className="relative text-grey-pale w-full sm:w-max">
      <button
        onClick={() => setIsOpen((prevState) => !prevState)}
        type="button"
        className="flex items-center bg-white justify-between gap-5 rounded-[5px] border border-solid border-[#BEBEBE] px-4 py-2 w-full"
      >
        {t('saleAgents')}
        <HiOutlineChevronDown size={18} className={`${isOpen ? '-rotate-180' : 'rotate-0'} transition-transform duration-300`} />
      </button>
      <Dropdown isOpen={isOpen} align="left-0 origin-top-left" triangleAlignment="left-[12px]">
        <div className="h-[300px] overflow-y-auto w-[300px]">
          <button
            onClick={onSelectAllAgentOptionsHandler}
            type="button"
            className="w-[calc(100%-24px)] text-left mx-3 border-b border-solid border-b-[#f2f2f2] py-2 hover:text-btn-sub transition-all duration-200"
          >
            {selectAllAgentOptions ? t('removeAll') : t('selectAll')}
          </button>
          {mapAgents.map((agent) => (
            <div key={agent.id} className="flex gap-2 items-center justify-between mx-3 border-b border-solid border-b-[#f2f2f2] py-2 last:border-b-0">
              {agent.department}
              <input
                type="checkbox"
                className="mr-2"
                checked={agent.isSelected}
                onChange={() => onChangeHandler(agent)}
              />
            </div>
          ))}
        </div>
      </Dropdown>
    </div>
  )
}

export default SelectMultipleAgents
