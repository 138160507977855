import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { toast } from 'react-toastify'
import FormLayout from '../../../../components/Form/FormLayout'
import { useCallClient } from '../../../../context/ClientCallCtx/ClientCallProvider'
import PhoneButton from './PhoneButton'
import { callResultOptions } from '../../../../helpers/call-result-options'
import FormTextArea from '../../../../components/Form/FormTextArea'
import validationSchema from '../../../../utils/validationSchema'
import RenderIf from '../../../../components/UI/RenderIf'
import useMutateData from '../../../../hooks/useMutateData'
import apiService from '../../../../services/api/apiService'
import Spinner from '../../../../components/UI/Spinner'
import routes from '../../../../constants/routes'
import useWidth from '../../../../hooks/useWidth'
import callMethods from '../../../../utils/callMethods'
import Dropdown from '../../../../components/Dropdown/Dropdown'
import useToggleMenu from '../../../../hooks/useToggleMenu'
import CallStatus from '../../../../components/UI/CallStatus'
import { CallStatusTypes, TodayCallsTypes } from '../../../../interfaces/calls'
import { ClientTypes } from '../../../../interfaces/client'

interface Props {
  call?: TodayCallsTypes
  client?: ClientTypes
}

function InitializeCall({ call, client }:Props) {
  const startedCall = callMethods.getStartedCall()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { ref, isOpen, setIsOpen } = useToggleMenu()
  const { width } = useWidth()
  const clientCallCtx = useCallClient()
  const cacheKeys = ['todayCalls', 'latestCalls', `callHistory, ${client?.client_number}`, 'agentAnalytics']
  const { mutate: updateCall, isLoading: isUpdateCallLoading } = useMutateData({
    key: cacheKeys,
    mutationFn: apiService.updateCall,
    successMessage: 'callUpdated'
  })

  const formik = useFormik({
    initialValues: {
      callResult: 'callOutcome',
      callNotes: '',
      newDate: ''
    },
    validationSchema: () => validationSchema.callResultCreationFrom(formik.values.callResult),
    onSubmit: (values) => {
      if (values.callResult === 'callOutcome') {
        toast.warn<string>(t('selectCallOutcome'))
        return
      }

      updateCall(
        {
          clientNumber: client?.client_number,
          callId: call?.id.toString(),
          duration: clientCallCtx.duration,
          status: values.callResult,
          notes: values.callNotes,
          callStartTime: clientCallCtx.startDate!,
          callEndTime: clientCallCtx.endDate!,
          plannedDate: moment(values.newDate).format('YYYY-MM-DD')
        },
        {
          onSuccess: () => {
            clientCallCtx.onReset()
            formik.resetForm()
            if (call) {
              navigate(routes.agent.home)
            }
          }
        }
      )
    }
  })

  // eslint-disable-next-line no-nested-ternary
  const isNotActiveCall = startedCall?.call?.id
    ? startedCall?.call?.id !== call?.id
    : startedCall?.client
      ? startedCall.client.client_number !== client?.client_number
      : false

  const isDisabled = clientCallCtx.callState !== 'ENDED'

  return (
    <FormLayout>
      <form className="flex flex-col gap-5 xl:gap-1 w-full" onSubmit={formik.handleSubmit}>
        <div className="flex justify-between items-center gap-1 w-full  xl:mb-3">
          <PhoneButton
            isNotActiveCall={isNotActiveCall}
            clientCallCtx={clientCallCtx}
            call={call}
            client={client}
          />
          <div className="relative w-max" ref={ref}>
            <button
              className="outline-none rounded-[5px] border border-gray-400 border-solid h-[50px] px-4 w-[155px]"
              disabled={isDisabled || isNotActiveCall}
              type="button"
              onClick={() => setIsOpen(!isOpen)}
            >
              {t(formik.values.callResult)}
            </button>
            <Dropdown isOpen={isOpen}>
              {callResultOptions.map((option) => (
                <button
                  type="button"
                  key={option.id}
                  className="px-[20px] py-[7px] border-b border-b-[#f2f2f2] w-full last:border-b-0 hover:bg-[#F8F8F8] transition-all duration-200"
                  onClick={() => {
                    formik.setFieldValue('callResult', option.value)
                    setIsOpen(false)
                  }}
                >
                  <CallStatus status={option.value as CallStatusTypes} />
                </button>
              ))}
            </Dropdown>
          </div>
        </div>
        <FormTextArea
          inputId="comments"
          name="comments"
          width="lg:w-full"
          rows={width && width >= 1536 && width <= 1688 ? 4 : 5}
          getFieldProps={formik.getFieldProps('callNotes')}
          errors={!!(formik.touched.callNotes && formik.errors.callNotes)}
          errorName={formik.errors.callNotes}
          marginX="mx-0"
          marginY="my-0"
          disabled={isNotActiveCall}
        />
        <div className="flex mt-auto justify-between items-center">
          <RenderIf isTrue={formik.values.callResult === 'Postponed'}>
            <div className="flex flex-col gap-2 text-grey-pale">
              <label htmlFor="newDate" className="text-sm">{t('newDate')}</label>
              <input
                id="newDate"
                className={`outline-none border py-1 px-2 rounded-[5px] h-[44px] ${
                  formik.touched.newDate && formik.errors.newDate ? 'border-red-700' : 'border-gray-400'
                }`}
                type="date"
                {...formik.getFieldProps('newDate')}
              />
            </div>
          </RenderIf>
          <button
            disabled={isDisabled || isNotActiveCall}
            type="submit"
            className="mt-auto ml-auto bg-btn-sub text-white rounded-[5px] w-[161px] h-[46px] hover:opacity-90 transition-opacity duration-200 disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            {isUpdateCallLoading ? <Spinner /> : t('save')}
          </button>
        </div>

      </form>
    </FormLayout>
  )
}

export default InitializeCall
