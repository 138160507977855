import {
  useMemo, useContext, useRef, useEffect, useCallback, useState
} from 'react'
import moment from 'moment'
import clientCallCtx from './clientCallCtx'
import { ChildrenTypes } from '../../interfaces/children'
import { ClientCallCtxTypes } from './clientCallCtx.interface'
import callMethods from '../../utils/callMethods'
import { TodayCallsTypes } from '../../interfaces/calls'
import { ClientTypes } from '../../interfaces/client'

export const useCallClient = ():ClientCallCtxTypes => useContext(clientCallCtx)

function ClientCallProvider({ children }:ChildrenTypes) {
  const startedCall = callMethods.getStartedCall()
  const [callState, setCallState] = useState<ClientCallCtxTypes['callState']>(
    startedCall?.callState === 'IN_PROGRESS'
      ? 'ENDED'
      : startedCall?.callState ?? 'NOT_STARTED'
  )
  const [startDate, setStartDate] = useState<ClientCallCtxTypes['startDate']>(startedCall?.startDate ?? null)
  const [endDate, setEndDate] = useState<ClientCallCtxTypes['endDate']>(startedCall?.endDate ?? null)
  const [duration, setDuration] = useState<ClientCallCtxTypes['duration']>(startedCall?.duration ?? 0)
  const [hours, setHours] = useState<number>(startedCall?.hours ?? 0)
  const [minutes, setMinutes] = useState<number>(startedCall?.minutes ?? 0)
  const [seconds, setSeconds] = useState<number>(startedCall?.seconds ?? 0)
  const [call, setCall] = useState<TodayCallsTypes | null>(startedCall?.call ?? null)
  const [client, setClient] = useState<ClientTypes | null>(startedCall?.client ?? null)

  const timerRef = useRef<NodeJS.Timeout | null>(null)

  const clearTimer = useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current)
    }
  }, [])

  const callClock = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

  const startCall = useCallback((
    { clientCall, selectedClient }: { clientCall?: TodayCallsTypes, selectedClient?: ClientTypes }
  ) => {
    clearTimer()
    if (startDate === null) {
      setStartDate(moment(new Date()).format())
    }
    if (clientCall) {
      setCall(clientCall)
    }

    if (selectedClient) {
      setClient(selectedClient)
    }
    setCallState('IN_PROGRESS')
    timerRef.current = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 59) {
          setMinutes((prevMinutes) => {
            if (prevMinutes === 59) {
              setHours((prevHours) => prevHours + 1)
              return 0
            }
            return prevMinutes + 1
          })
          return 0
        }
        return prevSeconds + 1
      })
    }, 1000)
  }, [clearTimer, startDate])

  const endCall = useCallback(() => {
    setCallState('ENDED')
    setEndDate(moment(new Date()).format())
    setDuration(seconds + (minutes * 60) + (hours * 3600))
    clearTimer()
  }, [clearTimer, seconds, minutes, hours])

  const onReset = useCallback(() => {
    setCallState('NOT_STARTED')
    setStartDate(null)
    setEndDate(null)
    setDuration(0)
    setHours(0)
    setMinutes(0)
    setSeconds(0)
    setCall(null)
    setClient(null)
    callMethods.removeStartedCall()
  }, [])

  useEffect(() => () => {
    clearTimer()
  }, [clearTimer])

  useEffect(() => {
    if (callState !== 'NOT_STARTED') {
      callMethods.saveStartedCall({
        call: call ?? null,
        client: client ?? null,
        callState,
        hours,
        minutes,
        seconds,
        duration,
        startDate: startDate!,
        endDate: endDate!
      })
    }
  }, [
    callState,
    call,
    client,
    hours,
    minutes,
    seconds,
    startDate,
    endDate,
    duration
  ])

  const ctx = useMemo(() => ({
    call,
    client,
    callState,
    callClock,
    startCall,
    endCall,
    startDate,
    endDate,
    duration,
    onReset
  }), [
    call,
    client,
    callState,
    callClock,
    startCall,
    endCall,
    startDate,
    endDate,
    duration,
    onReset
  ]);
  return (
    <clientCallCtx.Provider value={ctx}>
      {children}
    </clientCallCtx.Provider>
  )
}

export default ClientCallProvider
