export type ColorState = 'Σε εξέλιξη' | 'cancelled' | 'Υπο Επεξεργασία' | 'Μερικώς απεσταλμένη' | 'Απεσταλμένη'

export default function orderStateColors(state:ColorState) {
  let classes: string
  switch (state) {
    case 'Σε εξέλιξη':
      classes = 'bg-[#EEF4FC] text-[#2571C6]'
      return classes
    case 'cancelled':
      classes = 'bg-[#FBE2E2] text-[#E64848]'
      return classes
    case 'Υπο Επεξεργασία':
      classes = 'bg-[#FBF3E5] text-[#A88F11]'
      return classes
    case 'Μερικώς απεσταλμένη':
      classes = 'bg-[#ABB2BC] text-[#E6E9EE]'
      return classes
    case 'Απεσταλμένη':
      classes = 'bg-[#D0FEE1] text-[#61A279]'
      return classes
    default:
      return '#ffc107'
  }
}
