import moment from 'moment'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import routes from '../../../../constants/routes'
import { TodayCallsTypes } from '../../../../interfaces/calls'
import RenderIf from '../../../../components/UI/RenderIf'
import borderColor from '../../../../assets/images/blue-border.svg'
import CallStatus from '../../../../components/UI/CallStatus'

interface Props {
  todayCall: TodayCallsTypes;
}

function CallInfo({ todayCall }:Props) {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const callId = pathname.split('-').at(-1)
  const isSelectedCall = callId && todayCall.id === +callId
  const containerClasses = isSelectedCall ? 'border-[#0584a5] bg-[#FEFDFB] w-[calc(100%+17px)] rounded-r-0 ' : 'border-[#E6E6E6] bg-white'
  const selectedWidthClass = isSelectedCall ? 'w-[calc(100%-17px)]' : 'w-full'
  return (
    <Link
      to={`${routes.agent.clientCallInfo}/${todayCall.client.client_number}-${todayCall.id}`}
      className={`${containerClasses} relative h-[95px] border border-solid rounded-lg p-3 cursor-pointer hover:opacity-[0.85] transition-opacity duration-200`}
    >
      <div className={`${selectedWidthClass} flex justify-between items-center`}>
        <div className="flex items-center gap-4 ">
          <p className="text-[#BCBCBC] text-sm">{todayCall.daily_index}</p>
          <div>
            <h3 title={todayCall.client.full_name} className="text-grey-pale font-semibold mb-1 line-clamp-2">{todayCall.client.full_name}</h3>
            <p className="text-[#4B8FDC]">{todayCall.client.telephone}</p>
          </div>
        </div>
        <div className="text-sm text-grey-pale flex flex-col gap-1 text-right">
          <p>
            {moment(todayCall.planned_date).format('DD/MM/YYYY')}
          </p>
          <div className="self-end">
            <CallStatus status={todayCall.status} />
          </div>
          <RenderIf isTrue={!!todayCall.attempts}>
            <p className="text-sm whitespace-nowrap">
              {t(todayCall.attempts === 1 ? 'callAttempt' : 'callAttempts')}
              :
              {' '}
              {' '}
              {todayCall.attempts}
            </p>
          </RenderIf>
        </div>
      </div>
      <RenderIf isTrue={!!isSelectedCall}>
        <img src={borderColor} alt="" className="h-[calc(100%-10px)] absolute top-[5px] -right-[8px]" />
      </RenderIf>
    </Link>
  )
}

export default CallInfo
