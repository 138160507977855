import { IoMdRemoveCircle } from 'react-icons/io'
import { useTranslation } from 'react-i18next'
import RenderIf from '../../../../../../components/UI/RenderIf'
import { ProductTypes } from '../../../../../../interfaces/products'

interface Props {
  products: ProductTypes[]
  onRemoveProduct: (product: ProductTypes) => void
  isEmailDesign: boolean
}

function EmailPreviewProducts({ products, onRemoveProduct, isEmailDesign }:Props) {
  const { t } = useTranslation()
  return (
    <table style={{
      marginTop: '20px',
      borderCollapse: 'collapse',
      tableLayout: 'auto',
      width: '100%',
      textAlign: 'left',
      fontSize: '12px',
      backgroundColor: '#FBFBFB',
      borderRadius: '10px'
    }}
    >
      <thead>
        <tr style={{
          borderBottom: '1px solid #EFEFEF'
        }}
        >
          <th style={{
            padding: '5px 0 5px 10px',
            whiteSpace: 'nowrap'
          }}
          >
            {t('skuProduct')}
          </th>
          <th style={{ padding: '5px 10px 5px 10px', whiteSpace: 'nowrap' }}>
            {t('productName')}
          </th>
          <th style={{
            padding: '5px 10px 5px 0',
            textAlign: 'right',
            whiteSpace: 'nowrap'
          }}
          >
            {t('price')}
          </th>
        </tr>
      </thead>
      <tbody>
        {
          products.map((product) => (
            <tr
              key={product.product_id}
            >
              <td style={{
                padding: '5px 0 5px 10px',
                position: 'relative'
              }}
              >
                <RenderIf isTrue={isEmailDesign}>
                  <IoMdRemoveCircle
                    onClick={() => onRemoveProduct(product)}
                    className="cursor-pointer text-error absolute -left-3 top-2"
                  />
                </RenderIf>
                {product.product_sku}
              </td>
              <td style={{
                padding: '5px 10px 5px 10px'
              }}
              >
                {product.product_name}
              </td>
              <td style={{
                padding: '5px 10px 5px 0',
                textAlign: 'right'
              }}
              >
                {product.price}
                {' '}
                €
              </td>
            </tr>
          ))
      }
      </tbody>
    </table>
  )
}

export default EmailPreviewProducts
