import React from 'react'
import { useTranslation } from 'react-i18next'
import Detail from '../../../../../components/UI/Detail'
import { ClientTypes } from '../../../../../interfaces/client'

interface Props {
  client?: ClientTypes
  gridCols: 'lg:grid-cols-3' | 'lg:grid-cols-4'
  ellipsis?: 'line-clamp-1 text-ellipsis' | 'line-clamp-none'
}

function ClientBasicInfo({ client, gridCols, ellipsis }: Props) {
  const { t } = useTranslation()
  const print = 'print:grid-cols-3'
  return (
    <div className={`grid grid-cols-2 ${gridCols} gap-5 w-full ${print}`}>
      <Detail label={t('clientCode')} text={client?.client_code || '-'} ellipsis={ellipsis} />
      <Detail label={t('fullName')} text={client?.full_name || '-'} ellipsis={ellipsis} />
      <Detail label={t('Email')} text={client?.email || '-'} ellipsis={ellipsis} />
      <Detail label={t('vatNumber')} text={t(client?.vat_number || '-')} ellipsis={ellipsis} />
      <Detail label={t('irsBranch')} text={t(client?.irs_branch || '-')} ellipsis={ellipsis} />
      <Detail label={t('taxStatus')} text={t(client?.tax_exemption || '-')} ellipsis={ellipsis} />
      <Detail label={t('telephone')} text={client?.telephone || '-'} ellipsis={ellipsis} />
      <Detail label={t('county')} text={t(client?.prefecture || '-')} ellipsis={ellipsis} />
      <Detail label={t('city')} text={t(client?.city || '-')} ellipsis={ellipsis} />
      <Detail label={t('address')} text={t(client?.address || '-')} ellipsis={ellipsis} />
      <Detail label={t('zipCode')} text={t(client?.zip || '-')} ellipsis={ellipsis} />
      <Detail label={t('toughClient')} text={t(client?.tough_client ? 'yes' : 'no')} ellipsis={ellipsis} />
    </div>
  )
}

export default ClientBasicInfo
