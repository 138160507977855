import {
  ChangeEvent, useEffect, useMemo, useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useInfiniteQuery } from 'react-query'
import Title from '../../../../components/UI/Title'
import CallsTable from '../../components/CallsTable'
import apiService from '../../../../services/api/apiService'
import tokenMethods from '../../../../utils/tokenMethods'
import { InfiniteActionPaginateTypes } from '../../../../interfaces/client'
import RenderIf from '../../../../components/UI/RenderIf'
import TableSkeleton from '../../../../components/Skeletons/TableSkeleton'

function Calls() {
  const { t } = useTranslation()
  const [searchTextCalls, setSearchTextCalls] = useState<string>('')
  const token = tokenMethods.getAccessToken()

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  } = useInfiniteQuery(['calls', searchTextCalls], ({ pageParam }) => apiService
    .getAgentCalls({ pageParam, searchText: searchTextCalls }), {
    getNextPageParam: (lastPage) => lastPage?.next?.split('=').at(-1),
    enabled: !!token,
    refetchOnWindowFocus: false
  })

  const callsPaginate: InfiniteActionPaginateTypes = {
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage
  }

  const calls = useMemo(() => data?.pages
    .map((group) => group?.results ?? []).flat(1) ?? [], [data])

  const onSearchOrderHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTextCalls(event.target.value)
  }

  useEffect(() => {
    document.title = `Demo CRM - ${t('calls')}`
  }, [t])

  useEffect(() => () => {
    // reset search text when leaving the page
    setSearchTextCalls('')
  }, [setSearchTextCalls])

  return (
    <section>
      <Title results={calls} title="calls" add="" />
      <RenderIf isTrue={isLoading || callsPaginate.isFetchingNextPage}>
        <TableSkeleton />
      </RenderIf>
      <RenderIf isTrue={!isLoading}>
        <CallsTable
          calls={calls}
          callsPaginate={callsPaginate}
          searchTextCalls={searchTextCalls}
          onSearchOrderHandler={onSearchOrderHandler}
          isHidden={callsPaginate.isFetchingNextPage}
        />
      </RenderIf>
    </section>
  )
}

export default Calls
