import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import PageTitle from '../../../../../components/UI/PageTitle';
import ComplaintCreationForm from '../components/ComplaintCreationForm';
import { ComplaintCreationTypes } from '../../../../../interfaces/complaints';
import useMutateData from '../../../../../hooks/useMutateData';
import apiService from '../../../../../services/api/apiService';
import PageSection from '../../../../../components/UI/PageSection'

function CreateComplaint() {
  const { t } = useTranslation();
  const navigate = useNavigate()
  const { mutate: createComplaint, isLoading } = useMutateData({
    key: 'complaints',
    mutationFn: apiService.createComplaint,
    successMessage: 'complaintHasBeenCreated'
  })

  const onSubmit = (values: ComplaintCreationTypes) => {
    createComplaint(values, {
      onSuccess: () => navigate(-1)
    })
  }

  useEffect(() => {
    document.title = `Demo CRM - ${t('New Complaint')}`
  }, [t])

  return (
    <PageSection>
      <PageTitle text={t('New Complaint')} />
      <ComplaintCreationForm onSubmit={onSubmit} isLoading={isLoading} />
    </PageSection>
  )
}

export default CreateComplaint
