import { BsSearch } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { ChangeEvent } from 'react'

interface Props {
  searchValue: string
  onSearchTodayCallsHandler: (event: ChangeEvent<HTMLInputElement>) => void
}

function SearchClientCall({ searchValue, onSearchTodayCallsHandler }:Props) {
  const { t } = useTranslation()
  return (
    <div className="bg-white w-full flex items-center border border-solid border-[#BEBEBE] rounded-md px-4">
      <input
        type="text"
        placeholder={t('search')}
        value={searchValue}
        onChange={onSearchTodayCallsHandler}
        className="py-[5px] outline-none w-full"
      />
      <BsSearch />
    </div>
  )
}

export default SearchClientCall
