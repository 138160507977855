import { useTranslation } from 'react-i18next'
import FormLayout from '../../../../../components/Form/FormLayout'
import { ClientTypes } from '../../../../../interfaces/client'
import ClientBasicInfo from './ClientBasicInfo'

interface Props {
  client?: ClientTypes
}

function ClientDetails({ client }: Props) {
  const { t } = useTranslation()

  return (
    <FormLayout>
      <div className="w-full">
        <h2 className="font-semibold text-xl text-primary-text-dark mb-8">{t('basicDetails')}</h2>
        <ClientBasicInfo client={client} gridCols="lg:grid-cols-3" />
      </div>
    </FormLayout>
  )
}

export default ClientDetails
