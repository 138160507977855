import { useState } from 'react'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCallClient } from '../../../../context/ClientCallCtx/ClientCallProvider'
import RenderIf from '../../../../components/UI/RenderIf'
import routes from '../../../../constants/routes'

function FixedPhoneButton() {
  const { t } = useTranslation()
  const clientCallCtx = useCallClient()
  const navigate = useNavigate()
  const [isHoverPhone, setIsHoverPhone] = useState<boolean>(false)
  const [isHoverBg, setIsHoverBg] = useState<boolean>(false)

  const onPhoneButtonClickHandler = () => {
    if (clientCallCtx.callState === 'NOT_STARTED'
      || clientCallCtx.callState === 'ENDED') {
      clientCallCtx.startCall(
        { clientCall: clientCallCtx.call!, selectedClient: clientCallCtx.client! }
      )
      return
    }

    if (clientCallCtx.callState === 'IN_PROGRESS') {
      clientCallCtx.endCall()
    }
  }
  const phoneButtonClasses = clientCallCtx.callState === 'NOT_STARTED' || clientCallCtx.callState === 'ENDED'
    ? 'bg-success'
    : 'bg-error'

  const phoneIconClasses = clientCallCtx.callState === 'NOT_STARTED' || clientCallCtx.callState === 'ENDED'
    ? 'rotate-0'
    : 'rotate-[135deg]'

  const navigateToCallPath = `${routes.agent.home}/${routes.agent.clientCallInfo}/${clientCallCtx?.call?.client.client_number}-${clientCallCtx?.call?.id}`
  const navigateToClientPath = `${routes.agent.clients}/${routes.shared.clientInfo}/${clientCallCtx?.client?.client_number}`
  const navigateToPath = clientCallCtx?.call ? navigateToCallPath : navigateToClientPath

  const radiusClasses = isHoverBg ? 'rounded-b-xl' : 'rounded-xl'
  return (
    <>
      <RenderIf isTrue={isHoverPhone || isHoverBg}>
        <div className={`${isHoverBg ? 'bg-[#F8F8F8]' : 'bg-white'} text-black whitespace-nowrap text-xs fixed bottom-[83px] left-5 z-[20] w-[150px]  rounded-t-xl shadow-md p-[6px]`}>
          {isHoverPhone && <p>{t(clientCallCtx.callState === 'IN_PROGRESS' ? 'endCall' : 'startCall')}</p>}
          {isHoverBg && <p>{t('viewCall')}</p>}
        </div>
      </RenderIf>
      <div className={`fixed bottom-5 left-5 z-[20] w-[150px] h-[70px] bg-white shadow-md ${radiusClasses}`}>
        <div className="relative w-full flex items-center gap-2">
          <button
            type="button"
            onClick={() => {
              onPhoneButtonClickHandler()
            }}
            onMouseEnter={() => setIsHoverPhone(true)}
            onMouseLeave={() => setIsHoverPhone(false)}
            className={`${phoneButtonClasses} absolute left-3 h-[50px] w-[50px] rounded-full shadow-md flex items-center justify-center hover:opacity-90 transition-opacity duration-200`}
          >
            <BsFillTelephoneFill className={`${phoneIconClasses} text-white transition-transform duration-200`} size={18} />
          </button>
          <button
            type="button"
            onMouseEnter={() => setIsHoverBg(true)}
            onMouseLeave={() => setIsHoverBg(false)}
            onClick={() => navigate(navigateToPath)}
            className={`w-full h-[70px] flex items-center justify-end hover:bg-[#F8F8F8] transition-all duration-200 cursor-pointer h-full p-4 ${radiusClasses}`}
          >

            <p
              className="font-semibold"
            >
              {clientCallCtx.callClock}
            </p>
          </button>
        </div>
      </div>
    </>
  )
}

export default FixedPhoneButton
