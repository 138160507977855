import { OrderProductTypes } from '../interfaces/products'

// eslint-disable-next-line import/prefer-default-export
export const calculateQuantity = (products: OrderProductTypes[]) => products
  .reduce((acc, prd) => acc + +prd.quantity, 0)

export const calculatePrice = (products: OrderProductTypes[]) => products
  .reduce((acc, prd) => acc + (+prd.quantity * prd.buy_price), 0)

export const calculateTaxPrice = (products: OrderProductTypes[]) => products.reduce(
  (acc, prd) => {
    const taxRate = prd.product.product_tax.replace(/\D/g, '');
    const totalTaxPrice = +prd.quantity * prd.buy_price * (+taxRate / 100)
    return acc + totalTaxPrice
  },
  0
)
